import { FC } from 'react'

import {
  Timeline,
  TimelineConnector,
  TimelineContent,
  TimelineDot,
  TimelineItem,
  TimelineOppositeContent,
  TimelineSeparator
} from '@mui/lab'
import { observer } from 'mobx-react-lite'

import s from './MileagesTimeline.module.scss'

const MileagesTimeline: FC<Props> = ({ items }) => {
  return (
    <Timeline className={s.MileagesTimeline} data-testid={TEST_HOOKS.MILEAGESTIMELINE}>
      {items.map((item, idx) => (
        <TimelineItem key={item.date}>
          <TimelineOppositeContent className={s.Date}>
            {item.date}
          </TimelineOppositeContent>
          <TimelineSeparator>
            <TimelineDot variant={idx === 0 ? 'filled' : 'outlined' } color={idx === 0 ? 'primary' : 'grey'} />
            <TimelineConnector />
          </TimelineSeparator>
          <TimelineContent className={s.Mileage}>
            {item.mileage} km
          </TimelineContent>
        </TimelineItem>
      ))}
    </Timeline>
  )
}

const TEST_HOOKS = {
  MILEAGESTIMELINE: 'mileagesTimeline'
}

interface MileageItem {
  date: string
  mileage: number
}

interface Props {
  items: MileageItem[]
}

const withObserver = observer(MileagesTimeline)

export default withObserver

export {
  withObserver as MileagesTimeline,
  TEST_HOOKS,
  type Props
}
