import { FC } from 'react'

import { LaunchOutlined, PersonOutlined } from '@mui/icons-material'
import { Card, CardActionArea, CardContent, CardHeader, Tooltip } from '@mui/material'
import { observer } from 'mobx-react-lite'
import { Link as RouterLink } from 'react-router-dom'

import { ClientData } from 'services/types'

import { DistinctList } from 'components'

import s from './ClientCard.module.scss'

const ClientCard: FC<Props> = ({ client }) => {
  return (
    <Tooltip title="Zobacz klienta">
      <CardActionArea component={RouterLink} to={`/app/clients/${client.id}`}>
        <Card className={s.ClientCard} data-testid={TEST_HOOKS.CLIENTCARD}>
          <CardHeader
            title="Właściciel samochodu"
            avatar={<PersonOutlined />}
            action={<LaunchOutlined />}
          />
          <CardContent>
            <DistinctList
              items={[
                {
                  label: 'Numer telefonu',
                  value: client.attributes.phone
                },
                {
                  label: 'NIP',
                  value: client.attributes.tin ?? '—'
                }
              ]}
            />
          </CardContent>
        </Card>
      </CardActionArea>
    </Tooltip>
  )
}

const TEST_HOOKS = {
  CLIENTCARD: 'clientCard'
}

interface Props {
  client: ClientData
}

const withObserver = observer(ClientCard)

export default withObserver

export {
  withObserver as ClientCard,
  TEST_HOOKS,
  type Props
}
