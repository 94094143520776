import { createContext } from 'react'

import RootStore from './RootStore'

const root: RootStore = new RootStore()
const context = createContext(root)

export default context

export {
  context,
  root
}
