import { SearchableAttributes } from 'meilisearch'
import { makeAutoObservable, runInAction } from 'mobx'

import { SEARCHABLE_ATTRIBUTES } from 'shared/constants'
import { parseStrapiError } from 'shared/utils'

import { SettingsService } from 'services'

import { MeiliBackend } from 'services/backends'
import { AvailableIndices, SettingsData, SettingsUpdateData } from 'services/types'

import ApiErrorStore from '../ApiErrorStore'
import LoadingStore from '../LoadingStore'
import type RootStore from '../RootStore'

class SettingsStore {
  rootStore: RootStore

  settings?: SettingsData
  loading = new LoadingStore()
  apiError?: ApiErrorStore

  constructor (rootStore: RootStore) {
    makeAutoObservable(this)
    this.rootStore = rootStore
  }

  public reset (): void {
    this.settings = undefined
    this.loading.reset()
    this.apiError = undefined
  }

  public async getSearchableAttributes (index: AvailableIndices): Promise<SearchableAttributes> {
    const backend = new MeiliBackend()
    return await backend.getSearchableAttributes(index)
  }

  public async setSearchableAttributes (): Promise<void> {
    const backend = new MeiliBackend()
    await backend.setSearchableAttributes('client', SEARCHABLE_ATTRIBUTES.get('client') ?? null)
    await backend.setSearchableAttributes('vehicle', SEARCHABLE_ATTRIBUTES.get('vehicle') ?? null)
    await backend.setSearchableAttributes('service', SEARCHABLE_ATTRIBUTES.get('service') ?? null)
  }

  public async get (): Promise<void> {
    this.loading.startLoading()
    const service = new SettingsService(this.rootStore.token)
    try {
      const response = await service.getSettings()
      runInAction(() => { this.settings = response.data.data })
    } catch (e) {
      runInAction(() => { this.apiError = new ApiErrorStore(parseStrapiError(e)) })
      this.loading.finishLoading()
    } finally {
      this.loading.finishLoading()
    }
  }

  public async update (data: SettingsUpdateData): Promise<void> {
    this.loading.startUpdating()
    const service = new SettingsService(this.rootStore.token)
    try {
      const response = await service.updateSettings(data)
      runInAction(() => { this.settings = response.data.data })
    } catch (e) {
      runInAction(() => { this.apiError = new ApiErrorStore(parseStrapiError(e)) })
    } finally {
      this.loading.finishUpdating()
    }
  }
}

export default SettingsStore
