import { type ConfirmOptions } from 'material-ui-confirm'

const defaultOptions: ConfirmOptions = {
  title: 'Potwierdź usunięcie',
  description: 'Czy jesteś pewien? Usuniętego obiektu nie bęedzie można przywrócić!',
  confirmationText: 'Usuń',
  cancellationText: 'Nie usuwaj',
  confirmationButtonProps: {
    variant: 'contained',
    color: 'error'
  },
  cancellationButtonProps: {
    variant: 'outlined'
  }
}

export {
  defaultOptions
}
