import { FC } from 'react'

import { HomeOutlined } from '@mui/icons-material'
import { Button } from '@mui/material'
import { observer } from 'mobx-react-lite'
import { useTranslation } from 'react-i18next'
import { useLocation } from 'react-router'
import { Link as RouterLink } from 'react-router-dom'

import { Page } from 'components'

import s from './NotFoundPage.module.scss'

const NotFoundPage: FC<Props> = () => {
  const { t } = useTranslation()
  const location = useLocation()

  return (
    <Page
      title={t('notFoundPage.title')}
      description={t('notFoundPage.description', { url: <code key="path">{location.pathname}</code> })}
      className={s.NotFoundPage}
      data-testid={TEST_HOOKS.NOTFOUNDPAGE}
    >
      <Button
        size="large"
        color="primary"
        variant="contained"
        startIcon={<HomeOutlined />}
        to="/app" replace
        component={RouterLink}
      >
        {t('notFoundPage.homeButton')}
      </Button>
    </Page>
  )
}

const TEST_HOOKS = {
  NOTFOUNDPAGE: 'notFoundPage'
}

// FIXME: Either add props or remove the interface!
// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface Props {

}

const withObserver = observer(NotFoundPage)

export default withObserver

export {
  withObserver as NotFoundPage,
  TEST_HOOKS,
  type Props
}
