import { FC, ReactNode } from 'react'

import { List, ListItem, ListItemText } from '@mui/material'
import clsx from 'clsx'
import { observer } from 'mobx-react-lite'

import s from './DistinctList.module.scss'

const DistinctListItem: FC<DistinctListItemProps> = observer(({ label, value, action }) => {
  return (
    <ListItem secondaryAction={action} disableGutters>
      <ListItemText
        className={s.ListItem}
        primary={value}
        primaryTypographyProps={{ className: s.ListValue }}
        secondary={label}
        secondaryTypographyProps={{ className: s.ListLabel }}
      />
    </ListItem>
  )
})

interface DistinctListItemProps {
  label?: string
  value: string | ReactNode
  action?: ReactNode
}

const DistinctList: FC<Props> = ({ items, className, children = null }) => {
  return (
    <List className={clsx(s.DistinctList, className)} data-testid={TEST_HOOKS.DISTINCTLIST} disablePadding>
      {items?.map((item, idx) => (
        <DistinctListItem key={idx} value={item.value} label={item.label} action={item.action} />
      ))}
      {children}
    </List>
  )
}

const TEST_HOOKS = {
  DISTINCTLIST: 'distinctList'
}

interface Props {
  items?: DistinctListItemProps[]
  className?: string
  children?: ReactNode
}

const withObserver = observer(DistinctList)

export default withObserver

export {
  withObserver as DistinctList,
  DistinctListItem,
  TEST_HOOKS,
  type Props,
  type DistinctListItemProps
}
