import { FC, ReactNode } from 'react'

import { MoreVertOutlined } from '@mui/icons-material'
import { IconButton, Menu } from '@mui/material'
import {
  usePopupState,
  bindTrigger,
  bindMenu
} from 'material-ui-popup-state/hooks'
import { observer } from 'mobx-react-lite'

import s from './MoreMenu.module.scss'

const MoreMenu: FC<Props> = ({ popupId, ButtonProps = {}, MenuProps = {}, children }) => {
  const popupState = usePopupState({ variant: 'popover', popupId })
  return (
    <div className={s.MoreMenu} data-testid={TEST_HOOKS.MOREMENU}>
      <IconButton {...ButtonProps} {...bindTrigger(popupState)} data-testid={TEST_HOOKS.BUTTON}>
        <MoreVertOutlined />
      </IconButton>
      <Menu {...MenuProps} {...bindMenu(popupState)} data-testid={TEST_HOOKS.MENU}>
        {children}
      </Menu>
    </div>
  )
}

const TEST_HOOKS = {
  MOREMENU: 'moreMenu',
  BUTTON: 'button',
  MENU: 'menu'
}

interface Props {
  popupId: string
  ButtonProps?: Object
  MenuProps?: Object
  children: ReactNode
}

const withObserver = observer(MoreMenu)

export default withObserver

export {
  withObserver as MoreMenu,
  TEST_HOOKS,
  type Props
}
