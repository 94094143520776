import { makeAutoObservable } from 'mobx'

/**
 * A generic store for handling loading state of pages and components.
 * isLoaded/isLoading refers to loading the entire entity, and in general happens only once (initially).
 *
 * Some entities (like paginated views) can additionally do an update, for which you should use the `isUpdating` field.
 * `isUpdating` generally means that the entity itself is loaded, but is updating/re-fetching/refreshing.
 */
class LoadingStore {
  isLoaded: boolean = false
  isLoading: boolean = false

  isUpdating: boolean = false

  constructor () {
    makeAutoObservable(this)
  }

  public reset (): void {
    this.isLoaded = false
    this.isLoading = false
    this.isUpdating = false
  }

  public startLoading (): void {
    this.isLoaded = false
    this.isLoading = true
  }

  public finishLoading (): void {
    this.isLoaded = true
    this.isLoading = false
  }

  public startUpdating (): void {
    this.isUpdating = true
  }

  public finishUpdating (): void {
    this.isUpdating = false
  }
}

export default LoadingStore
