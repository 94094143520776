import { FC, useEffect } from 'react'

import { HandymanOutlined, MinorCrashOutlined, PersonAddOutlined } from '@mui/icons-material'
import {
  Button,
  Card, CardActions,
  CardContent,
  CardHeader, Grow, Skeleton,
  Stack,
  Typography
} from '@mui/material'
import Grid from '@mui/material/Unstable_Grid2'
import { observer } from 'mobx-react-lite'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router'
import { Link as RouterLink } from 'react-router-dom'

import { useStores } from 'stores/hooks'

import {
  Page,
  ServicesTable,
  QuickActions,
  SmsApiOverview,
  EditClientForm,
  EditVehicleForm,
  EditServiceForm
} from 'components'

import s from './StartPage.module.scss'

const StartPage: FC<Props> = () => {
  const navigate = useNavigate()
  const { t } = useTranslation()
  const { services, smsApi, editClient, editVehicle, editService } = useStores()

  useEffect(() => {
    void services.fetchOpen()
    void services.getMonthlyIncome()
    void services.getWeeklyIncome()
    void smsApi.getAccount()
    return () => {
      services.reset()
      smsApi.reset()
    }
  }, [])

  const onClientCreated = (): void => {
    if (editClient.client != null) navigate(`/app/clients/${editClient.client.id}`)
  }

  const onVehicleCreated = (): void => {
    if (editVehicle.vehicle != null) navigate(`/app/cars/${editVehicle.vehicle.id}`)
  }

  const onServiceCreated = (): void => {
    if (editService.service != null) navigate(`/app/services/${editService.service.id}`)
  }

  return (
    <Page
      title={t('startPage.title')}
      description={t('startPage.description')}
      className={s.StartPage}
      data-testid={TEST_HOOKS.STARTPAGE}
    >
      <Grid spacing={4} container>
        <Grid xs={12} md={8}>
          <Stack spacing={4}>
            {services.loading.isLoading && (
              <Skeleton variant="rectangular" height={300} />
            )}
            <Grow in>
              <div>
                <QuickActions
                  variant="contained"
                  actions={[
                    {
                      name: t('startPage.actions.addClient'),
                      icon: <PersonAddOutlined />,
                      ButtonProps: {
                        onClick: () => editClient.startEdit()
                      }
                    },
                    {
                      name: t('startPage.actions.addCar'),
                      icon: <MinorCrashOutlined />,
                      ButtonProps: {
                        onClick: () => editVehicle.startEdit()
                      }
                    },
                    {
                      name: t('startPage.actions.addService'),
                      icon: <HandymanOutlined />,
                      ButtonProps: {
                        onClick: () => editService.startEdit()
                      }
                    }
                  ]}
                />
              </div>
            </Grow>
            {services.loading.isLoaded && (
              <Grow in>
                <Card>
                  <CardHeader title={t('startPage.services.title', { count: services.services.length })} />
                  <CardContent>
                    <ServicesTable size="small" services={services.services} />
                  </CardContent>
                  <CardActions>
                    <Button component={RouterLink} to="services">
                      {t('startPage.services.open')}
                    </Button>
                  </CardActions>
                </Card>
              </Grow>
            )}
          </Stack>
        </Grid>
        <Grid xs={12} md={4}>
          <Stack spacing={4}>
            <Grow in>
              <Card className={s.IncomeCard}>
                <CardHeader title={t('startPage.income.title')} />
                <CardContent>
                  <Typography variant="h6" component="p">
                    {services.weeklyIncome?.income} PLN
                  </Typography>
                  <Typography variant="subtitle2">
                    {t('startPage.income.week', { servicesCount: services.weeklyIncome?.servicesCount })}
                  </Typography>
                </CardContent>
                <CardContent>
                  <Typography variant="h6" component="p">
                    {services.monthlyIncome?.income} PLN
                  </Typography>
                  <Typography variant="subtitle2">
                    {t('startPage.income.month', { servicesCount: services.monthlyIncome?.servicesCount })}
                  </Typography>
                </CardContent>
              </Card>
            </Grow>
            <Grow in>
              <Card>
                <CardHeader title={t('startPage.smsApi.title')} subheader={t('startPage.smsApi.subheader')} />
                <CardContent>
                  {smsApi.error && <Typography color="error">Błąd podczas pobierania danych z SMS API.</Typography>}
                  {(!smsApi.error && smsApi.account != null) && <SmsApiOverview account={smsApi.account} />}
                </CardContent>
                <CardActions>
                  <Button href="https://smsapi.pl/" target="_blank" rel="noopener noreferrer">
                    {t('startPage.smsApi.open')}
                  </Button>
                </CardActions>
              </Card>
            </Grow>
          </Stack>
        </Grid>
      </Grid>
      <EditClientForm onPostSave={onClientCreated} />
      <EditVehicleForm onPostSave={onVehicleCreated} />
      <EditServiceForm onPostSave={onServiceCreated} />
    </Page>
  )
}

const TEST_HOOKS = {
  STARTPAGE: 'startPage'
}

// FIXME: Either add props or remove the interface!
// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface Props {

}

const withObserver = observer(StartPage)

export default withObserver

export {
  withObserver as StartPage,
  TEST_HOOKS,
  type Props
}
