import { FC } from 'react'

import { ChevronRightOutlined } from '@mui/icons-material'
import {
  IconButton,
  Link, Skeleton, Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tooltip
} from '@mui/material'
import times from 'lodash/times'
import { observer } from 'mobx-react-lite'
import { Link as RouterLink } from 'react-router-dom'

import { VehicleData } from 'services/types'

import { Plate } from 'components'

import s from './CarsTable.module.scss'

const CarsTable: FC<Props> = ({ cars, size = 'medium', isLoading = false }) => {
  const emptyRow = (
    <TableRow>
      <TableCell colSpan={3} align="center" className={s.EmptyRow}>
        Brak samochodów...
      </TableCell>
    </TableRow>
  )

  if (isLoading) {
    return (
      <Stack spacing={2}>
        {times(cars?.length ?? 25).map(idx => (
          <Skeleton key={idx} variant="rectangular" height={60} />
        ))}
      </Stack>
    )
  }

  return (
    <TableContainer className={s.CarsTable} data-testid={TEST_HOOKS.CARSTABLE}>
      <Table size={size}>
        <TableHead>
          <TableRow>
            <TableCell>
              Samochód
            </TableCell>
            <TableCell>
              Rejestracja
            </TableCell>
            <TableCell>
              Klient
            </TableCell>
            <TableCell />
          </TableRow>
        </TableHead>
        <TableBody>
          {(cars == null || cars.length === 0) && emptyRow}
          {cars?.map(car => {
            const client = car.attributes.client.data
            return (
              <TableRow key={car.id}>
                <TableCell>
                  <Link underline="hover" component={RouterLink} to={`/app/cars/${car.id}`}>
                    {car.attributes.manufacturer} {car.attributes.model}
                  </Link>
                </TableCell>
                <TableCell>
                  <Plate car={car} navigable />
                </TableCell>
                <TableCell>
                  {client != null && (
                    <Link underline="hover" component={RouterLink} to={`/app/clients/${client.id}`}>
                      {client.attributes.phone}
                    </Link>
                  )}
                </TableCell>
                <TableCell align="right">
                  <Tooltip title="Zobacz samochód">
                    <IconButton
                      size="large"
                      color="primary"
                      component={RouterLink}
                      to={`/app/cars/${car.id}`}
                      edge="end"
                    >
                      <ChevronRightOutlined />
                    </IconButton>
                  </Tooltip>
                </TableCell>
              </TableRow>
            )
          })}
        </TableBody>
      </Table>
    </TableContainer>
  )
}

const TEST_HOOKS = {
  CARSTABLE: 'carsTable'
}

interface Props {
  cars?: VehicleData[]
  size?: 'small' | 'medium'
  isLoading?: boolean
}

const withObserver = observer(CarsTable)

export default withObserver

export {
  withObserver as CarsTable,
  TEST_HOOKS,
  type Props
}
