import { AxiosResponse } from 'axios'
import { type StrapiBaseRequestParams } from 'strapi-sdk-js'

import { StrapiBackend } from './backends'
import { ApiUser, ApiUserUpdate } from './types'

class AccountService extends StrapiBackend {
  readonly BASE_URL: string = '/users'

  public async updateUser (
    id: string,
    data: ApiUserUpdate,
    params?: StrapiBaseRequestParams
  ): Promise<ApiUser | undefined> {
    const response = await this.put(this.resourceUrl(id), data, { params })
    return response.status !== 200 ? undefined : response.data
  }

  public async fetchUser (params?: StrapiBaseRequestParams): Promise<ApiUser | undefined> {
    const response: AxiosResponse<ApiUser> = await this.get(this.resourceUrl('me'), {
      params: {
        populate: ['avatar'],
        ...params
      }
    })
    return response.status !== 200 ? undefined : response.data
  }
}

export default AccountService
