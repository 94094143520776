import { FC, useEffect, useMemo } from 'react'

import {
  AddOutlined,
  ChevronRightOutlined,
  DeleteOutlined,
  DriveEtaOutlined,
  EditOutlined,
  ExtensionOutlined,
  HandymanOutlined,
  MoneyOutlined,
  PrintOutlined
} from '@mui/icons-material'
import { Button, Card, CardContent, CardHeader, Divider, Grow, IconButton, Tooltip } from '@mui/material'
import Grid from '@mui/material/Unstable_Grid2'
import { useConfirm } from 'material-ui-confirm'
import { observer } from 'mobx-react-lite'
import { useSnackbar } from 'notistack'
import { useNavigate, useParams } from 'react-router'
import { Link as RouterLink } from 'react-router-dom'

import { parseIdFromParams } from 'shared/utils'

import { useStores } from 'stores/hooks'

import { NotFoundPage } from 'pages'

import {
  ClientCard,
  DistinctList,
  EditVehicleForm, EditVehicleMileagesForm,
  EditVehiclePartsForm,
  MileagesTimeline,
  Page,
  PageLoader,
  Plate
} from 'components'

import s from './VehiclePage.module.scss'

const VehiclePage: FC<Props> = () => {
  const { id } = useParams()
  const parsedId: number = useMemo(() => parseIdFromParams(id), [id])
  const { vehicle, editVehicle, editVehicleParts, editVehicleMileages } = useStores()
  const removalConfirm = useConfirm()
  const navigate = useNavigate()
  const { enqueueSnackbar } = useSnackbar()
  const car = vehicle.vehicle
  const client = car?.attributes?.client?.data
  const services = car?.attributes?.services?.data

  useEffect(() => {
    if (isNaN(parsedId)) return
    void vehicle.fetchVehicle(parsedId)
    return () => vehicle.reset()
  }, [parsedId])

  const onRemove = (): void => {
    removalConfirm({
      description: `
        Czy jesteś pewien? Usuwając samochód usuniesz również wszystkie powiązane z nim serwisy.
        Tej akcji nie można cofnąć!`
    })
      .then(async () => {
        const removed = await vehicle.removeVehicle(parsedId)
        if (removed) navigate('/app/cars', { replace: true })
        else enqueueSnackbar('Nie udało się usunąć samochodu')
      })
      .catch(() => null)
  }

  const handleEditParts = (): void => {
    if (vehicle.vehicle == null) return
    editVehicleParts.startEdit(vehicle.vehicle.id, vehicle.vehicle.attributes.parts)
  }

  const handleEditMileages = (): void => {
    if (vehicle.vehicle == null) return
    editVehicleMileages.startEdit(vehicle.vehicle.id, vehicle.vehicle.attributes.mileages)
  }

  const onVehicleUpdated = (): void => {
    void vehicle.fetchVehicle(parsedId)
  }

  if (isNaN(parsedId) || (vehicle.loading.isLoaded && vehicle?.apiError?.status === 404)) {
    return <NotFoundPage />
  }

  const content = car != null && (
    <Grid container spacing={4} className={s.Main}>
      <Grid xs={12} md={6} xl={4}>
        <Grow in>
          <Card>
            <CardHeader
              title="Dane pojazdu"
              avatar={<DriveEtaOutlined />}
              action={
                <Tooltip title="Edytuj">
                  <IconButton onClick={() => editVehicle.startEdit(vehicle.vehicle)}>
                    <EditOutlined />
                  </IconButton>
                </Tooltip>
              }
            />
            <CardContent>
              <DistinctList
                items={[
                  {
                    label: 'Marka i model',
                    value: `${car.attributes.manufacturer} ${car.attributes.model}`
                  },
                  {
                    label: 'Numer rejestracyjny',
                    value: <Plate car={car} size="large" />
                  },
                  {
                    label: 'Rok produkcji',
                    value: car.attributes.year
                  },
                  {
                    label: 'VIN',
                    value: car.attributes.vin
                  },
                  {
                    label: 'Numer silnika',
                    value: car.attributes.engineNumber
                  },
                  {
                    label: 'Pojemność skokowa',
                    value: `${car.attributes.engineVolume ?? '?'} ccm`
                  },
                  {
                    label: 'Moc',
                    value: `${car.attributes.power ?? '?'} kW`
                  },
                  {
                    label: 'Rodzaj paliwa',
                    value: `${car.attributes?.fuel?.toUpperCase() ?? 'Nieznany'}`
                  }
                ]}
              />
            </CardContent>
          </Card>
        </Grow>
      </Grid>
      <Grid xs={12} md={6} xl={4}>
        <Grow in>
          <Card>
            <CardHeader
              title="Dane techniczne"
              avatar={<ExtensionOutlined />}
              action={
                <>
                  <Tooltip color="secondary" title="Drukuj dane techniczne">
                    <IconButton>
                      <PrintOutlined />
                    </IconButton>
                  </Tooltip>
                  <Tooltip title="Edytuj">
                    <IconButton onClick={handleEditParts}>
                      <EditOutlined />
                    </IconButton>
                  </Tooltip>
                </>
              }
            />
            <CardContent>
              <DistinctList
                items={car?.attributes.parts.map(part => ({ label: part.key, value: part.value }))}
              />
              <Divider />
              <DistinctList
                items={car?.attributes?.partsFromServices?.map(
                  part => ({ label: part.name, value: part.serialNumber })
                )}
              />
            </CardContent>
          </Card>
        </Grow>
      </Grid>
      <Grid xs={12} md={6} xl={4}>
        <Grow in>
          <Card>
            <CardHeader
              title="Przebiegi"
              avatar={<MoneyOutlined />}
              action={
                <Tooltip title="Edytuj">
                  <IconButton onClick={handleEditMileages}>
                    <EditOutlined />
                  </IconButton>
                </Tooltip>
              }
            />
            <CardContent>
              <MileagesTimeline
                items={car.attributes.mileages.map(mileage => ({
                  date: mileage.date,
                  mileage: mileage.mileage
                }))}
              />
            </CardContent>
          </Card>
        </Grow>
      </Grid>
      <Grid xs={12} md={6}>
        <Grow in>
          <Card>
            <CardHeader
              title="Serwisy"
              avatar={<HandymanOutlined />}
              action={
                <>
                  <Tooltip title="Drukuj historię pojazdu">
                    <IconButton color="secondary">
                      <PrintOutlined />
                    </IconButton>
                  </Tooltip>
                  <Tooltip title="Dodaj serwis dla samochodu">
                    <IconButton>
                      <AddOutlined />
                    </IconButton>
                  </Tooltip>
                </>
              }
            />
            <CardContent>
              {services != null && (
                <DistinctList
                  items={services.map(service => ({
                    label: service.attributes.errand,
                    value: service.attributes.date,
                    action: (
                      <IconButton edge="end" component={RouterLink} to={`/app/services/${service.id}`}>
                        <ChevronRightOutlined />
                      </IconButton>
                    )
                  }))}
                />
              )}
            </CardContent>
          </Card>
        </Grow>
      </Grid>
      {client != null && (
        <Grid xs={12} md={6}>
          <Grow in>
            <div>
              <ClientCard client={client} />
            </div>
          </Grow>
        </Grid>
      )}
    </Grid>
  )

  return (
    <Page
      title={'Samochód'}
      description="Zarządzaj samochodem"
      className={s.VehiclePage}
      data-testid={TEST_HOOKS.VEHICLEPAGE}
      action={(vehicle.loading.isLoaded && vehicle.apiError == null) && (
        <Button
          onClick={onRemove}
          variant="contained"
          color="error"
          size="large"
          startIcon={<DeleteOutlined />}
        >
          Usuń pojazd
        </Button>
      )}
    >
      {vehicle.loading.isLoading && <PageLoader />}
      {(vehicle.loading.isLoaded && vehicle.apiError == null) && content}
      <EditVehicleForm onPostSave={onVehicleUpdated} />
      <EditVehiclePartsForm onPostSave={onVehicleUpdated} />
      <EditVehicleMileagesForm onPostSave={onVehicleUpdated} />
    </Page>
  )
}

const TEST_HOOKS = {
  VEHICLEPAGE: 'vehiclePage'
}

// FIXME: Either add props or remove the interface!
// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface Props {

}

const withObserver = observer(VehiclePage)

export default withObserver

export {
  withObserver as VehiclePage,
  TEST_HOOKS,
  type Props
}
