import { FC, useState } from 'react'

import { KeyboardArrowDownOutlined, LogoutOutlined, PersonOutlined } from '@mui/icons-material'
import { Avatar, Button, Divider, ListItemIcon, ListItemText, Menu, MenuItem } from '@mui/material'
import { observer } from 'mobx-react-lite'
import { useTranslation } from 'react-i18next'
import { Link as RouterLink } from 'react-router-dom'

import { getMediaAbsolutePath } from 'shared/utils'

import { ApiMedia } from 'services/types'

import s from './UserWidget.module.scss'

const UserWidget: FC<Props> = ({ name, avatar = undefined, initials = false, onLogout }) => {
  const { t } = useTranslation()
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)

  const handleClose = (): void => {
    setAnchorEl(null)
  }

  return (
    <>
      <Button
        color="primary"
        className={s.UserWidget}
        data-testid={TEST_HOOKS.USERWIDGET}
        onClick={e => setAnchorEl(e.currentTarget)}
        startIcon={
          <Avatar
            className={s.Avatar}
            src={avatar?.url !== undefined ? getMediaAbsolutePath(avatar.url) : undefined}
          />
        }
        endIcon={<KeyboardArrowDownOutlined />}
      >
        {initials ? name.split(' ').map(part => part[0]) : name}
      </Button>
      <Menu
        open={Boolean(anchorEl)}
        onClose={() => setAnchorEl(null)}
        anchorEl={anchorEl}
      >
        <MenuItem disabled className={s.Name}>
          {t('app.welcome')} {name}
        </MenuItem>
        <Divider />
        <MenuItem component={RouterLink} to="/app/account" onClick={handleClose}>
          <ListItemIcon>
            <PersonOutlined />
          </ListItemIcon>
          <ListItemText>
            {t('app.manageAccount')}
          </ListItemText>
        </MenuItem>
        <MenuItem
          onClick={() => {
            handleClose()
            onLogout()
          }}
          >
          <ListItemIcon>
            <LogoutOutlined fontSize="small" />
          </ListItemIcon>
          <ListItemText>
            {t('app.logout')}
          </ListItemText>
        </MenuItem>
      </Menu>
    </>
  )
}

const TEST_HOOKS = {
  USERWIDGET: 'userWidget'
}

interface Props {
  name: string
  avatar?: ApiMedia
  initials?: boolean
  onLogout: Function
}

const withObserver = observer(UserWidget)

export default withObserver

export {
  withObserver as UserWidget,
  TEST_HOOKS,
  type Props
}
