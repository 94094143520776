import { makeAutoObservable, runInAction } from 'mobx'

import { parseStrapiError } from 'shared/utils'

import { ClientsService } from 'services'

import { ClientData, Pagination } from 'services/types'

import ApiErrorStore from '../ApiErrorStore'
import LoadingStore from '../LoadingStore'
import PaginationStore from '../PaginationStore'
import type RootStore from '../RootStore'

const commonParams = {
  sort: 'id:desc'
}

class ClientsStore {
  rootStore: RootStore

  searchQuery?: string
  clients: ClientData[] = []
  pagination: PaginationStore = new PaginationStore()
  loading: LoadingStore = new LoadingStore()
  apiError?: ApiErrorStore

  constructor (rootStore: RootStore) {
    makeAutoObservable(this)
    this.rootStore = rootStore
  }

  public reset (): void {
    this.clients = []
    this.loading.reset()
    this.pagination.reset()
  }

  public async fetchClients (): Promise<void> {
    this.apiError = undefined
    this.searchQuery = undefined
    this.loading.startLoading()
    const service = new ClientsService(this.rootStore.token)
    const clients = await service.find(commonParams)
    runInAction(() => {
      this.pagination.update(clients.data.meta.pagination as Pagination)
      this.clients = clients.data.data
      this.loading.finishLoading()
    })
  }

  public async changePage (page: number, updatePagination: boolean = false): Promise<void> {
    this.loading.startUpdating()
    this.pagination.setPage(page)
    this.apiError = undefined
    const service = new ClientsService(this.rootStore.token)
    try {
      const response = await service.find({ ...commonParams, pagination: this.pagination.queryParams })
      runInAction(() => {
        this.clients = response.data.data
        if (updatePagination) this.pagination.update(response.data.meta.pagination as Pagination)
      })
    } finally {
      this.loading.finishUpdating()
    }
  }

  public async search (query: string): Promise<void> {
    if (this.searchQuery === undefined && query === '') return

    this.apiError = undefined

    if (query == null || query === '') {
      this.searchQuery = undefined
      await this.changePage(1, true)
      return
    }

    this.loading.startUpdating()
    this.searchQuery = query
    this.pagination.reset()

    try {
      const service = new ClientsService(this.rootStore.token)
      const response = await service.search(query, 50, { params: commonParams })
      runInAction(() => {
        this.clients = response.data.data
        this.pagination.update(response.data.meta.pagination as Pagination)
      })
    } catch (e) {
      runInAction(() => { this.apiError = new ApiErrorStore(parseStrapiError(e)) })
    } finally {
      this.loading.finishUpdating()
    }
  }
}

export default ClientsStore
