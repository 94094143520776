import { FC, ReactNode } from 'react'

import { AutoAwesomeOutlined } from '@mui/icons-material'
import { Button, FormHelperText } from '@mui/material'
import { observer } from 'mobx-react-lite'

import s from './AutoGenerateButton.module.scss'

const AutoGenerateButton: FC<Props> = ({ label, helperText, onClick }) => {
  return (
    <div className={s.AutoGenerateButton} data-testid={TEST_HOOKS.AUTOGENERATEBUTTON}>
      <Button
        startIcon={<AutoAwesomeOutlined />}
        onClick={onClick}
      >
        {label}
      </Button>
      {helperText != null && (
        <FormHelperText>{helperText}</FormHelperText>
      )}
    </div>
  )
}

const TEST_HOOKS = {
  AUTOGENERATEBUTTON: 'autoGenerateButton'
}

interface Props {
  label: string
  helperText?: ReactNode
  onClick: () => any
}

const withObserver = observer(AutoGenerateButton)

export default withObserver

export {
  withObserver as AutoGenerateButton,
  TEST_HOOKS,
  type Props
}
