import { FC, useEffect, useRef } from 'react'

import { yupResolver } from '@hookform/resolvers/yup'
import { Stack, TextField } from '@mui/material'
import { observer } from 'mobx-react-lite'
import { useSnackbar } from 'notistack'
import { Controller, SubmitHandler, useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import * as yup from 'yup'
import { InferType } from 'yup'

import { PHONE_REGEX } from 'shared/constants'
import { useRemoteErrors } from 'shared/utils'

import { useStores } from 'stores/hooks'

import { DialogForm } from 'components'

import s from './EditClientForm.module.scss'

const schema = yup.object({
  phone: yup.string()
    .matches(new RegExp(PHONE_REGEX), { excludeEmptyString: true, message: 'editClient.form.phone.errors.format' })
    .required('editClient.form.phone.errors.required'),
  tin: yup.string()
})

type EditClientFormSchema = InferType<typeof schema>

const EditClientForm: FC<Props> = ({ onPostSave }) => {
  const { t } = useTranslation()
  const { enqueueSnackbar } = useSnackbar()
  const { editClient } = useStores()
  const ref = useRef<HTMLFormElement>(null)
  const { control, formState, handleSubmit, reset, setError } = useForm<EditClientFormSchema>({
    mode: 'all',
    resolver: yupResolver(schema),
    defaultValues: editClient.defaultValues
  })

  useEffect(() => {
    reset(editClient.defaultValues)
  }, [editClient.client])

  useRemoteErrors<EditClientFormSchema>(setError, editClient.apiError?.details.errors)

  const onSubmit: SubmitHandler<EditClientFormSchema> = async (data): Promise<void> => {
    await editClient.save(data)
    if (editClient.client != null) {
      enqueueSnackbar(t('editClient.save.success'), { variant: 'success' })
      if (onPostSave != null) onPostSave()
      editClient.reset()
      return
    }
    enqueueSnackbar(t('editClient.save.error'), { variant: 'error' })
  }

  const onCancel = (): void => editClient.reset()

  return (
    <DialogForm
      open={editClient.isOpen}
      title={t(editClient.client != null ? 'editClient.title.edit' : 'editClient.title.add') }
      formRef={ref}
      isValid={formState.isDirty && formState.isValid}
      isUpdating={editClient.loading.isUpdating}
      onCancel={onCancel}
      className={s.AddClientForm}
    >
      {/* eslint-disable-next-line @typescript-eslint/no-misused-promises */}
      <form ref={ref} onSubmit={handleSubmit(onSubmit)}>
        <Stack spacing={2}>
          <Controller
            control={control}
            name="phone"
            render={({ field }) => (
              <TextField
                required
                fullWidth
                label={t('editClient.form.phone.label')}
                InputProps={{ ...field }}
                error={Boolean(formState.errors.phone)}
                helperText={t(formState.errors?.phone?.message ?? '')}
              />
            )}
          />
          <Controller
            control={control}
            name="tin"
            render={({ field }) => (
              <TextField
                fullWidth
                label={t('editClient.form.tin.label')}
                InputProps={{ ...field }}
                error={Boolean(formState.errors.tin)}
                helperText={t(formState.errors?.tin?.message ?? '')}
              />
            )}
          />
        </Stack>
      </form>
    </DialogForm>
  )
}

const TEST_HOOKS = {
  ADDCLIENTFORM: 'addClientForm'
}

interface Props {
  onPostSave?: () => any
}

const withObserver = observer(EditClientForm)

export default withObserver

export {
  withObserver as EditClientForm,
  TEST_HOOKS,
  type Props,
  type EditClientFormSchema
}
