import { FC } from 'react'

import { HandymanOutlined, LoginOutlined } from '@mui/icons-material'
import { AppBar, Button, Toolbar, Typography } from '@mui/material'
import clsx from 'clsx'
import { observer } from 'mobx-react-lite'
import { useTranslation } from 'react-i18next'
import { useLocation } from 'react-router'
import { Link as RouterLink } from 'react-router-dom'

import s from './PublicAppBar.module.scss'

const PublicAppBar: FC<Props> = ({ variant = 'default' }) => {
  const location = useLocation()
  const { t } = useTranslation()

  return (
    <AppBar className={clsx(s.PublicAppBar, s[variant])} elevation={0} data-testid={TEST_HOOKS.PUBLICAPPBAR}>
      <Toolbar className={s.Toolbar}>
        <Typography component={RouterLink} to="/" variant="h6" className={s.Title}>
          <HandymanOutlined className={s.TitleIcon} />
          <span className={s.TitleName}>{t('app.name')}</span>
        </Typography>
        {location.pathname !== '/login' && (
          <Button
            size="large"
            startIcon={<LoginOutlined />}
            component={RouterLink}
            to="/login"
          >
            Zaloguj się
          </Button>
        )}
      </Toolbar>
    </AppBar>
  )
}

const TEST_HOOKS = {
  PUBLICAPPBAR: 'publicAppBar'
}

interface Props {
  variant?: 'default' | 'inverted'
}

const withObserver = observer(PublicAppBar)

export default withObserver

export {
  withObserver as PublicAppBar,
  TEST_HOOKS,
  type Props
}
