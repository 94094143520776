import { FC, Suspense } from 'react'

import loadable from '@loadable/component'
import { CssBaseline, ThemeProvider, StyledEngineProvider, Box } from '@mui/material'
import { ConfirmProvider } from 'material-ui-confirm'
import { observer } from 'mobx-react-lite'
import { SnackbarProvider } from 'notistack'
import { Routes, Route } from 'react-router-dom'

import { defaultOptions } from 'shared/confirmation'
import { defaultTheme } from 'shared/theme'

import {
  LogInPage,
  NotFoundPage,
  IndexPage
} from 'pages'

import { AppWrapper, PageLoader } from 'components'

import s from './App.module.scss'

const AsyncRoute: FC<AsyncRouteProps> = ({ pageName }) => {
  const PageComponent = loadable(async () => await import(`pages/${pageName}/${pageName}`))
  return (
    <>
      <Suspense fallback={<PageLoader />}>
        <PageComponent />
      </Suspense>
    </>
  )
}

interface AsyncRouteProps {
  pageName: string
}

const App: FC = () => {
  return (
    <StyledEngineProvider injectFirst>
      <ThemeProvider theme={defaultTheme}>
        <SnackbarProvider
          preventDuplicate
          maxSnack={3}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'right'
          }}
        >
          <ConfirmProvider defaultOptions={defaultOptions}>
            <CssBaseline />
            <Box className={s.App}>
              <Routes>
                <Route path="/" element={<IndexPage />} />
                <Route path="login" element={<LogInPage />} />
                <Route path="app" element={<AppWrapper />}>
                  <Route index element={<AsyncRoute pageName="StartPage" />} />
                  <Route path="services" element={<AsyncRoute pageName="ServicesPage" />} />
                  <Route path="services/:id" element={<AsyncRoute pageName="ServicePage" />} />
                  <Route path="cars" element={<AsyncRoute pageName="CarsPage" />} />
                  <Route path="cars/:id" element={<AsyncRoute pageName="VehiclePage" />} />
                  <Route path="clients" element={<AsyncRoute pageName="ClientsPage" />} />
                  <Route path="clients/:id" element={<AsyncRoute pageName="ClientPage" />} />
                  <Route path="documents" element={<AsyncRoute pageName="NotFoundPage" />} />
                  <Route path="settings" element={<AsyncRoute pageName="SettingsPage" />} />
                  <Route path="account" element={<AsyncRoute pageName="AccountSettingsPage" />} />
                  <Route path="*" element={<NotFoundPage />} />
                </Route>
              </Routes>
            </Box>
          </ConfirmProvider>
        </SnackbarProvider>
      </ThemeProvider>
    </StyledEngineProvider>
  )
}

export default observer(App)

export {
  AppWrapper,
  App
}
