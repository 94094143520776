import { FC, SyntheticEvent, useState } from 'react'

import { SearchOutlined } from '@mui/icons-material'
import { IconButton, InputAdornment, TextField } from '@mui/material'
import clsx from 'clsx'
import { observer } from 'mobx-react-lite'

import s from './Search.module.scss'

const Search: FC<Props> = ({
  label, placeholder, defaultValue = '', onSearch,
  className
}) => {
  const [value, setValue] = useState<string>(defaultValue)

  const handleSubmit = (e: SyntheticEvent): void => {
    e.preventDefault()
    onSearch(value)
  }

  return (
    <form onSubmit={handleSubmit}>
      <TextField
        fullWidth
        className={clsx(s.Search, className)}
        data-testid={TEST_HOOKS.SEARCH}
        label={label}
        placeholder={placeholder}
        value={value}
        onChange={(e) => setValue(e.target.value)}
        InputLabelProps={{
          shrink: label != null && placeholder != null
        }}
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              <IconButton color="primary" size="large" onClick={() => onSearch(value)}>
                <SearchOutlined />
              </IconButton>
            </InputAdornment>
          )
        }}
      >
      </TextField>
    </form>
  )
}

const TEST_HOOKS = {
  SEARCH: 'search'
}

interface Props {
  label?: string
  placeholder?: string
  defaultValue?: string
  onSearch: (value: string) => any
  className?: string
}

const withObserver = observer(Search)

export default withObserver

export {
  withObserver as Search,
  TEST_HOOKS,
  type Props
}
