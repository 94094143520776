import { FC, MouseEventHandler } from 'react'

import { HandymanOutlined, MenuOpenOutlined, MenuOutlined } from '@mui/icons-material'
import { AppBar, IconButton, Toolbar, Typography, useMediaQuery, useTheme } from '@mui/material'
import { observer } from 'mobx-react-lite'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router'

import { useStores } from 'stores/hooks'

import { UserWidget } from 'components'

import s from './AppNavbar.module.scss'

const AppNavbar: FC<Props> = ({ isMenuOpen = false, onMenuClick }) => {
  const navigate = useNavigate()
  const { auth } = useStores()
  const theme = useTheme()
  const mdUp = useMediaQuery(theme.breakpoints.up('md'))
  const { t } = useTranslation()

  const Icon = isMenuOpen ? MenuOpenOutlined : MenuOutlined

  const username: string = auth.user?.name ?? 'Motocar'

  return (
    <AppBar
      className={s.AppNavbar}
      position="fixed"
      data-testid={TEST_HOOKS.APPNAVBAR}
      elevation={0}
    >
      <Toolbar className={s.Toolbar}>
        {!mdUp && (
          <IconButton onClick={onMenuClick} size="large" color="primary">
            <Icon fontSize="medium" />
          </IconButton>
        )}
        <Typography variant="h6" component="div" color="primary" className={s.Title}>
          <HandymanOutlined className={s.TitleIcon} />
          <span className={s.TitleName}>{t('app.name')}</span>
        </Typography>
        <UserWidget
          name={username}
          avatar={auth.user?.avatar}
          initials={!mdUp}
          onLogout={() => {
            auth.deAuthorize()
            navigate('/')
          }}
        />
      </Toolbar>
    </AppBar>
  )
}

const TEST_HOOKS = {
  APPNAVBAR: 'appNavbar'
}

interface Props {
  isMenuOpen?: boolean
  onMenuClick: MouseEventHandler
}

const withObserver = observer(AppNavbar)

export default withObserver

export {
  withObserver as AppNavbar,
  TEST_HOOKS,
  type Props
}
