import { AxiosResponse } from 'axios'
import { type StrapiBaseRequestParams, type StrapiRequestParams } from 'strapi-sdk-js'

import { StrapiBackend } from './backends'
import { ApiMedia, ApiUpload } from './types'

class StrapiMediaService extends StrapiBackend {
  BASE_URL: string = '/upload/files'

  public async fetchAll (params?: StrapiRequestParams): Promise<ApiMedia[]> {
    const response: AxiosResponse<ApiMedia[]> = await this.get(this.url, { params })
    return response.data
  }

  public async fetchOne (id: number | string, params?: StrapiBaseRequestParams): Promise<ApiMedia> {
    const response: AxiosResponse<ApiMedia> = await this.get(`${this.url}/${id}`, { params })
    return response.data
  }

  public async uploadMedia (data: ApiUpload, params?: StrapiBaseRequestParams): Promise<ApiMedia> {
    const formData = new FormData()
    Object.entries(data).forEach(([key, value]) => {
      formData.append(key, value)
    })
    const response: AxiosResponse<ApiMedia> = await this.post(`${this.HOST}/upload`, formData, { params })
    return response.data
  }
}

export default StrapiMediaService
