import { makeAutoObservable } from 'mobx'

import { StrapiError } from 'services/types'

class ApiErrorStore implements StrapiError {
  status
  name
  message
  details

  constructor ({ status, name, message, details }: StrapiError) {
    makeAutoObservable(this)
    this.status = status
    this.name = name
    this.message = message
    this.details = details
  }
}

export default ApiErrorStore
