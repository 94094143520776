import { FC, useState } from 'react'

import { VisibilityOffOutlined, VisibilityOutlined } from '@mui/icons-material'
import { IconButton, InputAdornment, TextField, type TextFieldProps, Tooltip } from '@mui/material'
import clsx from 'clsx'
import { observer } from 'mobx-react-lite'
import { useTranslation } from 'react-i18next'

import s from './SecretTextField.module.scss'

const SecretTextField: FC<TextFieldProps> = ({ className, ...rest }) => {
  const { t } = useTranslation()
  const [showValue, setShowValue] = useState<boolean>(false)
  const VisibilityIcon = showValue ? VisibilityOffOutlined : VisibilityOutlined

  return (
    <TextField
      {...rest}
      className={clsx(s.SecretTextField, className)}
      data-testid={TEST_HOOKS.SECRETTEXTFIELD}
      type={showValue ? 'text' : 'password'}
      InputProps={{
        ...(rest?.InputProps ?? {}),
        endAdornment: (
          <InputAdornment position="end">
            <Tooltip title={showValue ? t('secretTextField.hide.tooltip') : t('secretTextField.show.tooltip')}>
              <IconButton onClick={() => setShowValue(!showValue)}>
                <VisibilityIcon />
              </IconButton>
            </Tooltip>
          </InputAdornment>
        )
      }}
    />
  )
}

const TEST_HOOKS = {
  SECRETTEXTFIELD: 'secretTextField'
}

const withObserver = observer(SecretTextField)

export default withObserver

export {
  withObserver as SecretTextField,
  TEST_HOOKS,
  type TextFieldProps as Props
}
