import Cookies from 'js-cookie'
import { makeAutoObservable, runInAction } from 'mobx'

import { JWT_COOKIE_NAME } from 'shared/constants'
import { isAxiosError } from 'shared/utils'

import { AuthService, AccountService } from 'services'

import { ApiUser, StrapiError } from 'services/types'

import { type RootStore } from 'stores'

/**
 * Handles user (de)authorization, fetching user data, checking authorization status, etc.
 */
class AuthStore {
  rootStore: RootStore

  user?: ApiUser
  error?: StrapiError

  constructor (rootStore: RootStore) {
    makeAutoObservable(this)
    this.rootStore = rootStore
  }

  saveTokenToCookie (token: string): void {
    Cookies.set(JWT_COOKIE_NAME, token, { expires: 365, secure: true, sameSite: 'Strict' })
  }

  /**
   * Sets JWT token from cookie, if it is set.
   */
  public setTokenFromCookie (): boolean {
    const cookie = Cookies.get(JWT_COOKIE_NAME)
    if (cookie === undefined) return false
    this.rootStore.token = cookie
    return true
  }

  /**
   * Checks whether the user is actually authorized to use the API.
   */
  public async isAuthorized (): Promise<boolean> {
    const service = new AuthService(this.rootStore.token)
    return await service.isAuthorized()
  }

  /**
   * Log in user, using their username and password.
   */
  public async authorize (username: string, password: string): Promise<boolean> {
    this.error = undefined
    const service = new AuthService(this.rootStore.token)
    try {
      const token = await service.authorize(username, password)
      if (token === undefined) return false
      this.rootStore.token = token
      this.saveTokenToCookie(token)
      await this.fetchUser()
      return true
    } catch (e) {
      if (isAxiosError(e)) {
        runInAction(() => {
          // @ts-expect-error
          this.error = e.response?.data?.error
        })
      }
      return false
    }
  }

  /**
   * Log out user from both the frontend platform, as well as the API.
   */
  public deAuthorize (): boolean {
    Cookies.remove(JWT_COOKIE_NAME)
    this.rootStore.token = undefined
    return true
  }

  /**
   * Fetch current user data.
   */
  public async fetchUser (): Promise<ApiUser | undefined> {
    const service = new AccountService(this.rootStore.token)
    const user = await service.fetchUser()
    runInAction(() => { this.user = user })
    return user
  }
}

export default AuthStore
