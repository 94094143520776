import { FC, SyntheticEvent, useEffect, useRef, useState } from 'react'

import { yupResolver } from '@hookform/resolvers/yup'
import {
  Autocomplete, AutocompleteInputChangeReason,
  Divider,
  FormControl, FormHelperText,
  InputAdornment,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography
} from '@mui/material'
import Grid from '@mui/material/Unstable_Grid2'
import { observer } from 'mobx-react-lite'
import { useSnackbar } from 'notistack'
import { Controller, SubmitHandler, useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import * as yup from 'yup'
import { InferType } from 'yup'

import { adaptMeiliResource } from 'shared/adapters'
import { emptyStringToNull, useRemoteErrors } from 'shared/utils'

import { MeiliBackend } from 'services/backends'
import { ClientData, MeiliResource } from 'services/types'

import { useStores } from 'stores/hooks'

import { DialogForm } from 'components'

import s from './EditVehicleForm.module.scss'

const schema = yup.object({
  client: yup.mixed().required(),
  plate: yup.string().required(),
  manufacturer: yup.string().required(),
  model: yup.string().required(),
  vin: yup.string().nullable().transform(emptyStringToNull),
  year: yup.number().nullable().transform(emptyStringToNull),
  engineVolume: yup.number().nullable().transform(emptyStringToNull),
  power: yup.number().nullable().transform(emptyStringToNull),
  engineNumber: yup.string().nullable().transform(emptyStringToNull),
  fuel: yup.string().nullable().transform(emptyStringToNull),
  lastOilReplacementMileage: yup.number().nullable().transform(emptyStringToNull),
  lastOilReplacementDate: yup.string().nullable().transform(emptyStringToNull),
  lastTimingGearReplacementMileage: yup.number().nullable().transform(emptyStringToNull),
  lastTimingGearReplacementDate: yup.string().nullable().transform(emptyStringToNull)
})

type EditVehicleFormSchema = InferType<typeof schema>

const EditVehicleForm: FC<Props> = ({ onPostSave }) => {
  const { t } = useTranslation()
  const ref = useRef(null)
  const { enqueueSnackbar } = useSnackbar()
  const { editVehicle } = useStores()
  const { control, formState, handleSubmit, reset, setError } = useForm<EditVehicleFormSchema>({
    mode: 'all',
    resolver: yupResolver(schema),
    defaultValues: editVehicle.defaultValues
  })
  const [options, setOptions] = useState<Array<{ id: number, label: string }>>([])

  useEffect(() => {
    reset(editVehicle.defaultValues)
  }, [editVehicle.defaultValues])

  useRemoteErrors(setError, editVehicle.apiError?.details.errors)

  const onSubmit: SubmitHandler<EditVehicleFormSchema> = async (data) => {
    if (editVehicle.vehicle != null) delete data.client
    else data = { ...data, client: data.client.id }
    const response = await editVehicle.save(data)
    if (response != null) {
      enqueueSnackbar('Zapisano dane samochodu', { variant: 'success' })
      if (onPostSave != null) onPostSave()
      editVehicle.reset()
      return
    }
    enqueueSnackbar('Błąd podczas zapisywania danych samochodu', { variant: 'error' })
  }

  const handleClientChange = async (e: SyntheticEvent, value: string, reason: AutocompleteInputChangeReason): Promise<void> => {
    if (value == null || value === '') {
      setOptions([])
      return
    }
    const meiliBackend = new MeiliBackend()
    const response = await meiliBackend.search<MeiliResource<ClientData>>(
      'client', value, { attributesToRetrieve: ['_strapiId', 'phone'] }
    )
    const clients = response.hits.map(adaptMeiliResource)
    setOptions(clients.map(client => ({ id: client.id, label: client.attributes.phone })))
  }

  return (
    <DialogForm
      open={editVehicle.isOpen}
      formRef={ref}
      title={editVehicle.vehicle == null ? 'Dodaj samochód' : 'Edytuj dane samochodu'}
      isValid={formState.isValid}
      onCancel={() => editVehicle.reset()}
      className={s.EditVehicleForm}
      data-testid={TEST_HOOKS.EDITVEHICLEFORM}
    >
      {/* eslint-disable-next-line @typescript-eslint/no-misused-promises */}
      <Grid container spacing={3} component="form" onSubmit={handleSubmit(onSubmit)} ref={ref}>
        <Grid xs={12}>
          <Controller
            control={control}
            name="client"
            render={({ field }) => (
              <Autocomplete
                {...field}
                /* eslint-disable-next-line @typescript-eslint/no-misused-promises */
                onInputChange={handleClientChange}
                filterOptions={options => options}
                disabled={editVehicle.vehicle != null}
                onChange={(e, data) => field.onChange(data)}
                getOptionLabel={option => typeof option === 'string' ? option : option.label}
                isOptionEqualToValue={(option, value) => {
                  if (typeof value === 'string') return option.id === value
                  return option.id === value.id
                }}
                renderInput={params => (
                  <TextField
                    {...params}
                    required
                    label="Klient"
                    error={Boolean(formState.errors.client)}
                    // @ts-expect-error
                    helperText={t(formState.errors?.client?.message ?? '')}
                  />
                )}
                options={options}
              />
            )}
          />
        </Grid>
        <Grid xs={12}>
          <Divider />
        </Grid>
        <Grid xs={12}>
          <Controller
            control={control}
            name="plate"
            render={({ field }) => (
              <TextField
                required
                fullWidth
                label="Numer rejestracyjny"
                InputProps={field}
                error={Boolean(formState.errors.plate)}
                helperText={t(formState.errors?.plate?.message ?? '')}
              />
            )}
          />
        </Grid>
        <Grid xs={12}>
          <Controller
            control={control}
            name="manufacturer"
            render={({ field }) => (
              <TextField
                required
                fullWidth
                label="Marka"
                InputProps={field}
                error={Boolean(formState.errors.manufacturer)}
                helperText={t(formState.errors?.manufacturer?.message ?? '')}
              />
            )}
          />
        </Grid>
        <Grid xs={12}>
          <Controller
            control={control}
            name="model"
            render={({ field }) => (
              <TextField
                required
                fullWidth
                label="Model"
                InputProps={field}
                error={Boolean(formState.errors.model)}
                helperText={t(formState.errors?.model?.message ?? '')}
              />
            )}
          />
        </Grid>
        <Grid xs={12}>
          <Divider />
        </Grid>
        <Grid xs={12} sm={6}>
          <Controller
            control={control}
            name="year"
            render={({ field }) => (
              <TextField
                fullWidth
                type="number"
                label="Rok produkcji"
                InputProps={field}
                inputProps={{ min: 1900 }}
                error={Boolean(formState.errors.year)}
                helperText={t(formState.errors?.year?.message ?? '')}
              />
            )}
          />
        </Grid>
        <Grid xs={12} sm={6}>
          <Controller
            control={control}
            name="vin"
            render={({ field }) => (
              <TextField
                fullWidth
                label="VIN"
                InputProps={field}
                error={Boolean(formState.errors.vin)}
                helperText={t(formState.errors?.vin?.message ?? '')}
              />
            )}
          />
        </Grid>
        <Grid xs={12} sm={6}>
          <Controller
            control={control}
            name="engineVolume"
            render={({ field }) => (
              <TextField
                fullWidth
                type="number"
                label="Pojemność silnika"
                InputProps={{
                  ...field,
                  endAdornment: (
                    <InputAdornment position="end">
                      ccm
                    </InputAdornment>
                  )
                }}
                inputProps={{ min: 0 }}
                error={Boolean(formState.errors.engineVolume)}
                helperText={t(formState.errors?.engineVolume?.message ?? '')}
              />
            )}
          />
        </Grid>
        <Grid xs={12} sm={6}>
          <Controller
            control={control}
            name="power"
            render={({ field }) => (
              <TextField
                fullWidth
                type="number"
                label="Moc silnika"
                InputProps={{
                  ...field,
                  endAdornment: (
                    <InputAdornment position="end">
                      kW
                    </InputAdornment>
                  )
                }}
                inputProps={{ min: 0 }}
                error={Boolean(formState.errors.power)}
                helperText={t(formState.errors?.power?.message ?? '')}
              />
            )}
          />
        </Grid>
        <Grid xs={12} sm={6}>
          <Controller
            control={control}
            name="engineNumber"
            render={({ field }) => (
              <TextField
                fullWidth
                label="Numer silnika"
                InputProps={field}
                error={Boolean(formState.errors.engineNumber)}
                helperText={t(formState.errors?.engineNumber?.message ?? '')}
              />
            )}
          />
        </Grid>
        <Grid xs={12} sm={6}>
          <FormControl fullWidth>
            <InputLabel>Rodzaj paliwa</InputLabel>
            <Controller
              control={control}
              name="fuel"
              render={({ field }) => (
                <Select
                  {...field}
                  fullWidth
                  label="Rodzja paliwa"
                  error={Boolean(formState.errors.fuel)}
                >
                  <MenuItem value="pb">PB</MenuItem>
                  <MenuItem value="on">ON</MenuItem>
                </Select>
              )}
            />
            {formState.errors?.fuel != null && (
              <FormHelperText color="error">
                {t(formState.errors?.fuel?.message ?? '')}
              </FormHelperText>
            )}
          </FormControl>
        </Grid>
        <Grid xs={12}>
          <Typography variant="subtitle2">
            Wymiana oleju
          </Typography>
        </Grid>
        <Grid xs={12} sm={6}>
          <Controller
            control={control}
            name="lastOilReplacementDate"
            render={({ field }) => (
              <TextField
                fullWidth
                type="date"
                label="Data"
                InputProps={field}
                InputLabelProps={{ shrink: true }}
                error={Boolean(formState.errors.lastOilReplacementDate)}
                helperText={t(formState.errors?.lastOilReplacementDate?.message ?? '')}
              />
            )}
          />
        </Grid>
        <Grid xs={12} sm={6}>
          <Controller
            control={control}
            name="lastOilReplacementMileage"
            render={({ field }) => (
              <TextField
                fullWidth
                type="number"
                label="Przebieg"
                InputProps={{
                  ...field,
                  endAdornment: (
                    <InputAdornment position="end">
                      km
                    </InputAdornment>
                  )
                }}
                inputProps={{ min: 0 }}
                error={Boolean(formState.errors.lastOilReplacementMileage)}
                helperText={t(formState.errors?.lastOilReplacementMileage?.message ?? '')}
              />
            )}
          />
        </Grid>
        <Grid xs={12}>
          <Typography variant="subtitle2">
            Wymiana rozrządu
          </Typography>
        </Grid>
        <Grid xs={12} sm={6}>
          <Controller
            control={control}
            name="lastTimingGearReplacementDate"
            render={({ field }) => (
              <TextField
                fullWidth
                type="date"
                label="Data"
                InputProps={field}
                InputLabelProps={{ shrink: true }}
                error={Boolean(formState.errors.lastTimingGearReplacementDate)}
                helperText={t(formState.errors?.lastTimingGearReplacementDate?.message ?? '')}
              />
            )}
          />
        </Grid>
        <Grid xs={12} sm={6}>
          <Controller
            control={control}
            name="lastTimingGearReplacementMileage"
            render={({ field }) => (
              <TextField
                fullWidth
                type="number"
                label="Przebieg"
                InputProps={{
                  ...field,
                  endAdornment: (
                    <InputAdornment position="end">
                      km
                    </InputAdornment>
                  )
                }}
                inputProps={{ min: 0 }}
                error={Boolean(formState.errors.lastTimingGearReplacementMileage)}
                helperText={t(formState.errors?.lastTimingGearReplacementMileage?.message ?? '')}
              />
            )}
          />
        </Grid>
      </Grid>
    </DialogForm>
  )
}

const TEST_HOOKS = {
  EDITVEHICLEFORM: 'editVehicleForm'
}

interface Props {
  onPostSave?: () => any
}

const withObserver = observer(EditVehicleForm)

export default withObserver

export {
  withObserver as EditVehicleForm,
  TEST_HOOKS,
  type Props,
  type EditVehicleFormSchema
}
