import { FC, useEffect, useRef } from 'react'

import { yupResolver } from '@hookform/resolvers/yup'
import { AddOutlined, RemoveOutlined } from '@mui/icons-material'
import { Box, Button, IconButton, InputAdornment, TextField } from '@mui/material'
import Grid from '@mui/material/Unstable_Grid2'
import { observer } from 'mobx-react-lite'
import { useSnackbar } from 'notistack'
import { Controller, SubmitHandler, useFieldArray, useForm } from 'react-hook-form'
import * as yup from 'yup'
import { InferType } from 'yup'

import { emptyStringToNull } from 'shared/utils'

import { useStores } from 'stores/hooks'

import { ApiErrorMessage, DialogForm } from 'components'

import s from './EditVehicleMileagesForm.module.scss'

const schema = yup.object({
  mileages: yup.array().of(yup.object({
    date: yup.string().required('Data pomiaru przebiegu jest wymagana'),
    mileage: yup.number().required('Wartość przebiegu jest wymagana').transform(emptyStringToNull).nullable()
  }))
})

type EditVehicleMileagesFormSchema = InferType<typeof schema>

const EditVehicleMileagesForm: FC<Props> = ({ onPostSave }) => {
  const ref = useRef(null)
  const { enqueueSnackbar } = useSnackbar()
  const { editVehicleMileages } = useStores()
  const { control, formState, handleSubmit } = useForm<EditVehicleMileagesFormSchema>({
    mode: 'all',
    resolver: yupResolver(schema)
  })
  const { fields, append, remove } = useFieldArray({ name: 'mileages', control })

  useEffect(() => {
    remove()
    editVehicleMileages.mileages?.forEach(({ date, mileage }) => append({ date, mileage }))
  }, [editVehicleMileages.mileages])

  const onSubmit: SubmitHandler<EditVehicleMileagesFormSchema> = async (data) => {
    const response = await editVehicleMileages.save(data)
    if (response != null) {
      enqueueSnackbar('Zapisano przebiegi', { variant: 'success' })
      if (onPostSave != null) onPostSave()
      editVehicleMileages.reset()
      return
    }
    enqueueSnackbar('Błąd podczas zapisywania przebiegów', { variant: 'error' })
  }

  const getPartError = (idx: number): Record<string, any> | undefined => {
    if (formState.errors.mileages == null) return
    return formState.errors.mileages[idx]
  }

  return (
    <DialogForm
      open={editVehicleMileages.isOpen}
      title="Edytuj przebiegi"
      formRef={ref}
      isValid={formState.isValid}
      isUpdating={editVehicleMileages.loading.isUpdating}
      maxWidth="md"
      onCancel={() => editVehicleMileages.reset()}
      className={s.EditVehicleMileagesForm}
      data-testid={TEST_HOOKS.EDITVEHICLEMILEAGESFORM}
    >
     {(editVehicleMileages.apiError != null && editVehicleMileages.apiError.status > 499) && (
      <ApiErrorMessage className={s.ApiError} apiError={editVehicleMileages.apiError} />
     )}
     {/* eslint-disable-next-line @typescript-eslint/no-misused-promises */}
      <form ref={ref} onSubmit={handleSubmit(onSubmit)}>
        {fields.map((field, idx) => (
          <Grid container spacing={2} key={field.id}>
            <Grid xs={10} md={5}>
              <Controller
                control={control}
                name={`mileages.${idx}.date`}
                render={({ field }) => (
                  <TextField
                    required
                    fullWidth
                    type="date"
                    label="Data"
                    InputProps={field}
                    InputLabelProps={{ shrink: true }}
                    error={Boolean(getPartError(idx)?.date)}
                    helperText={getPartError(idx)?.date?.message ?? ' '}
                  />
                )}
              />
            </Grid>
            <Grid xs={10} md={6}>
              <Controller
                control={control}
                name={`mileages.${idx}.mileage`}
                render={({ field }) => (
                  <TextField
                    required
                    fullWidth
                    type="number"
                    label="Przebieg"
                    InputProps={{
                      ...field,
                      endAdornment: (
                        <InputAdornment position="end">
                          km
                        </InputAdornment>
                      )
                    }}
                    error={Boolean(getPartError(idx)?.mileage)}
                    helperText={getPartError(idx)?.mileage?.message ?? ' '}
                  />
                )}
              />
            </Grid>
            <Grid xs={2} md={1} className={s.RemoveFieldButton}>
              <IconButton color="primary" size="large" onClick={() => remove(idx)}>
                <RemoveOutlined />
              </IconButton>
            </Grid>
          </Grid>
        ))}
      </form>
      <Box className={s.AddFieldButton}>
        <Button
          color="primary"
          onClick={() => append({ date: '', mileage: 0 })}
          startIcon={<AddOutlined />}
        >
          Dodaj przebieg
        </Button>
      </Box>
    </DialogForm>
  )
}

const TEST_HOOKS = {
  EDITVEHICLEMILEAGESFORM: 'editVehicleMileagesForm'
}

interface Props {
  onPostSave?: () => any
}

const withObserver = observer(EditVehicleMileagesForm)

export default withObserver

export {
  withObserver as EditVehicleMileagesForm,
  TEST_HOOKS,
  type Props,
  type EditVehicleMileagesFormSchema
}
