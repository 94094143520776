/* istanbul ignore file */
/* eslint-disable sort-imports, import/order */
import { StrictMode } from 'react'
import ReactDOM from 'react-dom/client'

import { BrowserRouter } from 'react-router-dom'

import { configureApp } from 'config'

import App from 'App'

import './scss/global.scss'

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
)

configureApp()
  .then(() => root.render(
    <StrictMode>
      <BrowserRouter>
        <App />
      </BrowserRouter>
    </StrictMode>
  ))
  .catch(() => root.render(
    <StrictMode>
      <>
        Error...
      </>
    </StrictMode>
  ))
