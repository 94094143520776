import { FC } from 'react'

import { Box, CircularProgress, LinearProgress } from '@mui/material'
import clsx from 'clsx'
import { observer } from 'mobx-react-lite'

import s from './PageLoader.module.scss'

const PageLoader: FC<Props> = ({ variant = 'circular' }) => {
  const Component = variant === 'circular' ? CircularProgress : LinearProgress
  return (
    <Box className={s.PageLoader} data-testid={TEST_HOOKS.PAGELOADER}>
      <Component size={48} className={clsx(variant === 'linear' && s.LinearProgress)} />
    </Box>
  )
}

const TEST_HOOKS = {
  PAGELOADER: 'pageLoader'
}

interface Props {
  variant?: 'circular' | 'linear'
}

const withObserver = observer(PageLoader)

export default withObserver

export {
  withObserver as PageLoader,
  TEST_HOOKS,
  type Props
}
