import sumBy from 'lodash/sumBy'
import { makeAutoObservable, runInAction } from 'mobx'

import { base64ToBlob, parseStrapiError } from 'shared/utils'

import { ServicesService } from 'services'

import { PartData, ServiceData, ServiceTemplate } from 'services/types'

import ApiErrorStore from '../ApiErrorStore'
import LoadingStore from '../LoadingStore'
import type RootStore from '../RootStore'

class ServiceStore {
  rootStore: RootStore

  service?: ServiceData
  otherServices: ServiceData[] = []
  loading = new LoadingStore()
  apiError?: ApiErrorStore

  constructor (rootStore: RootStore) {
    makeAutoObservable(this)
    this.rootStore = rootStore
  }

  get totalPartsCost (): number {
    if (this.service?.attributes.parts == null || this.service?.attributes?.parts?.length === 0) return 0
    return sumBy(this.service.attributes.parts, (part: PartData) => parseFloat(part.price))
  }

  public reset (): void {
    this.service = undefined
    this.loading.reset()
    this.apiError = undefined
  }

  public async fetch (id: number): Promise<void> {
    this.loading.startLoading()
    const service = new ServicesService(this.rootStore.token)
    try {
      const response = await service.findOne(id)
      runInAction(() => {
        this.service = response.data.data
      })
    } catch (e) {
      runInAction(() => { this.apiError = new ApiErrorStore(parseStrapiError(e)) })
    } finally {
      this.loading.finishLoading()
    }
  }

  public async removeService (id: number): Promise<boolean> {
    if (this.service == null) return false

    this.loading.startUpdating()
    const service = new ServicesService(this.rootStore.token)

    try {
      await service.remove(id)
      return true
    } catch (e) {
      runInAction(() => { this.apiError = new ApiErrorStore(parseStrapiError(e)) })
      return false
    } finally {
      this.loading.finishUpdating()
    }
  }

  public async fetchOtherServices (): Promise<void> {
    if (this.service == null || this.service.attributes.vehicle?.data?.id == null) return
    const service = new ServicesService(this.rootStore.token)
    const response = await service.find({
      pagination: {
        page: 1,
        pageSize: 100
      },
      disableAutoPopulate: true,
      // @ts-expect-error
      'filters[id][$ne]': this.service.id,
      'filters[vehicle]': this.service.attributes.vehicle.data.id
    })
    runInAction(() => { this.otherServices = response.data.data })
  }

  public async getPdf (id: number, template: ServiceTemplate): Promise<Blob> {
    const service = new ServicesService(this.rootStore.token)
    const response = await service.generatePdf(id, template)
    return base64ToBlob(response.data, { type: 'application/pdf' })
  }
}

export default ServiceStore
