import dayjs from 'dayjs'
import { makeAutoObservable, runInAction } from 'mobx'

import { parseStrapiError } from 'shared/utils'

import { ServicesService } from 'services'

import { IncomeData, Pagination, ServiceData } from 'services/types'

import ApiErrorStore from '../ApiErrorStore'
import LoadingStore from '../LoadingStore'
import PaginationStore from '../PaginationStore'
import type RootStore from '../RootStore'

const sharedParams = {
  disableAutoPopulate: true,
  populate: ['vehicle', 'vehicle.client'],
  sort: 'id:desc'
}

class ServicesStore {
  rootStore: RootStore

  searchQuery?: string
  services: ServiceData[] = []
  pagination = new PaginationStore()
  loading = new LoadingStore()
  apiError?: ApiErrorStore

  weeklyIncome?: IncomeData
  monthlyIncome?: IncomeData

  constructor (rootStore: RootStore) {
    makeAutoObservable(this)
    this.rootStore = rootStore
  }

  public reset (): void {
    this.services = []
    this.pagination.reset()
    this.loading.reset()
    this.searchQuery = undefined
    this.apiError = undefined
  }

  public async changePage (page: number, updatePagination: boolean = false): Promise<void> {
    this.loading.startUpdating()
    this.pagination.setPage(page)
    this.apiError = undefined
    const service = new ServicesService(this.rootStore.token)

    try {
      const response = await service.find({ ...sharedParams, pagination: this.pagination.queryParams })
      runInAction(() => {
        this.services = response.data.data
        if (updatePagination) this.pagination.update(response.data.meta.pagination as Pagination)
      })
    } finally {
      this.loading.finishUpdating()
    }
  }

  public async fetch (): Promise<void> {
    if (this.loading.isLoading) return
    this.apiError = undefined
    this.searchQuery = undefined
    this.loading.startLoading()
    const service = new ServicesService(this.rootStore.token)

    try {
      const response = await service.find(sharedParams)
      runInAction(() => {
        this.services = response.data.data
        this.pagination.update(response.data.meta.pagination as Pagination)
      })
    } catch (e) {
      runInAction(() => { this.apiError = new ApiErrorStore(parseStrapiError(e)) })
    } finally {
      this.loading.finishLoading()
    }
  }

  public async fetchOpen (): Promise<void> {
    if (this.loading.isLoading) return

    this.apiError = undefined
    this.loading.startLoading()
    const service = new ServicesService(this.rootStore.token)

    try {
      const response = await service.find({
        ...sharedParams,
        pagination: {
          page: 1,
          pageSize: 5
        },
        filters: {
          status: {
            $ne: 'received'
          }
        }
      })
      runInAction(() => {
        this.services = response.data.data
        this.pagination.update(response.data.meta.pagination as Pagination)
      })
    } catch (e) {
      runInAction(() => { this.apiError = new ApiErrorStore(parseStrapiError(e)) })
    } finally {
      this.loading.finishLoading()
    }
  }

  public async search (query: string): Promise<void> {
    if ((this.searchQuery === undefined && query === '') || query === this.searchQuery) return

    this.apiError = undefined

    if (query == null || query === '') {
      this.searchQuery = undefined
      await this.changePage(1, true)
      return
    }

    this.loading.startUpdating()
    this.searchQuery = query
    this.pagination.reset()

    try {
      const service = new ServicesService(this.rootStore.token)
      const response = await service.search(query, 50, { params: sharedParams })
      runInAction(() => {
        this.services = response.data.data
        this.pagination.update(response.data.meta.pagination as Pagination)
      })
    } catch (e) {
      runInAction(() => { this.apiError = new ApiErrorStore(parseStrapiError(e)) })
    } finally {
      this.loading.finishUpdating()
    }
  }

  public async getWeeklyIncome (): Promise<void> {
    const service = new ServicesService(this.rootStore.token)
    try {
      const response = await service.getIncome(dayjs().subtract(7, 'days').toISOString())
      runInAction(() => { this.weeklyIncome = response.data })
    } catch (e) {
      console.info(e)
    }
  }

  public async getMonthlyIncome (): Promise<void> {
    const service = new ServicesService(this.rootStore.token)
    try {
      const response = await service.getIncome(dayjs().subtract(1, 'months').toISOString())
      runInAction(() => { this.monthlyIncome = response.data })
    } catch (e) {
      console.info(e)
    }
  }
}

export default ServicesStore
