import { FC, useCallback, useEffect } from 'react'

import { yupResolver } from '@hookform/resolvers/yup'
import { RefreshOutlined } from '@mui/icons-material'
import {
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  Grow,
  Skeleton,
  Stack,
  TextField
} from '@mui/material'
import { observer } from 'mobx-react-lite'
import { useSnackbar } from 'notistack'
import { Controller, SubmitHandler, useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import * as yup from 'yup'
import { InferType } from 'yup'

import { useStores } from 'stores/hooks'

import { ApiErrorMessage, Page, SecretTextField } from 'components'

import s from './SettingsPage.module.scss'

const SettingsPage: FC = () => {
  const { t } = useTranslation()
  const schema = yup.object({
    appName: yup.string()
      .min(2, t('settingsPage.app.appName.errors.min', { charCount: 2 }))
      .max(10, t('settingsPage.app.appName.errors.max', { charCount: 10 }))
      .required(t('settingsPage.app.appName.errors.required')),
    smsApiToken: yup.string().required(t('settingsPage.app.smsApiToken.errors.required'))
  })

  type ISettingsForm = InferType<typeof schema>
  const { enqueueSnackbar } = useSnackbar()
  const { settings } = useStores()
  const getDefaultValues = useCallback(() => ({
    appName: settings.settings?.attributes?.appName ?? '',
    smsApiToken: settings.settings?.attributes?.smsApiToken ?? ''
  }), [settings.settings])
  const { control, handleSubmit, formState, reset } = useForm<ISettingsForm>({
    mode: 'all',
    resolver: yupResolver(schema),
    defaultValues: getDefaultValues()
  })

  useEffect(() => {
    void settings.get().then(() => reset(getDefaultValues()))
    return () => settings.reset()
  }, [])

  const onSubmit: SubmitHandler<ISettingsForm> = async (data): Promise<void> => {
    try {
      await settings.update(data)
      reset(getDefaultValues())
      enqueueSnackbar(t('settingsPage.submit.success'), { variant: 'success' })
    } catch (e) {
      enqueueSnackbar(t('settingsPage.submit.error'), { variant: 'error' })
    }
  }

  const onSetSearchableAttributes = async (): Promise<void> => {
    try {
      await settings.setSearchableAttributes()
      enqueueSnackbar(t('settingsPage.searchableAttributes.success'), { variant: 'success' })
    } catch (e) {
      enqueueSnackbar(t('settingsPage.searchableAttributes.error'), { variant: 'error' })
    }
  }

  const form = (
    <>
      <Controller
        control={control}
        name="appName"
        render={({ field }) => (
          <TextField
            required
            fullWidth
            InputProps={field}
            label={t('settingsPage.app.appName.label')}
            error={Boolean(formState.errors.appName)}
            helperText={formState.errors.appName?.message}
          />
        )}
      />
      <Controller
        control={control}
        name="smsApiToken"
        render={({ field }) => (
          <SecretTextField
            autoComplete="off"
            required
            fullWidth
            InputProps={field}
            label={t('settingsPage.app.smsApiToken.label')}
            error={Boolean(formState.errors.smsApiToken)}
            helperText={formState.errors.smsApiToken?.message}
          />
        )}
      />
    </>
  )

  const skeleton = (
    <>
      <Skeleton variant="rectangular" height={50} />
      <Skeleton variant="rectangular" height={50} />
    </>
  )

  return (
    <Page
      narrow
      title={t('settingsPage.title')}
      description={t('settingsPage.description')}
      className={s.SettingsPage}
      data-testid={TEST_HOOKS.SETTINGSPAGE}
    >
      <Grow in>
        {/* eslint-disable-next-line @typescript-eslint/no-misused-promises */}
        <Card component="form" onSubmit={handleSubmit(onSubmit)}>
          <CardHeader title={t('settingsPage.app.title')} />
          <CardContent>
            <Stack spacing={4}>
              <ApiErrorMessage apiError={settings.apiError} />
              {settings.loading.isLoaded ? form : skeleton}
            </Stack>
          </CardContent>
          <CardActions>
            <Button
              disabled={!formState.isDirty}
              variant="outlined"
              color="primary"
              onClick={() => reset(getDefaultValues())}
            >
              {t('form.reset')}
            </Button>
            <Button
              disabled={!formState.isDirty || !formState.isValid}
              variant="contained"
              color="primary"
              type="submit"
            >
              {t('form.submit')}
            </Button>
          </CardActions>
        </Card>
      </Grow>
      <Grow in>
        <Card sx={{ marginTop: 4 }}>
          <CardHeader
            title={t('settingsPage.administration.title')}
            subheader={t('settingsPage.administration.subheader')}
          />
          <CardContent>
            <Button
              color="primary"
              variant="contained"
              startIcon={<RefreshOutlined />}
              size="large"
              /* eslint-disable-next-line @typescript-eslint/no-misused-promises */
              onClick={onSetSearchableAttributes}
            >
              {t('settingsPage.searchableAttributes.button.label')}
            </Button>
          </CardContent>
        </Card>
      </Grow>
    </Page>
  )
}

const TEST_HOOKS = {
  SETTINGSPAGE: 'settingsPage'
}

const withObserver = observer(SettingsPage)

export default withObserver

export {
  withObserver as SettingsPage,
  TEST_HOOKS
}
