import Cookies from 'js-cookie'

import { JWT_COOKIE_NAME } from 'shared/constants'

import AccountStore from '../AccountStore'
import AuthStore from '../AuthStore'
import ClientsStore from '../ClientsStore'
import ClientStore from '../ClientStore'
import EditClientStore from '../EditClientStore'
import EditServiceActionsStore from '../EditServiceActionsStore'
import EditServicePartsStore from '../EditServicePartsStore'
import EditServiceStore from '../EditServiceStore'
import EditVehicleMileagesStore from '../EditVehicleMileagesStore/EditVehicleMileagesStore'
import EditVehiclePartsStore from '../EditVehiclePartsStore'
import EditVehicleStore from '../EditVehicleStore'
import ServicesStore from '../ServicesStore'
import ServiceStore from '../ServiceStore'
import SettingsStore from '../SettingsStore'
import SmsApiStore from '../SmsApiStore'
import VehiclesStore from '../VehiclesStore'
import VehicleStore from '../VehicleStore'

class RootStore {
  token?: string = Cookies.get(JWT_COOKIE_NAME)

  auth: AuthStore
  account: AccountStore
  clients: ClientsStore
  client: ClientStore
  vehicles: VehiclesStore
  services: ServicesStore
  service: ServiceStore
  smsApi: SmsApiStore
  settings: SettingsStore
  vehicle: VehicleStore

  editClient: EditClientStore
  editVehicle: EditVehicleStore
  editVehicleParts: EditVehiclePartsStore
  editVehicleMileages: EditVehicleMileagesStore
  editService: EditServiceStore
  editServiceParts: EditServicePartsStore
  editServiceActions: EditServiceActionsStore

  constructor () {
    this.auth = new AuthStore(this)
    this.account = new AccountStore(this)
    this.clients = new ClientsStore(this)
    this.client = new ClientStore(this)
    this.vehicles = new VehiclesStore(this)
    this.services = new ServicesStore(this)
    this.smsApi = new SmsApiStore(this)
    this.settings = new SettingsStore(this)
    this.vehicle = new VehicleStore(this)
    this.service = new ServiceStore(this)

    this.editClient = new EditClientStore(this)
    this.editVehicle = new EditVehicleStore(this)
    this.editVehicleParts = new EditVehiclePartsStore(this)
    this.editVehicleMileages = new EditVehicleMileagesStore(this)
    this.editService = new EditServiceStore(this)
    this.editServiceParts = new EditServicePartsStore(this)
    this.editServiceActions = new EditServiceActionsStore(this)
  }
}

export default RootStore
