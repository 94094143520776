import { FC, useEffect, useRef } from 'react'

import { yupResolver } from '@hookform/resolvers/yup'
import { AddOutlined, RemoveOutlined } from '@mui/icons-material'
import { Box, Button, Divider, IconButton, InputAdornment, TextField, useMediaQuery, useTheme } from '@mui/material'
import Grid from '@mui/material/Unstable_Grid2'
import { observer } from 'mobx-react-lite'
import { useSnackbar } from 'notistack'
import { Controller, SubmitHandler, useFieldArray, useForm } from 'react-hook-form'
import * as yup from 'yup'
import { InferType } from 'yup'

import { emptyStringToNull } from 'shared/utils'

import { ServicePartsUpdateData } from 'services/types'

import { useStores } from 'stores/hooks'

import { ApiErrorMessage, DialogForm } from 'components'

import s from './EditServicePartsForm.module.scss'

const schema = yup.object({
  parts: yup.array().of(yup.object({
    name: yup.string().required('Nazwa części jest wymagana'),
    serialNumber: yup.string().nullable(),
    quantity: yup.number().min(1).required('Liczba części jest wymagana').transform(emptyStringToNull).nullable(),
    price: yup.number().min(0).required('Cena jest wymagana').transform(emptyStringToNull).nullable()
  }))
})

type EditServicePartsFormSchema = InferType<typeof schema>

const EditServicePartsForm: FC<Props> = ({ onPostSave }) => {
  const ref = useRef(null)
  const { enqueueSnackbar } = useSnackbar()
  const { editServiceParts } = useStores()
  const { control, formState, watch, handleSubmit } = useForm<EditServicePartsFormSchema>({
    mode: 'all',
    resolver: yupResolver(schema)
  })
  const { fields, append, remove } = useFieldArray({ name: 'parts', control })
  const theme = useTheme()
  const mdUp = useMediaQuery(theme.breakpoints.up('md'))

  const partsLength = watch('parts')?.length ?? 0

  useEffect(() => {
    remove()
    editServiceParts.parts?.forEach(
      ({ name, quantity, serialNumber, price }) => {
        append({ name, quantity, serialNumber: serialNumber ?? '', price: parseInt(price) })
      }
    )
  }, [editServiceParts.parts])

  const onSubmit: SubmitHandler<EditServicePartsFormSchema> = async (data) => {
    const response = await editServiceParts.save(data as ServicePartsUpdateData)
    if (response != null) {
      enqueueSnackbar('Zapisano części', { variant: 'success' })
      if (onPostSave != null) onPostSave()
      editServiceParts.reset()
      return
    }
    enqueueSnackbar('Błąd podczas zapisywania części', { variant: 'error' })
  }

  const getPartError = (idx: number): Record<string, any> | undefined => {
    if (formState.errors.parts == null) return
    return formState.errors.parts[idx]
  }

  return (
    <DialogForm
      open={editServiceParts.isOpen}
      title="Edytuj części"
      formRef={ref}
      isValid={formState.isValid && formState.isDirty && partsLength > 0}
      isUpdating={editServiceParts.loading.isUpdating}
      maxWidth="lg"
      onCancel={() => editServiceParts.reset()}
      className={s.EditVehicleMileagesForm}
      data-testid={TEST_HOOKS.EDITSERVICEPARTSFORM}
    >
      {(editServiceParts.apiError != null && editServiceParts.apiError.status > 499) && (
        <ApiErrorMessage className={s.ApiError} apiError={editServiceParts.apiError} />
      )}
      {/* eslint-disable-next-line @typescript-eslint/no-misused-promises */}
      <form ref={ref} onSubmit={handleSubmit(onSubmit)}>
        {fields.map((field, idx) => (
          <Grid container spacing={1} key={field.id}>
            {(!mdUp && idx > 0) && (
              <Grid xs={12}>
                <Divider sx={{ marginTop: 2, marginBottom: 4 }} />
              </Grid>
            )}
            <Grid xs={12} sm={6} md>
              <Controller
                control={control}
                name={`parts.${idx}.name`}
                render={({ field }) => (
                  <TextField
                    required
                    fullWidth
                    label="Nazwa"
                    InputProps={field}
                    InputLabelProps={{ shrink: true }}
                    error={Boolean(getPartError(idx)?.name)}
                    helperText={getPartError(idx)?.name?.message ?? ' '}
                  />
                )}
              />
            </Grid>
            <Grid xs={12} sm={6} md={3}>
              <Controller
                control={control}
                name={`parts.${idx}.serialNumber`}
                render={({ field }) => (
                  <TextField
                    fullWidth
                    label="Numer seryjny"
                    InputProps={field}
                    InputLabelProps={{ shrink: true }}
                    error={Boolean(getPartError(idx)?.serialNumber)}
                    helperText={getPartError(idx)?.serialNumber?.message ?? ' '}
                  />
                )}
              />
            </Grid>
            <Grid xs={10} sm={5} md={1}>
              <Controller
                control={control}
                name={`parts.${idx}.quantity`}
                render={({ field }) => (
                  <TextField
                    required
                    fullWidth
                    type="number"
                    label="Liczba"
                    InputProps={field}
                    inputProps={{
                      min: 1
                    }}
                    error={Boolean(getPartError(idx)?.quantity)}
                    helperText={getPartError(idx)?.quantity?.message ?? ' '}
                  />
                )}
              />
            </Grid>
            <Grid xs={12} sm={6} md={3}>
              <Controller
                control={control}
                name={`parts.${idx}.price`}
                render={({ field }) => (
                  <TextField
                    required
                    fullWidth
                    type="number"
                    label="Cena"
                    InputProps={{
                      ...field,
                      endAdornment: (
                        <InputAdornment position="end">
                          PLN
                        </InputAdornment>
                      )
                    }}
                    inputProps={{
                      min: 0
                    }}
                    error={Boolean(getPartError(idx)?.price)}
                    helperText={getPartError(idx)?.price?.message ?? ' '}
                  />
                )}
              />
            </Grid>
            <Grid xs={2} sm={1} md="auto" className={s.RemoveFieldButton}>
              <IconButton color="primary" size="large" onClick={() => remove(idx)}>
                <RemoveOutlined />
              </IconButton>
            </Grid>
          </Grid>
        ))}
      </form>
      <Box className={s.AddFieldButton}>
        <Button
          color="primary"
          onClick={() => append({ name: '', quantity: 1, serialNumber: '', price: 0 })}
          startIcon={<AddOutlined />}
        >
          Dodaj część
        </Button>
      </Box>
    </DialogForm>
  )
}

const TEST_HOOKS = {
  EDITSERVICEPARTSFORM: 'editServicePartsForm'
}

interface Props {
  onPostSave?: () => any
}

const withObserver = observer(EditServicePartsForm)

export default withObserver

export {
  withObserver as EditServicePartsForm,
  TEST_HOOKS,
  type Props,
  type EditServicePartsFormSchema
}
