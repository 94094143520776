import { FC, MouseEventHandler } from 'react'

import { Drawer, Toolbar, useTheme, useMediaQuery } from '@mui/material'
import { observer } from 'mobx-react-lite'

import { MainMenu } from 'components'

import s from './Sidebar.module.scss'

const Sidebar: FC<Props> = ({ open = false, onClose }) => {
  const theme = useTheme()
  const mdUp = useMediaQuery(theme.breakpoints.up('md'))

  return (
    <Drawer
      className={s.Sidebar}
      data-testid={TEST_HOOKS.SIDEBAR}
      variant={mdUp ? 'permanent' : 'temporary'}
      open={open}
      onClose={onClose}
    >
      <Toolbar />
      <MainMenu className={s.MainMenu} />
    </Drawer>
  )
}

const TEST_HOOKS = {
  SIDEBAR: 'sidebar'
}

interface Props {
  open: boolean
  onClose: MouseEventHandler
}

const withObserver = observer(Sidebar)

export default withObserver

export {
  withObserver as Sidebar,
  TEST_HOOKS,
  type Props
}
