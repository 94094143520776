import { FC } from 'react'

import { Alert, AlertTitle, Grow } from '@mui/material'
import clsx from 'clsx'
import { observer } from 'mobx-react-lite'
import { useTranslation } from 'react-i18next'

import { StrapiError } from 'services/types'

import s from './ApiErrorMessage.module.scss'

const ApiErrorMessage: FC<Props> = ({ apiError, className }) => {
  const { t } = useTranslation()

  if (apiError == null) return null

  return (
    <Grow in>
      <Alert className={clsx(s.ApiErrorMessage, className)} data-testid={TEST_HOOKS.APIERRORMESSAGE} severity="error">
        <AlertTitle>{apiError.status} {t(apiError.name)}</AlertTitle>
        {t(apiError.message)}
      </Alert>
    </Grow>
  )
}

const TEST_HOOKS = {
  APIERRORMESSAGE: 'apiErrorMessage'
}

interface Props {
  apiError?: StrapiError
  className?: string
}

const withObserver = observer(ApiErrorMessage)

export default withObserver

export {
  withObserver as ApiErrorMessage,
  TEST_HOOKS,
  type Props
}
