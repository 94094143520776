import { FC, useEffect, useState } from 'react'

import { yupResolver } from '@hookform/resolvers/yup'
import {
  ChevronRightOutlined, LockOutlined,
  PasswordOutlined,
  PersonOutline
} from '@mui/icons-material'
import {
  Alert, AlertTitle,
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  Grow,
  Hidden,
  InputAdornment,
  Stack,
  TextField
} from '@mui/material'
import { Controller, SubmitHandler, useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router'
import * as yup from 'yup'

import { useStores } from 'stores/hooks'

import { PublicAppBar, SecretTextField } from 'components'

import s from './LogInPage.module.scss'

interface LoginForm {
  username: string
  password: string
}

const schema = yup.object({
  username: yup.string().email('login.username.error.type').required('login.username.error.required'),
  password: yup.string().required('login.password.error.required')
})

const LogInPage: FC<Props> = () => {
  const { auth } = useStores()
  const navigate = useNavigate()
  const { t } = useTranslation()
  const [hideCard, setHideCard] = useState<boolean>(false)
  const { control, handleSubmit, formState } = useForm<LoginForm>({
    mode: 'all',
    resolver: yupResolver(schema),
    defaultValues: {
      username: '',
      password: ''
    }
  })

  useEffect(() => {
    const tokenFromCookie = auth.setTokenFromCookie()
    if (!tokenFromCookie) return

    void auth.isAuthorized()
      .then(isAuthorized => {
        if (isAuthorized) void auth.fetchUser().then(() => navigate('/app'))
      })
  }, [])

  const onSubmit: SubmitHandler<LoginForm> = async (data): Promise<void> => {
    const authorized = await auth.authorize(data.username, data.password)
    if (authorized) {
      setHideCard(true)
      setTimeout(() => navigate('/app'), 1000)
    }
  }

  return (
    <>
      <PublicAppBar variant="inverted" />
      <Box className={s.LogInPage}>
        {Boolean(auth.error) && (
          <Alert className={s.Alert} variant="filled" severity="error">
            <AlertTitle>{t(auth.error?.name ?? 'generic.error')}</AlertTitle>
            {t(auth.error?.message ?? 'generic.error.login')}
          </Alert>
        )}
        <Grow in={!hideCard} timeout={800}>
          {/* eslint-disable-next-line @typescript-eslint/no-misused-promises */}
          <Card className={s.Card} component="form" onSubmit={handleSubmit(onSubmit)}>
            <CardHeader title={t('login.card.title')} subheader={t('login.card.description')} />
            <CardContent>
              <Stack spacing={4}>
                <Controller
                  name="username"
                  control={control}
                  render={({ field }) => (
                    <TextField
                      label={t('login.username.label')}
                      placeholder="johndoe@example.com"
                      fullWidth
                      error={Boolean(formState.errors?.username)}
                      helperText={t(formState.errors?.username?.message ?? '')}
                      InputProps={{
                        ...field,
                        startAdornment: (
                          <InputAdornment position="start">
                            <PersonOutline />
                          </InputAdornment>
                        )
                      }}
                    />
                  )}
                />
                <Controller
                  name="password"
                  control={control}
                  render={({ field }) => (
                    <SecretTextField
                      label={t('login.password.label')}
                      error={Boolean(formState.errors?.password)}
                      helperText={t(formState.errors?.password?.message ?? '')}
                      fullWidth
                      InputProps={{
                        ...field,
                        startAdornment: (
                          <InputAdornment position="start">
                            <PasswordOutlined />
                          </InputAdornment>
                        )
                      }}
                    />
                  )}
                />
                <Hidden xsUp>
                  <Grow in={false}>
                    <TextField
                      label={t('login.code.label')}
                      placeholder="1234"
                      helperText={t('login.code.helper')}
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                            <LockOutlined />
                          </InputAdornment>
                        )
                      }}
                    />
                  </Grow>
                </Hidden>
              </Stack>
            </CardContent>
            <CardActions>
              <Button
                disabled={!formState.isDirty || !formState.isValid}
                size="large"
                color="primary"
                variant="contained"
                endIcon={<ChevronRightOutlined />}
                type="submit"
              >
                {t('login.action.label')}
              </Button>
            </CardActions>
          </Card>
        </Grow>
      </Box>
    </>
  )
}

const TEST_HOOKS = {
  LOGINPAGE: 'logInPage'
}

// FIXME: Either add props or remove the interface!
// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface Props {

}

export default LogInPage

export {
  LogInPage,
  TEST_HOOKS,
  type Props
}
