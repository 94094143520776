import { makeAutoObservable } from 'mobx'

import { Pagination } from 'services/types'

class PaginationStore {
  page: number = 1
  pageSize: number = 25
  pageCount: number = 1
  total: number = 0

  constructor () {
    makeAutoObservable(this)
  }

  /**
   * Return current pagination settings as query prams object, ready to be consumed by the API.
   */
  get queryParams (): { page: number, pageSize: number } {
    return {
      page: this.page,
      pageSize: this.pageSize
    }
  }

  public reset (): void {
    this.page = 1
    this.pageSize = 25
    this.pageCount = 1
    this.total = 0
  }

  public setPage (page: number): void {
    this.page = page
  }

  public previous (): void {
    if (this.page > 1) this.page -= 1
  }

  public next (): void {
    if (this.page < this.pageCount) this.page += 1
  }

  public update (data: Pagination): void {
    Object.assign(this, data)
  }
}

export default PaginationStore
