import { FC } from 'react'

import { Box, Link, Typography } from '@mui/material'
import { appMeta } from 'config'
import { observer } from 'mobx-react-lite'
import { useTranslation } from 'react-i18next'

import s from './Footer.module.scss'

const Footer: FC<Props> = () => {
  const { t } = useTranslation()

  return (
    <Box className={s.Footer} data-testid={TEST_HOOKS.FOOTER}>
      <Typography className={s.Copyright}>
        {new Date().getFullYear()} &copy; {appMeta.name} v{appMeta.version}
      </Typography>
      <Typography className={s.Copyright}>
        {t('app.madeBy', { creator: <Link key="creatorLink" href="http://instytutfi.pl/">Instytut Fi</Link> })}
      </Typography>
    </Box>
  )
}

const TEST_HOOKS = {
  FOOTER: 'footer'
}

// FIXME: Either add props or remove the interface!
// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface Props {

}

const withObserver = observer(Footer)

export default withObserver

export {
  withObserver as Footer,
  TEST_HOOKS,
  type Props
}
