import { makeAutoObservable, runInAction } from 'mobx'

import { parseStrapiError } from 'shared/utils'

import { VehiclesService } from 'services'

import { ListItemData, VehicleData, VehiclePartsUpdateData } from 'services/types'

import ApiErrorStore from '../ApiErrorStore'
import LoadingStore from '../LoadingStore'
import type RootStore from '../RootStore'

class EditVehiclePartsStore {
  rootStore: RootStore

  isOpen: boolean = false

  vehicleId?: number
  parts?: ListItemData[]
  loading = new LoadingStore()
  apiError?: ApiErrorStore

  constructor (rootStore: RootStore) {
    makeAutoObservable(this)
    this.rootStore = rootStore
  }

  public reset (): void {
    this.isOpen = false
    this.loading.reset()
    this.parts = undefined
    this.apiError = undefined
  }

  public startEdit (vehicleId: number, parts?: ListItemData[]): void {
    this.vehicleId = vehicleId
    if (parts != null) this.parts = parts
    this.isOpen = true
  }

  public async save (data: VehiclePartsUpdateData): Promise<VehicleData | undefined> {
    if (this.vehicleId == null) return
    this.loading.startUpdating()
    const service = new VehiclesService(this.rootStore.token)
    try {
      const response = await service.saveParts(this.vehicleId, data)
      runInAction(() => { this.parts = response.data.data.attributes.parts })
      return response.data.data
    } catch (e) {
      runInAction(() => { this.apiError = new ApiErrorStore(parseStrapiError(e)) })
    } finally {
      this.loading.finishUpdating()
    }
  }
}

export default EditVehiclePartsStore
