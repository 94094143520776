import { FC } from 'react'

import {
  CheckOutlined,
  FiberManualRecordOutlined,
  HandymanOutlined,
  NoCrashOutlined,
  SvgIconComponent
} from '@mui/icons-material'
import { Chip } from '@mui/material'
import clsx from 'clsx'
import { observer } from 'mobx-react-lite'
import { useTranslation } from 'react-i18next'

import { type ServiceStatus } from 'services/types'

import s from './ServiceStatusBadge.module.scss'

const Icons: Map<ServiceStatus, SvgIconComponent> = new Map([
  ['new', FiberManualRecordOutlined],
  ['progress', HandymanOutlined],
  ['finished', NoCrashOutlined],
  ['received', CheckOutlined]
])

const ServiceStatusBadge: FC<Props> = ({ status }) => {
  const { t } = useTranslation()
  // @ts-expect-error
  const Icon: SvgIconComponent = Icons.has(status) ? Icons.get(status) : FiberManualRecordOutlined

  return (
    <Chip
      className={clsx(s.ServiceStatusBadge, s[status])}
      data-testid={TEST_HOOKS.SERVICESTATUSBADGE}
      label={t(`service.status.${String(status)}`)}
      size="small"
      variant="outlined"
      icon={<Icon className={s.Icon} />}
    />
  )
}

const TEST_HOOKS = {
  SERVICESTATUSBADGE: 'serviceStatusBadge'
}

interface Props {
  status: ServiceStatus
}

const withObserver = observer(ServiceStatusBadge)

export default withObserver

export {
  withObserver as ServiceStatusBadge,
  TEST_HOOKS,
  type Props
}
