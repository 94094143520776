import { RefObject, useEffect } from 'react'

import axios, { AxiosError } from 'axios'
import { UseFormSetError } from 'react-hook-form/dist/types/form'
import { FieldPath } from 'react-hook-form/dist/types/path'
import { type Location } from 'react-router-dom'

import { ErrorDetail, StrapiError, StrapiErrorResponse } from 'services/types'

/**
 * Check if the current URL is an active one, by the location pathname.
 * Note that it will return true for sub-routes, i.e. url `/services` is active for pathname `/services/32`.
 * @param {Location}  location  Location, best provided by the `useLocation` hook from `react-router` library.
 * @param {string}    url       URL to check against, whether it's active or not.
 */
const checkIfUrlIsActive = (location: Location, url: string): boolean => {
  if (['/', '/app'].includes(url)) return location.pathname === url
  return location.pathname.startsWith(url)
}

const getMediaAbsolutePath = (path: string): string => {
  return `${process.env.REACT_APP_STRAPI_URL ?? 'https://strapi.motocar.pro'}${path}`
}

const getStrapiBaseUrl = (): string => {
  return process.env.REACT_APP_STRAPI_URL ?? 'https://strapi.motocar.pro'
}

const getApiBaseUrl = (): string => {
  return getStrapiBaseUrl() + '/api'
}

function isAxiosError (error: unknown): error is AxiosError<StrapiErrorResponse> {
  return axios.isAxiosError(error)
}

/**
 * Tries to parse an identifier as a number.
 * @param id
 */
const parseIdFromParams = (id: any): number => {
  return parseInt(id, 10)
}

const parseStrapiError = (e: any): StrapiError => {
  const error = e as AxiosError<StrapiErrorResponse>
  return error.response?.data.error as StrapiError
}

/**
 * Submit form represented by a ref
 * @param {object} formRef a React ref to the form element
 */
const submitForm = (formRef: RefObject<HTMLFormElement>): void => {
  if (formRef?.current != null) {
    formRef.current.dispatchEvent(new Event('submit', { cancelable: true, bubbles: true }))
  }
}

/**
 * Custom hook that parses Strapi remote API errors and sets them as React Hook Form field errors.
 * TODO: Handle non field errors, and return them — so they can be displayed separately.
 */
function useRemoteErrors<T> (setError: UseFormSetError<T>, errors?: ErrorDetail[]): void {
  const errorsJson = JSON.stringify(errors ?? {})

  useEffect(() => {
    errors?.forEach(error => {
      const path = error.path.join('.') as FieldPath<T>
      setError(path, { type: 'remote', message: error.message })
    })
  }, [errorsJson, setError])
}

/**
 * Yup helper to handle empty number-type fields as valid (otherwise Yup complains that an empty string
 * cannot be parsed to number).
 * @param value
 * @param originalValue
 */
const emptyStringToNull = (value: any, originalValue: any): any => {
  if (typeof originalValue === 'string' && originalValue === '') return null
  return value
}

/**
 * Nifty little helper to download a Blob as a file.
 */
const downloadFile = (blob: Blob, name: string): void => {
  const link = document.createElement('a')
  link.href = URL.createObjectURL(blob)
  link.download = name

  document.body.appendChild(link)

  link.dispatchEvent(
    new MouseEvent('click', {
      bubbles: true,
      cancelable: true,
      view: window
    })
  )

  document.body.removeChild(link)
}

const base64ToBlob = (data: string, options: BlobPropertyBag): Blob => {
  const binaryImg = atob(data)
  const length = binaryImg.length
  const arrayBuffer = new ArrayBuffer(length)
  const uintArray = new Uint8Array(arrayBuffer)

  for (let i = 0; i < length; i++) uintArray[i] = binaryImg.charCodeAt(i)

  return new Blob([uintArray], options)
}

export {
  checkIfUrlIsActive,
  getMediaAbsolutePath,
  getStrapiBaseUrl,
  getApiBaseUrl,
  isAxiosError,
  parseIdFromParams,
  parseStrapiError,
  submitForm,
  useRemoteErrors,
  emptyStringToNull,
  downloadFile,
  base64ToBlob
}
