import { AxiosResponse } from 'axios'
import { makeAutoObservable, runInAction } from 'mobx'
import { StrapiResponse } from 'strapi-sdk-js'

import { parseStrapiError } from 'shared/utils'

import { VehiclesService } from 'services'

import { VehicleCreateData, VehicleData, VehicleUpdateData } from 'services/types'

import ApiErrorStore from '../ApiErrorStore'
import LoadingStore from '../LoadingStore'
import type RootStore from '../RootStore'
import { EditVehicleFormSchema } from 'components/EditVehicleForm/EditVehicleForm'

class EditVehicleStore {
  rootStore: RootStore

  isOpen: boolean = false

  vehicle?: VehicleData
  loading = new LoadingStore()
  apiError?: ApiErrorStore

  constructor (rootStore: RootStore) {
    makeAutoObservable(this)
    this.rootStore = rootStore
  }

  private getValue (attribute: keyof VehicleData['attributes'], def: any = ''): any {
    return this.vehicle != null ? this.vehicle.attributes[attribute] : def
  }

  get defaultValues (): EditVehicleFormSchema {
    return {
      client: this.vehicle?.attributes?.client?.data != null
        ? {
            id: this.vehicle.attributes.client.data.id,
            label: this.vehicle.attributes.client.data.attributes.phone
          }
        : null,
      plate: this.getValue('plate'),
      manufacturer: this.getValue('manufacturer'),
      model: this.getValue('model'),
      year: this.getValue('year'),
      vin: this.getValue('vin'),
      engineVolume: this.getValue('engineVolume'),
      engineNumber: this.getValue('engineNumber'),
      power: this.getValue('power'),
      fuel: this.getValue('fuel') ?? '',
      lastOilReplacementDate: this.getValue('lastOilReplacementDate'),
      lastOilReplacementMileage: this.getValue('lastOilReplacementMileage'),
      lastTimingGearReplacementDate: this.getValue('lastTimingGearReplacementDate'),
      lastTimingGearReplacementMileage: this.getValue('lastTimingGearReplacementMileage')
    }
  }

  public reset (): void {
    this.isOpen = false
    this.vehicle = undefined
    this.loading.reset()
    this.apiError = undefined
  }

  public startEdit (vehicle?: VehicleData): void {
    if (vehicle != null) this.vehicle = vehicle
    this.isOpen = true
  }

  public async save (data: VehicleUpdateData | VehicleCreateData): Promise<VehicleData | undefined> {
    this.loading.startUpdating()
    this.apiError = undefined
    const service = new VehiclesService(this.rootStore.token)

    try {
      let response: AxiosResponse<StrapiResponse<VehicleData>>
      if (this.vehicle != null) response = await service.update(this.vehicle.id, data)
      else response = await service.create(data)
      runInAction(() => { this.vehicle = response.data.data })
      return response.data.data
    } catch (e) {
      runInAction(() => { this.apiError = new ApiErrorStore(parseStrapiError(e)) })
    } finally {
      this.loading.finishUpdating()
    }
  }
}

export default EditVehicleStore
