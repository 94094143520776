import { type AxiosResponse } from 'axios'

import { StrapiBackend } from './backends'
import { type ApiAuthResponse } from './types'

/**
 * Auth service for Strapi backend, which handles authorizing and de-authorizing user.
 */
class AuthService extends StrapiBackend {
  BASE_URL: string = '/auth/local'

  /**
   * Authorizes given user and returns JWT token if successful.
   * Override `headers` so that we do not try to authorize with a token being sent in Authorization header.
   */
  public async authorize (identifier: string, password: string): Promise<string | undefined> {
    const response: AxiosResponse<ApiAuthResponse> = await this.post(
      this.url, { identifier, password }, { headers: undefined }
    )
    return response.status !== 200 ? undefined : response.data.jwt
  }

  /**
   * Check if the backend is authorized to call the API.
   */
  public async isAuthorized (): Promise<boolean> {
    const response: AxiosResponse = await this.get(this.HOST + '/users/me')
    return response.status === 200
  }
}

export default AuthService
