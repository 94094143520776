import { makeAutoObservable, runInAction } from 'mobx'

import { AccountService, MediaService } from 'services'

import { ApiMedia, ApiUser, ApiUserUpdate } from 'services/types'

import type RootStore from '../RootStore'

class AccountStore {
  rootStore: RootStore

  user?: ApiUser

  constructor (rootStore: RootStore) {
    makeAutoObservable(this)
    this.rootStore = rootStore
  }

  public async fetchUser (): Promise<ApiUser | undefined> {
    const service = new AccountService(this.rootStore.token)
    try {
      const user = await service.fetchUser()
      runInAction(() => { this.user = user })
      return user
    } catch (e) {
      return undefined
    }
  }

  public async updateAccount (data: ApiUserUpdate): Promise<ApiUser | undefined> {
    if (this.user === undefined) return
    const service = new AccountService(this.rootStore.token)
    try {
      const user = await service.updateUser(String(this.user.id), data)
      if (user !== undefined) runInAction(() => { this.user = user })
      return user
    } catch (e) {
      return undefined
    }
  }

  public async updateAvatar (image: Blob | string): Promise<ApiMedia | undefined> {
    if (this.user === undefined) return
    const service = new MediaService(this.rootStore.token)
    try {
      const avatar = await service.uploadMedia({
        files: image,
        ref: 'plugin::users-permissions.user',
        refId: this.user.id,
        field: 'avatar'
      })
      runInAction(() => { if (this?.user != null) this.user.avatar = avatar })
      return avatar
    } catch (e) {
      return undefined
    }
  }

  public async removeAvatar (): Promise<ApiUser | undefined> {
    if (this.user === undefined) return
    const service = new AccountService(this.rootStore.token)
    try {
      const user = await service.updateUser(String(this.user.id), { avatar: null })
      if (user !== undefined) runInAction(() => { this.user = user })
      return user
    } catch (e) {
      return undefined
    }
  }
}

export default AccountStore
