import { FC } from 'react'

import { Card, CardContent, CardHeader, Grow, Typography, useMediaQuery, useTheme } from '@mui/material'
import Grid from '@mui/material/Unstable_Grid2'

import { Page, AvatarInput, AccountForm } from 'components'

import s from './AccountSettingsPage.module.scss'

const AccountSettingsPage: FC<Props> = () => {
  const theme = useTheme()
  const lgUp = useMediaQuery(theme.breakpoints.up('lg'))

  return (
    <Page
      title="Zarządzaj profilem"
      description="Tutaj możesz zarządzać swoim profilem, zmienić jego ustawienia oraz hasło"
      className={s.AccountSettingsPage}
      data-testid={TEST_HOOKS.ACCOUNTSETTINGSPAGE}
    >
      <Grid container spacing={4} alignItems="flex-start">
        <Grid xs={12} lg={8}>
          <Grow in>
            <div>
              <AccountForm />
            </div>
          </Grow>
        </Grid>
        <Grid container xs={12} lg={4}>
          <Grid xs={12}>
            <Grow in>
              <Card>
                <CardHeader title="Zdjęcie profilowe" />
                <CardContent>
                  <AvatarInput />
                </CardContent>
              </Card>
            </Grow>
          </Grid>
          <Grid xs={12} order={lgUp ? 'initial' : -1}>
            <Grow in>
              <Card>
                <CardHeader title="Zmiana hasła" />
                <CardContent>
                  <Typography>
                    W celu zmiany hasła skontaktuj się z administratorem.
                  </Typography>
                </CardContent>
              </Card>
            </Grow>
          </Grid>
        </Grid>
      </Grid>
    </Page>
  )
}

const TEST_HOOKS = {
  ACCOUNTSETTINGSPAGE: 'accountSettingsPage'
}

// FIXME: Either add props or remove the interface!
// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface Props {

}

export default AccountSettingsPage

export {
  AccountSettingsPage,
  TEST_HOOKS,
  type Props
}
