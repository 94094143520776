import { AvailableIndices } from '../services/types'

const JWT_COOKIE_NAME = 'jwt'

const PHONE_REGEX = '^[\\+]?[(]?[0-9]{3}[)]?[-\\s\\.]?[0-9]{3}[-\\s\\.]?[0-9]{4,6}$'

const SEARCHABLE_ATTRIBUTES = new Map<AvailableIndices, string[]>([
  ['client', ['phone', 'phoneNoPrefix', 'tin', 'vehicles.plate']],
  ['vehicle', ['fullName', 'plate', 'client.phone', 'client.phoneNoPrefix']],
  ['service', ['vehicle.fullName', 'vehicle.plate', 'client.phoneNoPrefix', 'client.phone']]
])

export {
  JWT_COOKIE_NAME,
  PHONE_REGEX,
  SEARCHABLE_ATTRIBUTES
}
