import { ChangeEvent, FC, useEffect, useRef } from 'react'

import { AddOutlined } from '@mui/icons-material'
import { Avatar, Box, Button, Skeleton } from '@mui/material'
import clsx from 'clsx'
import { observer } from 'mobx-react-lite'
import { useSnackbar } from 'notistack'
import { useTranslation } from 'react-i18next'

import { getMediaAbsolutePath } from 'shared/utils'

import { useStores } from 'stores/hooks'

import s from './AvatarInput.module.scss'

const AvatarInput: FC<Props> = () => {
  const { enqueueSnackbar } = useSnackbar()
  const { t } = useTranslation()
  const { account, auth } = useStores()
  const ref = useRef<HTMLInputElement>(null)
  const avatarSrc = account.user?.avatar?.url
  const hasAvatar = avatarSrc != null

  useEffect(() => {
    if (account.user === undefined) void account.fetchUser()
  }, [account])

  const handleChange = (e: ChangeEvent<HTMLInputElement>): void => {
    if (e.target.files?.[0] != null) {
      const file = e.target.files[0]
      void account.removeAvatar()
        .then(async () => await account.updateAvatar(file))
        .then(async () => await Promise.all([auth.fetchUser(), account.fetchUser()]))
        .then(() => enqueueSnackbar(t('accountPage.avatar.notification.changed'), { variant: 'success' }))
    }
  }

  const handleClick = (): void => {
    if (ref.current != null) ref.current.click()
  }

  const handleRemove = (): void => {
    void account.removeAvatar()
      .then(async () => await Promise.all([auth.fetchUser(), account.fetchUser()]))
      .then(() => enqueueSnackbar(t('accountPage.avatar.notification.removed'), { variant: 'success' }))
  }

  if (account.user === undefined) {
    return (
      <Box className={s.Wrapper}>
        <Skeleton className={s.AvatarInput} variant="circular" />
        <Skeleton width={100} height={36} />
      </Box>
    )
  }

  return (
    <Box className={s.Wrapper}>
      <div className={s.AvatarInput} data-testid={TEST_HOOKS.AVATARINPUT}>
        {hasAvatar
          ? <img src={getMediaAbsolutePath(avatarSrc ?? '')} className={s.Image} alt="" />
          : <Avatar className={s.Image} />
        }
        <Button
          className={clsx(s.Button, hasAvatar && s.imagePresent)}
          startIcon={<AddOutlined />}
          onClick={handleClick}
        >
          <input
            ref={ref}
            type="file"
            accept="image/*"
            hidden
            onChange={handleChange}
          />
          {t(hasAvatar ? 'accountPage.avatar.change' : 'accountPage.avatar.add')}
        </Button>
      </div>
      {hasAvatar && (
        <Button
          size="small"
          onClick={handleRemove}
          variant="outlined"
          color="primary"
        >
          {t('accountPage.avatar.remove')}
        </Button>
      )}
    </Box>
  )
}

const TEST_HOOKS = {
  AVATARINPUT: 'avatarInput'
}

// FIXME: Either add props or remove the interface!
// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface Props {

}

const withObserver = observer(AvatarInput)

export default withObserver

export {
  withObserver as AvatarInput,
  TEST_HOOKS,
  type Props
}
