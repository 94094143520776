import { makeAutoObservable, runInAction } from 'mobx'
import { ProfileResponse } from 'smsapi'

import { SmsApiService } from 'services'

import type RootStore from '../RootStore'

class SmsApiStore {
  rootStore: RootStore

  account?: ProfileResponse
  error: boolean = false

  constructor (rootStore: RootStore) {
    makeAutoObservable(this)
    this.rootStore = rootStore
  }

  public reset (): void {
    this.account = undefined
    this.error = false
  }

  public async getAccount (): Promise<ProfileResponse | undefined> {
    const service = new SmsApiService(this.rootStore.token)
    try {
      const response = await service.checkAccount()
      runInAction(() => { this.account = response.data })
      return response.data
    } catch (e) {
      runInAction(() => { this.error = true })
    }
  }
}

export default SmsApiStore
