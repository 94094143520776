import { FC, useEffect, useRef } from 'react'

import { yupResolver } from '@hookform/resolvers/yup'
import { AddOutlined, RemoveOutlined } from '@mui/icons-material'
import { Box, Button, IconButton, TextField } from '@mui/material'
import Grid from '@mui/material/Unstable_Grid2'
import { observer } from 'mobx-react-lite'
import { useSnackbar } from 'notistack'
import { Controller, SubmitHandler, useFieldArray, useForm } from 'react-hook-form'
import * as yup from 'yup'
import { InferType } from 'yup'

import { useStores } from 'stores/hooks'

import { ApiErrorMessage, DialogForm } from 'components'

import s from './EditVehiclePartsForm.module.scss'

const schema = yup.object({
  parts: yup.array().of(yup.object({
    key: yup.string().required('Nazwa części jest wymagana'),
    value: yup.string().required('Numer seryjny jest wymagany')
  }))
})

type EditVehiclePartsFormSchema = InferType<typeof schema>

const EditVehiclePartsForm: FC<Props> = ({ onPostSave }) => {
  const ref = useRef(null)
  const { enqueueSnackbar } = useSnackbar()
  const { editVehicleParts } = useStores()
  const { control, formState, handleSubmit } = useForm<EditVehiclePartsFormSchema>({
    mode: 'all',
    resolver: yupResolver(schema)
  })
  const { fields, append, remove } = useFieldArray({ name: 'parts', control })

  useEffect(() => {
    remove()
    editVehicleParts.parts?.forEach(({ key, value }) => append({ key, value }))
  }, [editVehicleParts.parts])

  const onSubmit: SubmitHandler<EditVehiclePartsFormSchema> = async (data) => {
    const response = await editVehicleParts.save(data)
    if (response != null) {
      enqueueSnackbar('Zapisano dane techniczne', { variant: 'success' })
      if (onPostSave != null) onPostSave()
      editVehicleParts.reset()
      return
    }
    enqueueSnackbar('Błąd podczas zapisywania danych technicznych', { variant: 'error' })
  }

  const getPartError = (idx: number): Record<string, any> | undefined => {
    if (formState.errors.parts == null) return
    return formState.errors.parts[idx]
  }

  return (
    <DialogForm
      open={editVehicleParts.isOpen}
      title="Edytuj dane techniczne"
      formRef={ref}
      isValid={formState.isValid}
      isUpdating={editVehicleParts.loading.isUpdating}
      maxWidth="md"
      onCancel={() => editVehicleParts.reset()}
      className={s.EditVehiclePartsForm}
      data-testid={TEST_HOOKS.EDITVEHICLEPARTSFORM}
    >
      {(editVehicleParts.apiError != null && editVehicleParts.apiError.status > 499) && (
        <ApiErrorMessage className={s.ApiError} apiError={editVehicleParts.apiError} />
      )}
      {/* eslint-disable-next-line @typescript-eslint/no-misused-promises */}
      <form ref={ref} onSubmit={handleSubmit(onSubmit)}>
        {fields.map((field, idx) => (
          <Grid container spacing={2} key={field.id}>
            <Grid xs={10} md={5}>
              <Controller
                control={control}
                name={`parts.${idx}.key`}
                render={({ field }) => (
                  <TextField
                    required
                    fullWidth
                    label="Nazwa"
                    InputProps={field}
                    error={Boolean(getPartError(idx)?.key)}
                    helperText={getPartError(idx)?.key?.message ?? ' '}
                  />
                )}
              />
            </Grid>
            <Grid xs={10} md={6}>
              <Controller
                control={control}
                name={`parts.${idx}.value`}
                render={({ field }) => (
                  <TextField
                    required
                    fullWidth
                    label="Numer seryjny"
                    InputProps={field}
                    error={Boolean(getPartError(idx)?.value)}
                    helperText={getPartError(idx)?.value?.message ?? ' '}
                  />
                )}
              />
            </Grid>
            <Grid xs={2} md={1} className={s.RemoveFieldButton}>
              <IconButton color="primary" size="large" onClick={() => remove(idx)}>
                <RemoveOutlined />
              </IconButton>
            </Grid>
          </Grid>
        ))}
      </form>
      <Box className={s.AddFieldButton}>
        <Button
          color="primary"
          onClick={() => append({ key: '', value: '' })}
          startIcon={<AddOutlined />}
        >
          Dodaj część
        </Button>
      </Box>
    </DialogForm>
  )
}

const TEST_HOOKS = {
  EDITVEHICLEPARTSFORM: 'editVehiclePartsForm'
}

interface Props {
  onPostSave?: () => any
}

const withObserver = observer(EditVehiclePartsForm)

export default withObserver

export {
  withObserver as EditVehiclePartsForm,
  TEST_HOOKS,
  type Props,
  type EditVehiclePartsFormSchema
}
