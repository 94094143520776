import { FC, useEffect, useRef } from 'react'

import { yupResolver } from '@hookform/resolvers/yup'
import { AddOutlined, RemoveOutlined } from '@mui/icons-material'
import { Button, IconButton, InputAdornment, Stack, TextField, Typography } from '@mui/material'
import Grid from '@mui/material/Unstable_Grid2'
import { observer } from 'mobx-react-lite'
import { useSnackbar } from 'notistack'
import { Controller, SubmitHandler, useFieldArray, useForm } from 'react-hook-form'
import * as yup from 'yup'
import { InferType } from 'yup'

import { emptyStringToNull } from 'shared/utils'

import { ServiceActionsUpdateData } from 'services/types'

import { useStores } from 'stores/hooks'

import { ApiErrorMessage, AutoGenerateButton, DialogForm } from 'components'

import s from './EditServiceActionsForm.module.scss'

const schema = yup.object({
  price: yup.number().required('Cena wykonanych czynności jest wymagana').nullable().transform(emptyStringToNull),
  actions: yup.array().of(yup.object({
    data: yup.string().required('Nazwa wykonanej akcji jest wymagana')
  }))
})

type EditServiceActionsFormSchema = InferType<typeof schema>

const EditServiceActionsForm: FC<Props> = ({ onPostSave }) => {
  const ref = useRef(null)
  const { enqueueSnackbar } = useSnackbar()
  const { service, editServiceActions } = useStores()
  const { control, formState, watch, handleSubmit, setValue } = useForm<EditServiceActionsFormSchema>({
    mode: 'all',
    resolver: yupResolver(schema),
    defaultValues: editServiceActions.defaultValues
  })
  const { fields, append, remove, replace } = useFieldArray({ name: 'actions', control })

  const actionsLength = watch('actions')?.length ?? 0

  useEffect(() => {
    setValue('price', editServiceActions.defaultValues.price, { shouldDirty: false, shouldTouch: false })
  }, [editServiceActions.defaultValues.price])

  useEffect(() => {
    replace(
      editServiceActions.actions?.map(({ data }) => ({ data })) ?? []
    )
  }, [editServiceActions.actions])

  const onSubmit: SubmitHandler<EditServiceActionsFormSchema> = async (data) => {
    const response = await editServiceActions.save(data as ServiceActionsUpdateData)
    if (response != null) {
      enqueueSnackbar('Zapisano wykonane czynności', { variant: 'success' })
      if (onPostSave != null) onPostSave()
      editServiceActions.reset()
      return
    }
    enqueueSnackbar('Błąd podczas zapisywania wykonanych czynności', { variant: 'error' })
  }

  const getActionError = (idx: number): Record<string, any> | undefined => {
    if (formState.errors.actions == null) return
    return formState.errors.actions[idx]
  }

  const handleAutoGenerate = (): void => {
    replace(
      service.service?.attributes.parts.map(part => ({ data: part.name })) ?? []
    )
  }

  return (
    <DialogForm
      open={editServiceActions.isOpen}
      title="Edytuj wykonane czynności"
      formRef={ref}
      isValid={formState.isValid && formState.isDirty && actionsLength > 0}
      isUpdating={editServiceActions.loading.isUpdating}
      onCancel={() => editServiceActions.reset()}
      maxWidth="md"
      className={s.EditServiceActionsForm}
      data-testid={TEST_HOOKS.EDITSERVICEACTIONSFORM}
    >
      {(editServiceActions.apiError != null && editServiceActions.apiError.status > 499) && (
        <ApiErrorMessage className={s.ApiError} apiError={editServiceActions.apiError} />
      )}
      {/* eslint-disable-next-line @typescript-eslint/no-misused-promises */}
      <form ref={ref} onSubmit={handleSubmit(onSubmit)}>
        <Controller
          control={control}
          name="price"
          render={({ field }) => (
            <TextField
              required
              fullWidth
              type="number"
              label="Cena"
              InputProps={{
                ...field,
                endAdornment: (
                  <InputAdornment position="end">
                    PLN
                  </InputAdornment>
                )
              }}
              inputProps={{
                min: 0
              }}
              error={Boolean(formState.errors.price)}
              helperText={formState.errors.price?.message}
            />
          )}
        />
        <Typography variant="subtitle2" className={s.ActionsSubtitle}>
          Czynności
        </Typography>
        {fields.map((field, idx) => (
          <Grid container spacing={1} key={field.id}>
            <Grid xs={11} sm>
              <Controller
                control={control}
                name={`actions.${idx}.data`}
                render={({ field }) => (
                  <TextField
                    required
                    fullWidth
                    label="Nazwa czynności"
                    InputProps={field}
                    InputLabelProps={{ shrink: true }}
                    error={Boolean(getActionError(idx)?.name)}
                    helperText={getActionError(idx)?.name?.message ?? ' '}
                  />
                )}
              />
            </Grid>
            <Grid xs={1} sm="auto" className={s.RemoveFieldButton}>
              <IconButton color="primary" size="large" onClick={() => remove(idx)}>
                <RemoveOutlined />
              </IconButton>
            </Grid>
          </Grid>
        ))}
      </form>
      <Stack direction="row" spacing={2} className={s.AddFieldButton}>
        <AutoGenerateButton
          label="Wygeneruj na podstawie części"
          helperText={
            <>
              Wygeneruj czynności na podstawie użytych części.<br />
              Spowoduje usunięcie aktualnie dodanych czynności!
            </>
          }
          onClick={handleAutoGenerate}
        />
        <Button
          color="primary"
          onClick={() => append({ data: '' })}
          startIcon={<AddOutlined />}
        >
          Dodaj czynność
        </Button>
      </Stack>
    </DialogForm>
  )
}

const TEST_HOOKS = {
  EDITSERVICEACTIONSFORM: 'editServiceActionsForm'
}

interface Props {
  onPostSave?: () => any
}

const withObserver = observer(EditServiceActionsForm)

export default withObserver

export {
  withObserver as EditServiceActionsForm,
  TEST_HOOKS,
  type Props,
  type EditServiceActionsFormSchema
}
