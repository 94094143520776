import { FC } from 'react'

import { Skeleton, Stack, Typography } from '@mui/material'
import { observer } from 'mobx-react-lite'
import { useTranslation } from 'react-i18next'
import { type ProfileResponse } from 'smsapi'

import s from './SmsApiOverview.module.scss'

const OverviewItem: FC<OverviewItemProps> = ({ value, label }) => {
  return (
    <div>
      <Typography variant="h6" component="p">
        {value}
      </Typography>
      <Typography variant="subtitle2">
        {label}
      </Typography>
    </div>
  )
}

interface OverviewItemProps {
  value: string
  label: string
}

const SmsApiOverview: FC<Props> = ({ account }) => {
  const { t } = useTranslation()

  if (account == null) {
    return (
      <Stack spacing={4} className={s.SmsApiOverview} data-testid={TEST_HOOKS.SMSAPIOVERVIEW}>
        <Skeleton variant="rectangular" height={55} />
        <Skeleton variant="rectangular" height={55} />
        <Skeleton variant="rectangular" height={55} />
      </Stack>
    )
  }

  return (
    <Stack spacing={4} className={s.SmsApiOverview} data-testid={TEST_HOOKS.SMSAPIOVERVIEW}>
      <OverviewItem
        value={t('startPage.smsApi.balance.value', { points: account.points })}
        label={t('startPage.smsApi.balance.label')}
      />
      <OverviewItem
        value={account.phoneNumber}
        label={t('startPage.smsApi.phone.label')}
      />
      <OverviewItem
        value={account.email}
        label={t('startPage.smsApi.email.label')}
      />
    </Stack>
  )
}

const TEST_HOOKS = {
  SMSAPIOVERVIEW: 'smsApiOverview'
}

interface Props {
  account?: ProfileResponse
}

const withObserver = observer(SmsApiOverview)

export default withObserver

export {
  withObserver as SmsApiOverview,
  TEST_HOOKS,
  type Props
}
