import { AxiosResponse } from 'axios'

import { StrapiBackend } from './backends'
import { SettingsResponse, SettingsUpdateData, StrapiRequestParams } from './types'

class SettingsService extends StrapiBackend {
  readonly BASE_URL: string = '/setting'

  public async getSettings (params?: StrapiRequestParams): Promise<AxiosResponse<SettingsResponse>> {
    return await this.get(this.url, { params })
  }

  public async updateSettings (data: SettingsUpdateData, params?: StrapiRequestParams): Promise<AxiosResponse<SettingsResponse>> {
    return await this.put(this.url, { data: { ...data } }, { params })
  }
}

export default SettingsService
