import { FC, useEffect, useState } from 'react'

import {
  AccountBalanceOutlined,
  CheckOutlined, ChevronRightOutlined,
  ClearOutlined,
  CreditCardOutlined,
  DeleteOutlined,
  EditOutlined,
  ExtensionOutlined,
  InfoOutlined, MonetizationOnOutlined, MoneyOffOutlined,
  PanToolOutlined, PrintOutlined, QuestionMarkOutlined, ThumbUpOutlined, ViewListOutlined
} from '@mui/icons-material'
import {
  Button,
  Card,
  CardContent,
  CardHeader, CircularProgress,
  Divider,
  Grow,
  IconButton, Stack,
  SvgIcon,
  Tooltip
} from '@mui/material'
import Grid from '@mui/material/Unstable_Grid2'
import clsx from 'clsx'
import dayjs from 'dayjs'
import { useConfirm } from 'material-ui-confirm'
import { observer } from 'mobx-react-lite'
import { SnackbarKey, useSnackbar } from 'notistack'
import { useTranslation } from 'react-i18next'
import { useNavigate, useParams } from 'react-router'
import { Link as RouterLink } from 'react-router-dom'

import { downloadFile, parseIdFromParams } from 'shared/utils'

import { FuelLevel, PaymentType, ServiceTemplate } from 'services/types'

import { useStores } from 'stores/hooks'

import { NotFoundPage } from 'pages'

import {
  CarCard,
  ClientCard,
  DistinctList, EditServiceActionsForm,
  EditServiceForm,
  EditServicePartsForm,
  Page,
  PageLoader,
  ServiceStatusBadge
} from 'components'

import { DistinctListItem } from 'components/DistinctList/DistinctList'

import s from './ServicePage.module.scss'

const PaymentTypeIconMap = new Map<PaymentType, typeof SvgIcon>([
  ['card', CreditCardOutlined],
  ['transfer', AccountBalanceOutlined],
  ['cash', MonetizationOnOutlined],
  ['other', MoneyOffOutlined]
])

const FuelLevelFriendlyMap = new Map<FuelLevel, string>([
  ['empty', 'Pusty'],
  ['quarter', '1/4 baku'],
  ['half', 'Pół baku'],
  ['third', '3/4 baku'],
  ['full', 'Pełny bak']
])

const AcceptField: FC<AcceptFieldProps> = ({ value = false }) => {
  const Icon = value ? CheckOutlined : ClearOutlined
  return (
    <div className={clsx(s.AcceptField, value ? s.yes : s.no)}>
      {value ? 'Tak' : 'Nie'}
      <Icon className={s.AcceptIcon} />
    </div>
  )
}

interface AcceptFieldProps {
  value?: boolean
}

const ServicePage: FC<Props> = () => {
  const [isPdfGenerating, setIsPdfGenerating] = useState<Record<ServiceTemplate, boolean>>({
    service: false,
    serviceAccept: false,
    repairCard: false
  })
  const { t } = useTranslation()
  const { id } = useParams()
  const parsedId: number = parseIdFromParams(id)
  const { service, editService, editServiceParts, editServiceActions } = useStores()
  const removalConfirm = useConfirm()
  const navigate = useNavigate()
  const { enqueueSnackbar } = useSnackbar()
  const data = service.service

  useEffect(() => {
    if (isNaN(parsedId)) return
    void service.fetch(parsedId).then(async () => await service.fetchOtherServices())
    return () => service.reset()
  }, [parsedId])

  const onRemove = (): void => {
    removalConfirm()
      .then(async () => {
        const removed = await service.removeService(parsedId)
        if (removed) navigate('/app/services', { replace: true })
        else enqueueSnackbar('Nie udało się usunąć serwisu', { variant: 'error' })
      })
      .catch(() => null)
  }

  const onPdfGenerate = async (): Promise<void> => {
    if (service.service == null || isPdfGenerating.service) return
    const notifyError = (): SnackbarKey => enqueueSnackbar(
      'Nie udało się pobrać podsumowania serwisu',
      { variant: 'error' }
    )
    setIsPdfGenerating({ ...isPdfGenerating, service: true })
    try {
      const pdf = await service.getPdf(service.service.id, 'service')
      const filename = `motocar-serwis-${service.service.id}-${dayjs().format('DD.MM.YYYY')}.pdf`
      if (pdf != null) downloadFile(pdf, filename)
      else notifyError()
    } catch (e) {
      notifyError()
    } finally {
      setIsPdfGenerating({ ...isPdfGenerating, service: false })
    }
  }

  const onServiceAcceptGenerate = async (): Promise<void> => {
    if (service.service == null || isPdfGenerating.serviceAccept) return
    const notifyError = (): SnackbarKey => enqueueSnackbar(
      'Nie udało się pobrać potwierdzenia zlecenia',
      { variant: 'error' }
    )
    setIsPdfGenerating({ ...isPdfGenerating, serviceAccept: true })
    try {
      const pdf = await service.getPdf(service.service.id, 'serviceAccept')
      const filename = `motocar-potwierdzenie-zlecenia-${service.service.id}-${dayjs().format('DD.MM.YYYY')}.pdf`
      if (pdf != null) downloadFile(pdf, filename)
      else notifyError()
    } catch (e) {
      notifyError()
    } finally {
      setIsPdfGenerating({ ...isPdfGenerating, serviceAccept: false })
    }
  }

  const onUpdated = (): any => service.fetch(parsedId)

  if (isNaN(parsedId) || (service.loading.isLoaded && service?.apiError?.status === 404)) {
    return <NotFoundPage />
  }

  const PaymentTypeIcon = data != null ? PaymentTypeIconMap.get(data.attributes.paymentType) ?? QuestionMarkOutlined : QuestionMarkOutlined

  const content = data != null && (
    <Grid container spacing={4} className={s.Main}>
      <Grid xs={12} lg={6} xl={4}>
        <Grow in>
          <Card>
            <CardHeader
              title="Dane zlecenia"
              avatar={<InfoOutlined />}
              action={
                <Tooltip title="Edytuj dane zlecenia">
                  <IconButton onClick={() => editService.startEdit(service.service)}>
                    <EditOutlined />
                  </IconButton>
                </Tooltip>
              }
            />
            <CardContent>
              <DistinctList
                items={[
                  {
                    value: <ServiceStatusBadge status={data.attributes.status} />,
                    label: 'Status serwisu'
                  },
                  {
                    value: FuelLevelFriendlyMap.get(data.attributes.fuelLevel) ?? data.attributes.fuelLevel,
                    label: 'Poziom paliwa'
                  },
                  {
                    value: data.attributes.date,
                    label: 'Data przyjęcia'
                  },
                  {
                    value: (
                      <div className={s.PaymentType}>
                        <PaymentTypeIcon />
                        {t(`paymentType.${data.attributes.paymentType}`)}
                      </div>
                    ),
                    label: 'Forma płatności'
                  },
                  {
                    value: <AcceptField value={data.attributes.acceptPlateFrame} />,
                    label: 'Zgoda na naklejenie loga na ramę rejestracji'
                  },
                  {
                    value: <AcceptField value={data.attributes.acceptNewParts} />,
                    label: 'Zgoda na zakup nowych części'
                  },
                  {
                    value: <AcceptField value={data.attributes.acceptUsedParts} />,
                    label: 'Zgoda na zakup używanych części'
                  },
                  {
                    value: <AcceptField value={data.attributes.acceptUnoriginalParts} />,
                    label: 'Zgoda na zakup części nieoryginalnych'
                  },
                  {
                    value: <AcceptField value={data.attributes.acceptTestDrive} />,
                    label: 'Zgoda na jazdę testową'
                  }
                ]}
              />
            </CardContent>
            <Divider />
            <CardContent>
              <DistinctList>
                <DistinctListItem
                  value={data.attributes.errand !== '' ? data.attributes.errand : '—'}
                  label="Zlecenie klienta"
                />
                <DistinctListItem value={data.attributes.vehicleState ?? '—'} label="Status pojazdu" />
                <DistinctListItem value={data.attributes.summary ?? '—'} label="Podsumowanie zlecenia" />
              </DistinctList>
            </CardContent>
          </Card>
        </Grow>
      </Grid>
      <Grid xs={12} lg={6} xl={4}>
        <Grow in>
          <Card>
            <CardHeader
              title="Części"
              avatar={<ExtensionOutlined />}
              action={
                <Tooltip title="Edytuj wykorzystane części">
                  <IconButton onClick={() => editServiceParts.startEdit(parsedId, service.service?.attributes.parts)}>
                    <EditOutlined />
                  </IconButton>
                </Tooltip>
              }
            />
            <CardContent>
              <DistinctList
                items={data.attributes.parts.map(part => ({
                  value: `${part.quantity} × ${part.name}`,
                  label: `${part.price} PLN`
                }))}
              >
              </DistinctList>
            </CardContent>
            <Divider />
            <CardContent>
              <DistinctList>
                <DistinctListItem
                  value={`${service.totalPartsCost} PLN`}
                  label="Łączny koszt części"
                />
              </DistinctList>
            </CardContent>
          </Card>
        </Grow>
      </Grid>
      <Grid xs={12} lg={6} xl={4}>
        <Grow in>
          <Card>
            <CardHeader
              title="Wykonane czynności"
              avatar={<PanToolOutlined />}
              action={
                <Tooltip title="Edytuj wykonane czynności">
                  <IconButton
                    onClick={() => editServiceActions.startEdit(
                      parsedId, service.service?.attributes.price, service.service?.attributes.actions
                    )}
                  >
                    <EditOutlined />
                  </IconButton>
                </Tooltip>
              }
            />
            <CardContent>
              <DistinctList
                items={data.attributes.actions.map(action => ({
                  value: action.data
                }))}
              >
              </DistinctList>
            </CardContent>
            <Divider />
            <CardContent>
              <DistinctListItem
                value={`${data.attributes.price ?? '0'} PLN`}
                label="Łączna cena wykonanych czynności"
              />
            </CardContent>
          </Card>
        </Grow>
      </Grid>
      <Grid xs={12} lg={6} xl={4}>
        <Grow in>
          <Card>
            <CardHeader title="Pozostałe serwisy samochodu" />
            <CardContent>
               <DistinctList
                items={service.otherServices.map(service => ({
                  label: service.attributes.errand,
                  value: service.attributes.date,
                  action: (
                    <IconButton edge="end" component={RouterLink} to={`/app/services/${service.id}`}>
                      <ChevronRightOutlined />
                    </IconButton>
                  )
                }))}
               />
            </CardContent>
          </Card>
        </Grow>
      </Grid>
      {data.attributes.vehicle.data?.attributes?.client?.data != null && (
        <Grid xs={12} lg={6} xl={4}>
          <Grow in>
            <div>
              <ClientCard client={data.attributes.vehicle.data.attributes.client.data} />
            </div>
          </Grow>
        </Grid>
      )}
      {data.attributes.vehicle.data != null && (
        <Grid xs={12} lg={6} xl={4}>
          <Grow in>
            <div>
              <CarCard car={data.attributes.vehicle.data} />
            </div>
          </Grow>
        </Grid>
      )}
    </Grid>
  )

  return (
    <Page
      title="Serwis"
      description="Zarządzaj serwisem pojazdu"
      className={s.ServicePage}
      data-testid={TEST_HOOKS.SERVICEPAGE}
      action={
        <Stack direction="row" spacing={2}>
          <Tooltip title="Drukuj podsumowanie zlecenia">
            {/* eslint-disable-next-line @typescript-eslint/no-misused-promises */}
            <IconButton color="secondary" onClick={onPdfGenerate}>
              {isPdfGenerating.service ? <CircularProgress size={24} /> : <PrintOutlined />}
            </IconButton>
          </Tooltip>
          <Tooltip title="Drukuj potwierdzenie zlecenia">
            {/* eslint-disable-next-line @typescript-eslint/no-misused-promises */}
            <IconButton color="secondary" onClick={onServiceAcceptGenerate}>
              {isPdfGenerating.serviceAccept ? <CircularProgress size={24} /> : <ThumbUpOutlined />}
            </IconButton>
          </Tooltip>
          <Tooltip title="Drukuj kartę naprawy">
            {/* eslint-disable-next-line @typescript-eslint/no-misused-promises */}
            <IconButton color="secondary" onClick={() => null} disabled>
              {isPdfGenerating.repairCard ? <CircularProgress size={24} /> : <ViewListOutlined />}
            </IconButton>
          </Tooltip>
          <Button
            onClick={onRemove}
            variant="contained"
            color="error"
            size="large"
            startIcon={<DeleteOutlined />}
          >
            Usuń serwis
          </Button>
        </Stack>
      }
    >
      {service.loading.isLoading && <PageLoader />}
      {service.loading.isLoaded && content}
      <EditServiceForm onPostSave={onUpdated} />
      <EditServicePartsForm onPostSave={onUpdated} />
      <EditServiceActionsForm onPostSave={onUpdated} />
    </Page>
  )
}

const TEST_HOOKS = {
  SERVICEPAGE: 'servicePage'
}

// FIXME: Either add props or remove the interface!
// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface Props {

}

const withObserver = observer(ServicePage)

export default withObserver

export {
  withObserver as ServicePage,
  TEST_HOOKS,
  type Props
}
