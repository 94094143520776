import { FC, useEffect } from 'react'

import { AddOutlined } from '@mui/icons-material'
import { Button, Card, CardContent, CardHeader, Grow } from '@mui/material'
import { observer } from 'mobx-react-lite'
import { useNavigate } from 'react-router'

import { useStores } from 'stores/hooks'

import { Page, CarsTable, PageLoader, ListPagination, Search, EditVehicleForm } from 'components'

import s from './CarsPage.module.scss'

const CarsPage: FC<Props> = () => {
  const navigate = useNavigate()
  const { vehicles, editVehicle } = useStores()

  useEffect(() => {
    void vehicles.fetch()
    return () => vehicles.reset()
  }, [])

  const onVehicleCreated = (): void => {
    if (editVehicle.vehicle != null) navigate(`/app/cars/${editVehicle.vehicle.id}`)
  }

  return (
    <Page
      narrow
      title="Samochody"
      description="Przeglądaj samochody zarejestowane w systemie"
      className={s.CarsPage}
      data-testid={TEST_HOOKS.CARSPAGE}
      action={
        <Button
          variant="contained"
          color="primary"
          size="large"
          startIcon={<AddOutlined />}
          onClick={() => editVehicle.startEdit()}
        >
          Dodaj samochód
        </Button>
      }
    >
      {vehicles.loading.isLoading && <PageLoader />}
      {vehicles.loading.isLoaded && (
        <Grow in>
          <Card>
            <CardHeader title={`Lista samochodów (${vehicles.pagination.total})`} />
            <CardContent>
              <Search
                className={s.Search}
                label="Wyszukaj"
                placeholder="Numer rejestracyjny, marka/model, numer klienta, itp."
                onSearch={async (value) => await vehicles.search(value)}
              />
              <CarsTable cars={vehicles.vehicles} size="small" isLoading={vehicles.loading.isUpdating} />
              {vehicles.searchQuery == null && (
                <ListPagination
                  pagination={vehicles.pagination}
                  onPageChange={async (page) => await vehicles.changePage(page)}
                />
              )}
            </CardContent>
          </Card>
        </Grow>
      )}
      <EditVehicleForm onPostSave={onVehicleCreated} />
    </Page>
  )
}

const TEST_HOOKS = {
  CARSPAGE: 'carsPage'
}

// FIXME: Either add props or remove the interface!
// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface Props {

}

const withObserver = observer(CarsPage)

export default withObserver

export {
  withObserver as CarsPage,
  TEST_HOOKS,
  type Props
}
