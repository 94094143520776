import { FC } from 'react'

import { Alert, AlertTitle, Typography } from '@mui/material'
import { observer } from 'mobx-react-lite'
import { useLocation } from 'react-router'

import { StrapiError } from 'services/types'

import { Page } from 'components'

import s from './ErrorPage.module.scss'

const ErrorPage: FC<Props> = ({ error }) => {
  const location = useLocation()

  return (
    <Page
      narrow
      title="Błąd"
      description={<>Twój adres: <code>{location.pathname}</code></>}
      className={s.ErrorPage}
      data-testid={TEST_HOOKS.ERRORPAGE}
    >
      <Typography>
        Niestety, wystąpił błąd aplikacji.
        Proszę spróbować ponownie, a jeśli problem występuje nadal – skontaktować się z administratorem.
      </Typography>
      {error != null && (
        <Alert severity="error" variant="filled" className={s.Alert}>
          <AlertTitle>{error.status} {error.name}</AlertTitle>
          {error.message}
        </Alert>
      )}
    </Page>
  )
}

const TEST_HOOKS = {
  ERRORPAGE: 'errorPage'
}

interface Props {
  error?: StrapiError
}

const withObserver = observer(ErrorPage)

export default withObserver

export {
  withObserver as ErrorPage,
  TEST_HOOKS,
  type Props
}
