import { makeAutoObservable, runInAction } from 'mobx'

import { parseStrapiError } from 'shared/utils'

import { VehiclesService } from 'services'

import { MileageData, VehicleData, VehicleMileagesUpdateDAta } from 'services/types'

import ApiErrorStore from '../ApiErrorStore'
import LoadingStore from '../LoadingStore'
import type RootStore from '../RootStore'

class EditVehicleMileagesStore {
  rootStore: RootStore

  isOpen: boolean = false

  vehicleId?: number
  mileages?: MileageData[]
  loading = new LoadingStore()
  apiError?: ApiErrorStore

  constructor (rootStore: RootStore) {
    makeAutoObservable(this)
    this.rootStore = rootStore
  }

  public reset (): void {
    this.isOpen = false
    this.loading.reset()
    this.mileages = undefined
    this.apiError = undefined
  }

  public startEdit (vehicleId: number, mileages?: MileageData[]): void {
    this.vehicleId = vehicleId
    if (mileages != null) this.mileages = mileages
    this.isOpen = true
  }

  public async save (data: VehicleMileagesUpdateDAta): Promise<VehicleData | undefined> {
    if (this.vehicleId == null) return
    this.loading.startUpdating()
    const service = new VehiclesService(this.rootStore.token)
    try {
      const response = await service.saveMileages(this.vehicleId, data)
      runInAction(() => { this.mileages = response.data.data.attributes.mileages })
      return response.data.data
    } catch (e) {
      runInAction(() => { this.apiError = new ApiErrorStore(parseStrapiError(e)) })
    } finally {
      this.loading.finishUpdating()
    }
  }
}

export default EditVehicleMileagesStore
