import { FC } from 'react'

import {
  HandymanOutlined,
  SettingsSuggestOutlined,
  SevereColdOutlined,
  SupportOutlined,
  TireRepairOutlined
} from '@mui/icons-material'
import {
  Box,
  Card,
  CardContent,
  CardHeader,
  Container,
  Toolbar,
  Typography
} from '@mui/material'
import Grid from '@mui/material/Unstable_Grid2'
import { observer } from 'mobx-react-lite'

import { PublicAppBar } from 'components'

import s from './IndexPage.module.scss'

const IndexPage: FC<Props> = () => {
  return (
    <div className={s.IndexPage} data-testid={TEST_HOOKS.INDEXPAGE}>
      <PublicAppBar variant="inverted" />
      <main className={s.Main}>
        <Toolbar />
        <section className={s.Intro}>
          <Grid container alignItems="stretch">
            <Grid xs={12} md={6}>
              <Box className={s.HeadlineColumn}>
                <HandymanOutlined className={s.Logo} />
                <Typography variant="h2" className={s.Headline}>
                  Profesjonalny serwis <span className={s.Emphasis}>twojego samochodu</span>
                </Typography>
                <Typography variant="h5" className={s.Slogan}>
                  Świadczymy kompletne usługi serwisowe dla samochodów osobowych oraz dostawczych
                </Typography>
              </Box>
            </Grid>
            <Grid xs={12} md={6} className={s.ImageColumn}>
              <img src="https://images.unsplash.com/photo-1619642751034-765dfdf7c58e?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1674&q=80" alt="" className={s.Image} />
            </Grid>
          </Grid>
        </section>
        <section className={s.Services}>
          <Container maxWidth="lg" className={s.ServicesHeading}>
            <Container maxWidth="sm">
              <Typography variant="h4" component="h2" className={s.ServicesTitle}>
                Nasze usługi
              </Typography>
              <Typography className={s.ServicesSubtitle}>
                Poznaj oferowaną przez nas gamę usług naprawczych oraz serwisowych
                i zobacz, co możemy dla ciebie zrobić
              </Typography>
            </Container>
            <Grid container spacing={4} className={s.ServicesGrid}>
              <Grid xs={12} sm={6} lg={4}>
                <Card>
                  <CardHeader
                    title="Mechanika"
                    avatar={<HandymanOutlined />}
                  />
                  <CardContent>
                    <Typography>
                      Naprawiamy oraz wymieniamy zużyte i zniszczone części. Dokonujemy modernizacji elementów
                      mechanicznych.
                    </Typography>
                  </CardContent>
                </Card>
              </Grid>
              <Grid xs={12} sm={6} lg={4}>
                <Card>
                  <CardHeader
                    title="Serwisowanie"
                    avatar={<SettingsSuggestOutlined />}
                  />
                  <CardContent>
                    <Typography>
                      Wykonujemy okresowe serwisowanie samochodu, takie jak wymiana oleju, płynu hamulcowego, itp.
                    </Typography>
                  </CardContent>
                </Card>
              </Grid>
              <Grid xs={12} sm={6} lg={4}>
                <Card>
                  <CardHeader
                    title="Serwis kół i opon"
                    avatar={<TireRepairOutlined />}
                  />
                  <CardContent>
                    <Typography>
                      Ustawiamy zbieżność, wyważamy koła, zmieniamy opony sezonowe i uszczelniamy drobne dziury.
                    </Typography>
                  </CardContent>
                </Card>
              </Grid>
              <Grid xs={12} sm={6} lg={4}>
                <Card>
                  <CardHeader
                    title="Serwis klimatyzacji"
                    avatar={<SevereColdOutlined />}
                  />
                  <CardContent>
                    <Typography>
                      Uzupełniamy odczynnik chłodzący oraz w razie potrzeby naprawiamy układ klimatyzacji.
                    </Typography>
                  </CardContent>
                </Card>
              </Grid>
              <Grid xs={12} sm={6} lg={4}>
                <Card>
                  <CardHeader
                    title="Inne usługi"
                    avatar={<SupportOutlined />}
                  />
                  <CardContent>
                    <Typography>
                      Wykonujemy także wiele innych usług — pełen ich wachlarz poznacie Państwo kontakując się z nami.
                    </Typography>
                  </CardContent>
                </Card>
              </Grid>
            </Grid>
          </Container>
        </section>
        <footer className={s.Footer}>
          <Container maxWidth="lg">
            <Typography align="center">
              Copyright &copy; {new Date().getFullYear()} Moto-Car Dawid Ciszczoń
            </Typography>
          </Container>
        </footer>
      </main>
    </div>
  )
}

const TEST_HOOKS = {
  INDEXPAGE: 'indexPage'
}

// FIXME: Either add props or remove the interface!
// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface Props {

}

const withObserver = observer(IndexPage)

export default withObserver

export {
  withObserver as IndexPage,
  TEST_HOOKS,
  type Props
}
