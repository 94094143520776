import { ChangeEvent, FC } from 'react'

import { TablePagination } from '@mui/material'
import clsx from 'clsx'
import { observer } from 'mobx-react-lite'
import { useTranslation } from 'react-i18next'

import { Pagination } from 'services/types'

import s from './ListPagination.module.scss'

const ListPagination: FC<Props> = ({ pagination, onPageChange, onRowsCountChange, className }) => {
  const { t } = useTranslation()

  return (
    <TablePagination
      className={clsx(s.ListPagination, className)}
      data-testid={TEST_HOOKS.LISTPAGINATION}
      component="div"
      count={pagination.total}
      page={pagination.page - 1}
      onPageChange={(_, page) => onPageChange(page + 1)}
      rowsPerPage={pagination.pageSize}
      onRowsPerPageChange={
        onRowsCountChange != null
          ? (e: ChangeEvent<HTMLInputElement>) => onRowsCountChange(parseInt(e.target.value, 10))
          : undefined
      }
      labelRowsPerPage={t('pagination.rowsPerPage')}
      labelDisplayedRows={
        ({ from, to, count }) =>
          t('pagination.counter', { from, to, total: count })
      }
      backIconButtonProps={{
        title: undefined
      }}
      nextIconButtonProps={{
        title: undefined
      }}
    />
  )
}

const TEST_HOOKS = {
  LISTPAGINATION: 'listPagination'
}

interface Props {
  pagination: Pagination
  onPageChange: (page: number) => any | Promise<void>
  onRowsCountChange?: (count: number) => any | Promise<void>
  className?: string
}

const withObserver = observer(ListPagination)

export default withObserver

export {
  withObserver as ListPagination,
  TEST_HOOKS,
  type Props
}
