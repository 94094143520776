import { AxiosResponse } from 'axios'

import { StrapiBackend } from './backends'
import { ClientsFindOneResponse, ClientsFindResponse, ClientUpdateData, StrapiRequestParams } from './types'

class ClientsService extends StrapiBackend {
  readonly BASE_URL: string = '/clients'
  readonly MEILI_INDEX = 'client'

  public async find (params?: StrapiRequestParams): Promise<AxiosResponse<ClientsFindResponse>> {
    return await this.get(this.url, { params })
  }

  public async findOne (id: number, params?: StrapiRequestParams): Promise<AxiosResponse<ClientsFindOneResponse>> {
    return await this.get(this.resourceUrl(id), { params })
  }

  public async update (id: number, data: any, params?: StrapiRequestParams): Promise<AxiosResponse<ClientsFindOneResponse>> {
    return await this.put(this.resourceUrl(id), { data: { ...data } }, { params })
  }

  public async remove (id: number, params?: StrapiRequestParams): Promise<AxiosResponse> {
    return await this.delete(this.resourceUrl(id), { params })
  }

  public async create (data: ClientUpdateData, params?: StrapiRequestParams): Promise<AxiosResponse<ClientsFindOneResponse>> {
    return await this.post(this.url, { data: { ...data } }, { params })
  }
}

export default ClientsService
