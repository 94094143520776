import pl from 'locale/pl.json'

import packageJson from '../../package.json'

import { i18nConfig } from './i18n'
import reportWebVitals from './reportWebVitals'

const appMeta = {
  name: packageJson.name,
  version: packageJson.version
}

const configureI18n = async (): Promise<void> => {
  await i18nConfig
    .init({
      resources: {
        pl: {
          translation: pl
        }
      },
      lng: 'pl',
      fallbackLng: 'pl',
      interpolation: {
        // react already safes from xss => https://www.i18next.com/translation-function/interpolation#unescape
        escapeValue: false
      }
    })
}

const configureApp = async (): Promise<void> => {
  if (i18nConfig !== null && !i18nConfig.isInitialized) await configureI18n()

  // If you want to start measuring performance in your app, pass a function
  // to log results (for example: reportWebVitals(console.log))
  // or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
  reportWebVitals()
}

export {
  configureI18n,
  configureApp,
  appMeta
}
