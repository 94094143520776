import { makeAutoObservable, runInAction } from 'mobx'

import { parseStrapiError } from 'shared/utils'

import { ServicesService } from 'services'

import {
  ActionData,
  ServiceActionsUpdateData,
  ServiceData
} from 'services/types'

import ApiErrorStore from '../ApiErrorStore'
import LoadingStore from '../LoadingStore'
import type RootStore from '../RootStore'

class EditServiceActionsStore {
  rootStore: RootStore

  isOpen: boolean = false

  vehicleId?: number
  actions?: ActionData[]
  price?: number | string
  loading = new LoadingStore()
  apiError?: ApiErrorStore

  constructor (rootStore: RootStore) {
    makeAutoObservable(this)
    this.rootStore = rootStore
  }

  get defaultValues (): { price: number } {
    return {
      price: (this.price != null && this.price !== '') ? parseFloat(String(this.price)) : 0
    }
  }

  public reset (): void {
    this.isOpen = false
    this.loading.reset()
    this.actions = undefined
    this.price = undefined
    this.apiError = undefined
  }

  public startEdit (vehicleId: number, price?: number, actions?: ActionData[]): void {
    this.vehicleId = vehicleId
    if (actions != null) this.actions = actions
    if (price != null) this.price = price
    this.isOpen = true
  }

  public async save (data: ServiceActionsUpdateData): Promise<ServiceData | undefined> {
    if (this.vehicleId == null) return
    this.loading.startUpdating()
    const service = new ServicesService(this.rootStore.token)
    try {
      const response = await service.saveActions(this.vehicleId, data)
      runInAction(() => {
        this.price = response.data.data.attributes.price
        this.actions = response.data.data.attributes.actions
      })
      return response.data.data
    } catch (e) {
      runInAction(() => { this.apiError = new ApiErrorStore(parseStrapiError(e)) })
    } finally {
      this.loading.finishUpdating()
    }
  }
}

export default EditServiceActionsStore
