import { AxiosResponse } from 'axios'
import { makeAutoObservable, runInAction } from 'mobx'

import { parseStrapiError } from 'shared/utils'

import { ClientsService } from 'services'

import { ClientData, ClientsFindOneResponse, ClientUpdateData } from 'services/types'

import ApiErrorStore from '../ApiErrorStore'
import LoadingStore from '../LoadingStore'
import type RootStore from '../RootStore'

class ClientStore {
  rootStore: RootStore

  client?: ClientData
  loading = new LoadingStore()
  apiError?: ApiErrorStore

  constructor (rootStore: RootStore) {
    makeAutoObservable(this)
    this.rootStore = rootStore
  }

  public reset (): void {
    this.client = undefined
    this.apiError = undefined
    this.loading.reset()
  }

  public async fetchClient (id: number): Promise<void> {
    this.loading.startLoading()
    const service = new ClientsService(this.rootStore.token)
    let client: AxiosResponse<ClientsFindOneResponse>
    try {
      client = await service.findOne(id)
      runInAction(() => { this.client = client.data.data })
    } catch (e) {
      runInAction(() => { this.apiError = new ApiErrorStore(parseStrapiError(e)) })
    }
    this.loading.finishLoading()
  }

  public async update (data: ClientUpdateData): Promise<void> {
    if (this?.client == null) return

    this.loading.startUpdating()
    const service = new ClientsService(this.rootStore.token)
    try {
      await service.update(this.client.id, data)
      const client = await service.findOne(this.client.id)
      runInAction(() => { this.client = client.data.data })
    } catch (e) {
      runInAction(() => { this.apiError = new ApiErrorStore(parseStrapiError(e)) })
    }
    this.loading.finishUpdating()
  }

  public async removeClient (): Promise<boolean> {
    if (this?.client == null) return false

    this.loading.startUpdating()
    const service = new ClientsService(this.rootStore.token)

    try {
      await service.remove(this.client.id)
      return true
    } catch (e) {
      runInAction(() => { this.apiError = new ApiErrorStore(parseStrapiError(e)) })
      return true
    } finally {
      this.loading.finishUpdating()
    }
  }
}

export default ClientStore
