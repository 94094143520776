import { AxiosResponse } from 'axios'
import { makeAutoObservable, runInAction } from 'mobx'
import { StrapiResponse } from 'strapi-sdk-js'

import { parseStrapiError } from 'shared/utils'

import { ServicesService } from 'services'

import { ServiceCreateData, ServiceData, ServiceUpdateData } from 'services/types'

import ApiErrorStore from '../ApiErrorStore'
import LoadingStore from '../LoadingStore'
import type RootStore from '../RootStore'

class EditServiceStore {
  rootStore: RootStore

  isOpen: boolean = false

  service?: ServiceData
  loading = new LoadingStore()
  apiError?: ApiErrorStore

  constructor (rootStore: RootStore) {
    makeAutoObservable(this)
    this.rootStore = rootStore
  }

  private getValue (attribute: keyof ServiceData['attributes'], def: any = ''): any {
    return this.service != null ? this.service.attributes[attribute] : def
  }

  get defaultValues (): any {
    const vehicle = this.service?.attributes?.vehicle?.data
    return {
      vehicle: vehicle != null
        ? {
            id: vehicle.id,
            label: `${vehicle.attributes.manufacturer} ${vehicle.attributes.model} (${vehicle.attributes.plate})`
          }
        : null,
      status: this.getValue('status'),
      date: this.getValue('date'),
      errand: this.getValue('errand'),
      vehicleState: this.getValue('vehicleState'),
      summary: this.getValue('summary'),
      fuelLevel: this.getValue('fuelLevel'),
      mileage: this.getValue('mileage'),
      maxAmount: this.getValue('maxAmount'),
      paymentType: this.getValue('paymentType'),
      acceptNewParts: this.getValue('acceptNewParts', false),
      acceptUsedParts: this.getValue('acceptUsedParts', false),
      acceptUnoriginalParts: this.getValue('acceptUnoriginalParts', false),
      acceptPlateFrame: this.getValue('acceptPlateFrame', false),
      acceptTestDrive: this.getValue('acceptTestDrive', false)
    }
  }

  public reset (): void {
    this.isOpen = false
    this.service = undefined
    this.loading.reset()
    this.apiError = undefined
  }

  public startEdit (service?: ServiceData): void {
    if (service != null) this.service = service
    this.isOpen = true
  }

  public async save (data: ServiceUpdateData | ServiceCreateData): Promise<ServiceData | undefined> {
    this.loading.startUpdating()
    this.apiError = undefined
    const service = new ServicesService(this.rootStore.token)

    try {
      let response: AxiosResponse<StrapiResponse<ServiceData>>
      if (this.service != null) response = await service.update(this.service.id, data)
      else response = await service.create(data)
      runInAction(() => { this.service = response.data.data })
      return response.data.data
    } catch (e) {
      runInAction(() => { this.apiError = new ApiErrorStore(parseStrapiError(e)) })
    } finally {
      this.loading.finishUpdating()
    }
  }

  public generateSummary (): string {
    if (this.service == null) return ''
    return `Użyte części:
${this.service.attributes.parts.map(part => `- ${part.name}`).join(',\n')}.

Wykonane czynności:
${this.service.attributes.actions.map(action => `- ${action.data}`).join(',\n')}.`
  }
}

export default EditServiceStore
