import { FC, ReactNode } from 'react'

import { Box, Button } from '@mui/material'

import s from './QuickActions.module.scss'

const Action: FC<ActionProps> = ({ name, icon = null, variant = undefined, ButtonProps = {} }) => {
  return (
    <Button
      variant={variant}
      color="primary"
      size="large"
      startIcon={icon}
      {...ButtonProps}
    >
      {name}
    </Button>
  )
}

interface ActionProps {
  name: string
  icon?: ReactNode
  variant?: 'text' | 'outlined' | 'contained'
  ButtonProps?: Object
}

const QuickActions: FC<Props> = ({ actions, variant = 'outlined' }) => {
  return (
    <Box className={s.QuickActions} data-testid={TEST_HOOKS.QUICKACTIONS}>
      {actions.map(action => {
        return (
          <Action
            key={action.name}
            variant={variant}
            {...action}
          />
        )
      })}
    </Box>
  )
}

const TEST_HOOKS = {
  QUICKACTIONS: 'quickActions'
}

interface Props {
  actions: ActionProps[]
  variant?: 'text' | 'outlined' | 'contained'
}

export default QuickActions

export {
  QuickActions,
  TEST_HOOKS,
  type Props
}
