import { FC, useMemo } from 'react'

import {
  DescriptionOutlined,
  HandymanOutlined,
  HomeOutlined,
  PeopleOutlined,
  SettingsOutlined,
  SvgIconComponent,
  TimeToLeaveOutlined
} from '@mui/icons-material'
import { List, ListItem, ListItemButton, ListItemIcon, ListItemText } from '@mui/material'
import clsx from 'clsx'
import { observer } from 'mobx-react-lite'
import { useTranslation } from 'react-i18next'
import { useLocation } from 'react-router'
import { Link as RouterLink } from 'react-router-dom'

import { checkIfUrlIsActive } from 'shared/utils'

import s from './MainMenu.module.scss'

const MenuItem: FC<MenuItemProps> = ({
  IconComponent,
  primary,
  secondary,
  url,
  disabled = false
}) => {
  const location = useLocation()
  const selected = useMemo<boolean>(
    () => checkIfUrlIsActive(location, url),
    [checkIfUrlIsActive, location, url]
  )

  return (
    <ListItem disablePadding>
      <ListItemButton
        disabled={disabled}
        className={clsx(s.Button, selected && s.selected)}
        component={RouterLink}
        to={url}
      >
        <ListItemIcon>
          <IconComponent className={s.Icon} />
        </ListItemIcon>
        <ListItemText primary={primary} secondary={secondary} />
      </ListItemButton>
    </ListItem>
  )
}

interface MenuItemProps {
  IconComponent: SvgIconComponent
  primary: string
  secondary?: string
  url: string
  disabled?: boolean
}

const MainMenu: FC<Props> = ({ className }) => {
  const { t } = useTranslation()

  return (
    <List disablePadding className={clsx(s.MainMenu, className)} data-testid={TEST_HOOKS.MAINMENU}>
      <MenuItem url="/app" IconComponent={HomeOutlined} primary={t('menu.start')} />
      <MenuItem url="/app/services" IconComponent={HandymanOutlined} primary={t('menu.services')} />
      <MenuItem url="/app/cars" IconComponent={TimeToLeaveOutlined} primary={t('menu.cars')} />
      <MenuItem url="/app/clients" IconComponent={PeopleOutlined} primary={t('menu.clients')} />
      <MenuItem disabled url="/app/documents" IconComponent={DescriptionOutlined} primary={t('menu.documents')} />
      <MenuItem url="/app/settings" IconComponent={SettingsOutlined} primary={t('menu.settings')} />
    </List>
  )
}

const TEST_HOOKS = {
  MAINMENU: 'mainMenu'
}

interface Props {
  className?: string
}

const withObserver = observer(MainMenu)

export default withObserver

export {
  withObserver as MainMenu,
  TEST_HOOKS,
  type Props
}
