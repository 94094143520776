import { makeAutoObservable, runInAction } from 'mobx'

import { parseStrapiError } from 'shared/utils'

import { ServicesService } from 'services'

import { PartData, ServiceData, ServicePartsUpdateData } from 'services/types'

import ApiErrorStore from '../ApiErrorStore'
import LoadingStore from '../LoadingStore'
import type RootStore from '../RootStore'

class EditServicePartsStore {
  rootStore: RootStore

  isOpen: boolean = false

  vehicleId?: number
  parts?: PartData[]
  loading = new LoadingStore()
  apiError?: ApiErrorStore

  constructor (rootStore: RootStore) {
    makeAutoObservable(this)
    this.rootStore = rootStore
  }

  public reset (): void {
    this.isOpen = false
    this.loading.reset()
    this.parts = undefined
    this.apiError = undefined
  }

  public startEdit (vehicleId: number, parts?: PartData[]): void {
    this.vehicleId = vehicleId
    if (parts != null) this.parts = parts
    this.isOpen = true
  }

  public async save (data: ServicePartsUpdateData): Promise<ServiceData | undefined> {
    if (this.vehicleId == null) return
    this.loading.startUpdating()
    const service = new ServicesService(this.rootStore.token)
    try {
      const response = await service.saveParts(this.vehicleId, data)
      runInAction(() => { this.parts = response.data.data.attributes.parts })
      return response.data.data
    } catch (e) {
      runInAction(() => { this.apiError = new ApiErrorStore(parseStrapiError(e)) })
    } finally {
      this.loading.finishUpdating()
    }
  }
}

export default EditServicePartsStore
