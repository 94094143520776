import { makeAutoObservable, runInAction } from 'mobx'

import { parseStrapiError } from 'shared/utils'

import { VehiclesService } from 'services'

import { Pagination, VehicleData } from 'services/types'

import ApiErrorStore from '../ApiErrorStore'
import LoadingStore from '../LoadingStore'
import PaginationStore from '../PaginationStore'
import type RootStore from '../RootStore'

const commonParams = {
  sort: 'id:desc',
  disableAutoPopulate: true,
  populate: 'client'
}

class VehiclesStore {
  rootStore: RootStore

  vehicles: VehicleData[] = []
  pagination = new PaginationStore()
  loading = new LoadingStore()
  apiError?: ApiErrorStore

  searchQuery?: string

  constructor (rootStore: RootStore) {
    makeAutoObservable(this)
    this.rootStore = rootStore
  }

  public reset (): void {
    this.vehicles = []
    this.searchQuery = undefined
    this.apiError = undefined
    this.pagination.reset()
    this.loading.reset()
  }

  public async changePage (page: number, refreshPagination: boolean = false): Promise<void> {
    this.loading.startUpdating()
    this.pagination.setPage(page)
    const service = new VehiclesService(this.rootStore.token)
    try {
      const response = await service.find({ ...commonParams, pagination: this.pagination.queryParams })
      runInAction(() => {
        this.vehicles = response.data.data
        if (refreshPagination) this.pagination.update(response.data.meta.pagination as Pagination)
      })
    } finally {
      this.loading.finishUpdating()
    }
  }

  public async fetch (): Promise<void> {
    this.loading.startLoading()
    this.searchQuery = undefined
    const service = new VehiclesService(this.rootStore.token)
    const response = await service.find(commonParams)
    runInAction(() => {
      this.pagination.update(response.data.meta.pagination as Pagination)
      this.vehicles = response.data.data
      this.loading.finishLoading()
    })
  }

  public async search (query: string): Promise<void> {
    if (this.searchQuery === undefined && query === '') return

    if (query == null || query === '') {
      this.searchQuery = undefined
      await this.changePage(1, true)
      return
    }

    this.loading.startUpdating()
    this.searchQuery = query
    this.pagination.reset()

    try {
      const service = new VehiclesService(this.rootStore.token)
      const response = await service.search(query, 50, { params: commonParams })
      runInAction(() => {
        this.vehicles = response.data.data
        this.pagination.update(response.data.meta.pagination as Pagination)
      })
    } catch (e) {
      runInAction(() => { this.apiError = new ApiErrorStore(parseStrapiError(e)) })
    } finally {
      this.loading.finishUpdating()
    }
  }
}

export default VehiclesStore
