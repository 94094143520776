import { FC, useEffect } from 'react'

import { AddOutlined } from '@mui/icons-material'
import {
  Button,
  Card,
  CardContent,
  CardHeader, Grow
} from '@mui/material'
import { observer } from 'mobx-react-lite'
import { useNavigate } from 'react-router'

import { useStores } from 'stores/hooks'

import { Page, ClientsTable, Search, ListPagination, PageLoader, EditClientForm } from 'components'

import s from './ClientsPage.module.scss'

const ClientsPage: FC<Props> = () => {
  const navigate = useNavigate()
  const { clients, editClient } = useStores()

  useEffect(() => {
    void clients.fetchClients()
    return () => clients.reset()
  }, [])

  const onClientAdded = (): void => {
    if (editClient.client != null) navigate(`/app/clients/${editClient.client.id}`)
  }

  return (
    <Page
      narrow
      title="Klienci"
      description="Zarządzaj klientami serwisu"
      className={s.ClientsPage}
      data-testid={TEST_HOOKS.CLIENTSPAGE}
      action={
        <Button
          variant="contained"
          color="primary"
          size="large"
          startIcon={<AddOutlined />}
          onClick={() => editClient.startEdit()}
        >
          Dodaj klienta
        </Button>
      }
    >
      {clients.loading.isLoading && <PageLoader />}
      {clients.loading.isLoaded && (
        <Grow in>
          <Card>
            <CardHeader title={`Lista klientów (${clients.pagination.total})`} />
            <CardContent>
              <Search
                className={s.Search}
                label="Wyszukaj"
                placeholder="Numer telefonu, rejestracja, marka/model samochodu itp."
                onSearch={async (value) => await clients.search(value)}
              />
              <ClientsTable clients={clients.clients} size="small" isLoading={clients.loading.isUpdating} />
              {clients.searchQuery == null && (
                <ListPagination
                  pagination={clients.pagination}
                  onPageChange={async (page) => await clients.changePage(page)}
                />
              )}
            </CardContent>
          </Card>
        </Grow>
      )}
      <EditClientForm onPostSave={onClientAdded} />
    </Page>
  )
}

const TEST_HOOKS = {
  CLIENTSPAGE: 'clientsPage'
}

// FIXME: Either add props or remove the interface!
// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface Props {

}

const withObserver = observer(ClientsPage)

export default withObserver

export {
  withObserver as ClientsPage,
  TEST_HOOKS,
  type Props
}
