import { FC, useCallback, useEffect } from 'react'

import { yupResolver } from '@hookform/resolvers/yup'
import { Button, Card, CardActions, CardContent, CardHeader, Skeleton, TextField } from '@mui/material'
import Grid from '@mui/material/Unstable_Grid2'
import { observer } from 'mobx-react-lite'
import { useSnackbar } from 'notistack'
import { Controller, SubmitHandler, useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import * as yup from 'yup'

import { PHONE_REGEX } from 'shared/constants'

import { useStores } from 'stores/hooks'

import s from './AccountForm.module.scss'

const schema = yup.object({
  email: yup.string().email().required(),
  username: yup.string().required(),
  name: yup.string().required('accountPage.form.name.error.required'),
  phone: yup.string()
    .matches(new RegExp(PHONE_REGEX), { message: 'accountPage.form.phone.error.format', excludeEmptyString: true })
    .required('accountPage.form.phone.error.required')
})

type IAccountForm = yup.InferType<typeof schema>

const AccountForm: FC<Props> = () => {
  const { t } = useTranslation()
  const { enqueueSnackbar } = useSnackbar()
  const { account, auth } = useStores()
  const getDefaultValues = useCallback((user: Object = {}) => ({
    email: account.user?.email ?? '',
    username: account.user?.username ?? '',
    name: account.user?.name ?? '',
    phone: account.user?.phone ?? '',
    ...user
  }), [account.user])
  const { control, handleSubmit, formState, reset } = useForm<IAccountForm>({
    mode: 'all',
    resolver: yupResolver(schema),
    defaultValues: getDefaultValues()
  })

  const onSubmit: SubmitHandler<IAccountForm> = async (data): Promise<void> => {
    const user = await account.updateAccount(data)
    await Promise.all([account.fetchUser(), auth.fetchUser()])
    enqueueSnackbar('Zapisano zmiany profilowe', { variant: 'success' })
    reset(getDefaultValues(user))
  }

  const onReset = (): void => {
    reset(getDefaultValues())
  }

  useEffect(() => {
    if (account.user === undefined) void account.fetchUser().then(() => reset(getDefaultValues()))
  }, [account])

  if (account.user === undefined) {
    return <Skeleton variant="rectangular" height={500} />
  }

  return (
    <>
      {/* eslint-disable-next-line @typescript-eslint/no-misused-promises */}
      <Card component="form" onSubmit={handleSubmit(onSubmit)}>
        <CardHeader title="Dane użytkownika" />
        <CardContent>
          <Grid container spacing={4} className={s.AccountForm} data-testid={TEST_HOOKS.ACCOUNTFORM}>
            <Grid xs={12}>
              <Controller
                name="email"
                control={control}
                render={({ field }) => {
                  return (
                    <TextField
                      disabled
                      fullWidth
                      required
                      error={Boolean(formState.errors.email)}
                      helperText={t(formState.errors.email?.message ?? '')}
                      label={t('accountPage.form.email.label')}
                      type="email"
                      InputProps={field}
                    />
                  )
                }}
              />
            </Grid>
            <Grid xs={12}>
              <Controller
                name="username"
                control={control}
                render={({ field }) => {
                  return (
                    <TextField
                      disabled
                      fullWidth
                      required
                      error={Boolean(formState.errors.username)}
                      helperText={t(formState.errors.username?.message ?? '')}
                      label={t('accountPage.form.usename.label')}
                      InputProps={field}
                    />
                  )
                }}
              />
            </Grid>
            <Grid xs={12}>
              <Controller
                name="name"
                control={control}
                render={({ field }) => {
                  return (
                    <TextField
                      fullWidth
                      required
                      error={Boolean(formState.errors.name)}
                      helperText={t(formState.errors.name?.message ?? '')}
                      label={t('accountPage.form.name.label')}
                      InputProps={field}
                    />
                  )
                }}
              />
            </Grid>
            <Grid xs={12}>
              <Controller
                name="phone"
                control={control}
                render={({ field }) => {
                  return (
                    <TextField
                      fullWidth
                      required
                      error={Boolean(formState.errors.phone)}
                      helperText={t(formState.errors.phone?.message ?? '')}
                      label={t('accountPage.form.phone.label')}
                      type="tel"
                      InputProps={field}
                    />
                  )
                }}
              />
            </Grid>
          </Grid>
        </CardContent>
        <CardActions>
          <Button
            disabled={!formState.isDirty}
            variant="outlined"
            color="primary"
            onClick={onReset}
          >
            Resetuj
          </Button>
          <Button
            disabled={!formState.isDirty || !formState.isValid}
            variant="contained"
            color="primary"
            type="submit"
          >
            Zapisz
          </Button>
        </CardActions>
      </Card>
    </>
  )
}

const TEST_HOOKS = {
  ACCOUNTFORM: 'accountForm'
}

// FIXME: Either add props or remove the interface!
// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface Props {

}

const withObserver = observer(AccountForm)

export default withObserver

export {
  withObserver as AccountForm,
  TEST_HOOKS,
  type Props,
  type IAccountForm
}
