import { FC } from 'react'

import { LaunchOutlined } from '@mui/icons-material'
import { Card, CardActionArea, type CardActionAreaProps, CardContent, CardHeader, Tooltip } from '@mui/material'
import clsx from 'clsx'
import { observer } from 'mobx-react-lite'
import { Link as RouterLink } from 'react-router-dom'

import { VehicleData } from 'services/types'

import { Plate } from 'components'

import s from './CarCard.module.scss'

const CarCard: FC<Props> = ({ car, className, CardActionAreaProps = {} }) => {
  const CardActionAreaCompositeProps = {
    ...CardActionAreaProps,
    component: RouterLink,
    to: `/app/cars/${car.id}`
  }
  return (
    <Tooltip title="Zobacz samochód">
      <CardActionArea {...CardActionAreaCompositeProps}>
        <Card className={clsx(s.CarCard, className)} data-testid={TEST_HOOKS.CARCARD}>
          <CardHeader
            title={`${car.attributes.manufacturer} ${car.attributes.model} (${car.attributes.year ?? '?'})`}
            subheaderTypographyProps={{ color: 'inherit' }}
            subheader={car.attributes.engineVolume != null ? `${(car.attributes.engineVolume)} ccm` : ''}
            action={<LaunchOutlined />}
          />
          <CardContent>
            <Plate car={car} size="large" />
          </CardContent>
        </Card>
      </CardActionArea>
    </Tooltip>
  )
}

const TEST_HOOKS = {
  CARCARD: 'carCard'
}

interface Props {
  car: VehicleData
  className?: string
  CardActionAreaProps?: CardActionAreaProps
}

const withObserver = observer(CarCard)

export default withObserver

export {
  withObserver as CarCard,
  TEST_HOOKS,
  type Props
}
