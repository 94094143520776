import { createTheme, ThemeOptions } from '@mui/material'

const theme: ThemeOptions = {
  typography: {
    fontFamily: 'Inter, sans-serif'
  },
  palette: {
    mode: 'light',
    primary: {
      main: '#023047'
    },
    secondary: {
      main: '#fb8500'
    }
  },
  components: {
    MuiAlert: {
      styleOverrides: {
        root: {
          borderRadius: 8
        }
      }
    },
    MuiChip: {
      styleOverrides: {
        root: {
          fontWeight: 500
        }
      }
    },
    MuiTooltip: {
      defaultProps: {
        placement: 'top',
        arrow: true
      },
      styleOverrides: {
        tooltip: {
          padding: '6px 12px',
          fontSize: 12,
          background: '#023047',
          borderRadius: 8
        },
        arrow: {
          color: '#023047'
        }
      }
    },
    MuiButton: {
      styleOverrides: {
        root: {
          textTransform: 'none',
          borderRadius: 8
        }
      }
    },
    MuiCard: {
      defaultProps: {
        elevation: 0
      },
      styleOverrides: {
        root: {
          borderRadius: 8
        }
      }
    },
    MuiCardHeader: {
      styleOverrides: {
        root: {
          padding: '24px 24px 16px 24px'
        },
        title: {
          fontSize: 18,
          fontWeight: 700
        }
      }
    },
    MuiCardContent: {
      styleOverrides: {
        root: {
          padding: '16px 24px'
        }
      }
    },
    MuiCardActions: {
      styleOverrides: {
        root: {
          padding: '16px 24px 24px 24px',
          justifyContent: 'flex-end'
        }
      }
    },
    MuiTableCell: {
      styleOverrides: {
        root: {
          border: 'none'
        },
        head: {
          paddingTop: 16,
          paddingBottom: 16,
          border: 'none',
          background: '#e7eced',
          '&:first-of-type': {
            borderTopLeftRadius: 8,
            borderBottomLeftRadius: 8
          },
          '&:last-of-type': {
            borderTopRightRadius: 8,
            borderBottomRightRadius: 8
          }
        }
      }
    },
    MuiPaper: {
      styleOverrides: {
        root: {
          borderRadius: 8
        }
      }
    },
    MuiInputBase: {
      styleOverrides: {
        root: {
          borderRadius: 8
        }
      }
    },
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          borderRadius: 8
        }
      }
    },
    MuiSkeleton: {
      defaultProps: {
        animation: 'wave'
      },
      styleOverrides: {
        rectangular: {
          borderRadius: 8
        }
      }
    },
    MuiCardActionArea: {
      styleOverrides: {
        root: {
          borderRadius: 8
        }
      }
    }
  }
}

const defaultTheme = createTheme(theme)

export {
  defaultTheme
}
