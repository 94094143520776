import { useContext } from 'react'

import storesContext from './context'
import RootStore from './RootStore'

function useStores (): RootStore {
  return useContext(storesContext)
}

export {
  useStores
}
