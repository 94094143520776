import { AxiosResponse } from 'axios'

import { StrapiBackend } from './backends'
import {
  StrapiRequestParams,
  ServicesFindOneResponse,
  ServicesFindResponse,
  ServicesIncomeResponse,
  ServicePartsUpdateData, ServiceActionsUpdateData
} from './types'

class ServicesService extends StrapiBackend {
  readonly BASE_URL: string = '/services'
  readonly MEILI_INDEX = 'service'

  public async findOne (id: number, params?: StrapiRequestParams): Promise<AxiosResponse<ServicesFindOneResponse>> {
    return await this.get(this.resourceUrl(id), { params })
  }

  public async find (params?: StrapiRequestParams): Promise<AxiosResponse<ServicesFindResponse>> {
    return await this.get(this.url, { params })
  }

  public async remove (id: number, params?: StrapiRequestParams): Promise<AxiosResponse> {
    return await this.delete(this.resourceUrl(id), { params })
  }

  public async update (id: number, data: any, params?: StrapiRequestParams): Promise<AxiosResponse<ServicesFindOneResponse>> {
    return await this.put(this.resourceUrl(id), { data: { ...data } }, { params })
  }

  public async create (data: any, params?: StrapiRequestParams): Promise<AxiosResponse<ServicesFindOneResponse>> {
    return await this.post(this.url, { data: { ...data } }, { params })
  }

  public async saveParts (id: number, data: ServicePartsUpdateData, params?: StrapiRequestParams): Promise<AxiosResponse<ServicesFindOneResponse>> {
    return await this.put(this.resourceUrl(id), { data: { ...data } }, { params })
  }

  public async saveActions (id: number, data: ServiceActionsUpdateData, params?: StrapiRequestParams): Promise<AxiosResponse<ServicesFindOneResponse>> {
    return await this.put(this.resourceUrl(id), { data: { ...data } }, { params })
  }

  /**
   * Calculate total income since given datetime.
   * @param since ISO string representation of date[time]<, YYYY-MM-DD [time]
   * @param params
   */
  public async getIncome (since: string, params?: StrapiRequestParams): Promise<AxiosResponse<ServicesIncomeResponse>> {
    return await this.get(`${this.url}/income/${since}`, { params })
  }

  /**
   * Fetches a PDF Blob for the customer.
   */
  public async generatePdf (id: number, template: string): Promise<AxiosResponse<string>> {
    return await this.get(`${this.resourceUrl(id)}/pdf`, { params: { template } })
  }
}

export default ServicesService
