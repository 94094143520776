import { FC, useEffect, useMemo } from 'react'

import { DeleteOutlined, EditOutlined } from '@mui/icons-material'
import {
  Button,
  Card,
  CardContent,
  CardHeader,
  Grow,
  IconButton,
  Tooltip
} from '@mui/material'
import Grid from '@mui/material/Unstable_Grid2'
import { useConfirm } from 'material-ui-confirm'
import { observer } from 'mobx-react-lite'
import { useSnackbar } from 'notistack'
import { useNavigate, useParams } from 'react-router'

import { parseIdFromParams } from 'shared/utils'

import { useStores } from 'stores/hooks'

import { ErrorPage, NotFoundPage } from 'pages'

import { CarCard, DistinctList, EditClientForm, Page, PageLoader } from 'components'

import { DistinctListItem } from 'components/DistinctList/DistinctList'

import s from './ClientPage.module.scss'

const ClientPage: FC<Props> = () => {
  const { id } = useParams()
  const navigate = useNavigate()
  const { enqueueSnackbar } = useSnackbar()
  const { client, editClient } = useStores()
  const parsedId: number = useMemo(() => parseIdFromParams(id), [id])
  const removalConfirm = useConfirm()

  useEffect(() => {
    if (isNaN(parsedId)) return
    void client.fetchClient(parsedId)
    return () => client.reset()
  }, [parsedId])

  const onRemove = (): void => {
    void removalConfirm({
      description: `
        Jesteś pewien? Usuwając klienta usuniesz również wszystkie jego samochody
        oraz powiązane z nimi serwisy. Tej akcji nie można cofnąć!`
    })
      .then(async () => {
        const removed = await client.removeClient()
        if (removed) {
          enqueueSnackbar('Usunięto klienta', { variant: 'success' })
          navigate('/app/clients', { replace: true })
          return
        }
        enqueueSnackbar('Nie udało się usunąć klienta', { variant: 'error' })
      })
      .catch(() => null)
  }

  const onPostEdit = async (): Promise<void> => await client.fetchClient(parsedId)

  if (isNaN(parsedId) || (client.loading.isLoaded && client?.apiError?.status === 404)) {
    return <NotFoundPage />
  }

  if (client.loading.isLoaded && client.client == null && client?.apiError != null) {
    return <ErrorPage error={client.apiError} />
  }

  return (
    <Page
      narrow
      title="Klient"
      description="Zarządzaj klientem"
      className={s.ClientPage}
      data-testid={TEST_HOOKS.CLIENTPAGE}
      action={
        <Button
          disabled={!client.loading.isLoaded || client?.apiError != null}
          onClick={onRemove}
          variant="contained"
          color="error"
          startIcon={<DeleteOutlined />}
        >
          Usuń klienta
        </Button>
      }
    >
      {client.loading.isLoading && <PageLoader />}
      {(client.loading.isLoaded && client.client != null) && (
        <Grid container spacing={4} className={s.Grid}>
          <Grid xs={12} md={6}>
            <Grow in>
              <Card>
                <CardHeader
                  title="Dane klienta"
                  action={
                    <Tooltip title="Edytuj dane klienta">
                      <IconButton onClick={() => editClient.startEdit(client.client)}>
                        <EditOutlined />
                      </IconButton>
                    </Tooltip>
                  }
                />
                <CardContent>
                  <DistinctList>
                    <DistinctListItem value={client.client.attributes.phone} label="Telefon" />
                    <DistinctListItem value={client.client.attributes.tin ?? '—'} label="NIP" />
                  </DistinctList>
                </CardContent>
              </Card>
            </Grow>
          </Grid>
          {client.client.attributes.vehicles.data.map(vehicle => (
            <Grow in key={vehicle.id}>
              <Grid xs={12} sm={6}>
                <CarCard
                  car={vehicle}
                  className={s.CarCard}
                  CardActionAreaProps={{ className: s.CarActionWrapper }}
                />
              </Grid>
            </Grow>
          ))}
        </Grid>
      )}
      <EditClientForm onPostSave={onPostEdit} />
    </Page>
  )
}

const TEST_HOOKS = {
  CLIENTPAGE: 'clientPage'
}

// FIXME: Either add props or remove the interface!
// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface Props {

}

const withObserver = observer(ClientPage)

export default withObserver

export {
  withObserver as ClientPage,
  TEST_HOOKS,
  type Props
}
