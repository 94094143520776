import sortBy from 'lodash/sortBy'
import { makeAutoObservable, runInAction } from 'mobx'

import { parseStrapiError } from 'shared/utils'

import { VehiclesService } from 'services'

import { VehicleData } from 'services/types'

import ApiErrorStore from '../ApiErrorStore'
import LoadingStore from '../LoadingStore'
import type RootStore from '../RootStore'

class VehicleStore {
  rootStore: RootStore

  vehicle?: VehicleData
  loading = new LoadingStore()
  apiError?: ApiErrorStore

  constructor (rootStore: RootStore) {
    makeAutoObservable(this)
    this.rootStore = rootStore
  }

  public reset (): void {
    this.vehicle = undefined
    this.loading.reset()
    this.apiError = undefined
  }

  public async fetchVehicle (id: number): Promise<void> {
    this.loading.startLoading()
    const service = new VehiclesService(this.rootStore.token)
    try {
      const response = await service.findOne(id)
      runInAction(() => {
        this.vehicle = response.data.data
        this.vehicle.attributes.mileages = sortBy(this.vehicle.attributes.mileages, 'date').reverse()
        this.vehicle.attributes.services.data = sortBy(this.vehicle.attributes.services.data, 'attributes.date').reverse()
        this.vehicle.attributes.parts = sortBy(this.vehicle.attributes.parts, 'key')
        this.vehicle.attributes.partsFromServices = sortBy(this.vehicle.attributes.partsFromServices, 'name')
      })
    } catch (e) {
      runInAction(() => { this.apiError = new ApiErrorStore(parseStrapiError(e)) })
    } finally {
      this.loading.finishLoading()
    }
  }

  public async removeVehicle (id: number): Promise<boolean> {
    if (this.vehicle == null) return false

    this.loading.startUpdating()
    const service = new VehiclesService(this.rootStore.token)

    try {
      await service.remove(this.vehicle.id)
      return true
    } catch (e) {
      runInAction(() => { this.apiError = new ApiErrorStore(parseStrapiError(e)) })
      return false
    } finally {
      this.loading.finishUpdating()
    }
  }
}

export default VehicleStore
