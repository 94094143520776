import { FC } from 'react'

import { Button } from '@mui/material'
import clsx from 'clsx'
import { Link as RouterLink } from 'react-router-dom'

import { VehicleData } from 'services/types'

import s from './Plate.module.scss'

/**
 * Displays the car plate number in a nice format. It comes in a non-navigable form,
 * but can also a display a button that will navigate to the car page.
 */
const Plate: FC<Props> = ({ car, size = 'medium', navigable = false, ...rest }) => {
  if (navigable) {
    return (
      <Button
        component={RouterLink}
        to={`/app/cars/${car.id}`}
        color="primary"
        className={clsx(s.Plate, s[size])}
        data-testid={TEST_HOOKS.PLATE}
        {...rest}
      >
        {car.attributes.plate}
      </Button>
    )
  }
  return (
    <span
      className={clsx(s.Plate, s[size])}
      data-testid={TEST_HOOKS.PLATE}
      {...rest}
    >
      {car.attributes.plate}
    </span>
  )
}

const TEST_HOOKS = {
  PLATE: 'plate'
}

interface Props {
  car: VehicleData
  size?: 'large' | 'medium' | 'small'
  navigable?: boolean
}

export default Plate

export {
  Plate,
  TEST_HOOKS,
  type Props
}
