import { FC, useEffect } from 'react'

import { AddOutlined } from '@mui/icons-material'
import {
  Button,
  Card,
  CardContent,
  CardHeader, Grow
} from '@mui/material'
import { observer } from 'mobx-react-lite'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router'

import { useStores } from 'stores/hooks'

import { Page, ServicesTable, Search, PageLoader, ListPagination, EditServiceForm } from 'components'

import s from './ServicesPage.module.scss'

const ServicesPage: FC<Props> = () => {
  const { t } = useTranslation()
  const { services, editService } = useStores()
  const navigate = useNavigate()

  useEffect(() => {
    void services.fetch()
    return () => services.reset()
  }, [])

  const onServiceCreated = (): void => {
    if (editService.service != null) navigate(`/app/services/${editService.service.id}`)
  }

  return (
    <Page
      narrow
      title={t('servicesPage.title')}
      description={t('servicePage.description')}
      className={s.ServicesPage}
      data-testid={TEST_HOOKS.SERVICESPAGE}
      action={
        <Button
          variant="contained"
          color="primary"
          size="large"
          startIcon={<AddOutlined />}
          onClick={() => editService.startEdit()}
        >
          {t('servicePage.addService')}
        </Button>
      }
    >
      {services.loading.isLoading && <PageLoader />}
      {services.loading.isLoaded && (
        <Grow in>
          <Card>
            <CardHeader
              title={t('servicePage.serviceList.title', { count: services.pagination.total })}
            />
            <CardContent>
              <Search
                className={s.Search}
                onSearch={async (value) => await services.search(value)}
                label="Wyszukaj"
                placeholder="Numer rejestracyjny, telefon, marka, itp."
              />
              <ServicesTable services={services.services} size="small" isLoading={services.loading.isUpdating} />
              {services.searchQuery == null && (
                <ListPagination
                  pagination={services.pagination}
                  onPageChange={async (page) => await services.changePage(page)}
                />
              )}
            </CardContent>
          </Card>
        </Grow>
      )}
      <EditServiceForm onPostSave={onServiceCreated} />
    </Page>
  )
}

const TEST_HOOKS = {
  SERVICESPAGE: 'servicesPage'
}

// FIXME: Either add props or remove the interface!
// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface Props {

}

const withObserver = observer(ServicesPage)

export default withObserver

export {
  withObserver as ServicesPage,
  TEST_HOOKS,
  type Props
}
