import { AxiosResponse } from 'axios'

import { StrapiBackend } from './backends'
import {
  type StrapiRequestParams,
  VehiclePartsUpdateData,
  VehiclesFindOneResponse,
  VehiclesFindResponse,
  VehicleUpdateData,
  VehicleMileagesUpdateDAta
} from './types'

class VehiclesService extends StrapiBackend {
  readonly BASE_URL = '/vehicles'
  readonly MEILI_INDEX = 'vehicle'

  public async findOne (id: number, params?: StrapiRequestParams): Promise<AxiosResponse<VehiclesFindOneResponse>> {
    return await this.get(this.resourceUrl(id), { params })
  }

  public async find (params?: StrapiRequestParams): Promise<AxiosResponse<VehiclesFindResponse>> {
    return await this.get(this.url, { params })
  }

  public async remove (id: number, params?: StrapiRequestParams): Promise<AxiosResponse> {
    return await this.delete(this.resourceUrl(id), { params })
  }

  public async create (data: VehicleUpdateData, params?: StrapiRequestParams): Promise<AxiosResponse<VehiclesFindOneResponse>> {
    return await this.post(this.url, { data: { ...data } }, { params })
  }

  public async update (id: number, data: VehicleUpdateData, params?: StrapiRequestParams): Promise<AxiosResponse<VehiclesFindOneResponse>> {
    return await this.put(this.resourceUrl(id), { data: { ...data } }, { params })
  }

  public async saveParts (id: number, data: VehiclePartsUpdateData, params?: StrapiRequestParams): Promise<AxiosResponse<VehiclesFindOneResponse>> {
    return await this.put(this.resourceUrl(id), { data: { ...data } }, { params })
  }

  public async saveMileages (id: number, data: VehicleMileagesUpdateDAta, params?: StrapiRequestParams): Promise<AxiosResponse<VehiclesFindOneResponse>> {
    return await this.put(this.resourceUrl(id), { data: { ...data } }, { params })
  }
}

export default VehiclesService
