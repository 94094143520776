import { FC } from 'react'

import { ChevronRightOutlined } from '@mui/icons-material'
import {
  IconButton,
  Link, Skeleton, Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tooltip
} from '@mui/material'
import times from 'lodash/times'
import { observer } from 'mobx-react-lite'
import { Link as RouterLink } from 'react-router-dom'

import { ClientData } from 'services/types'

import { Plate } from 'components'

import s from './ClientsTable.module.scss'

const ClientsTable: FC<Props> = ({ clients, size = 'medium', isLoading = false }) => {
  const emptyRow = (
    <TableRow>
      <TableCell colSpan={3} align="center">
        Brak klientów...
      </TableCell>
    </TableRow>
  )

  if (isLoading) {
    return (
      <Stack spacing={2}>
        {times(clients?.length ?? 25).map(idx => (
          <Skeleton key={idx} variant="rectangular" height={60} />
        ))}
      </Stack>
    )
  }

  return (
    <TableContainer className={s.ClientsTable} data-testid={TEST_HOOKS.CLIENTSTABLE}>
      <Table size={size}>
        <TableHead>
          <TableRow>
            <TableCell>
              Numer telefonu
            </TableCell>
            <TableCell>
              Samochody
            </TableCell>
            <TableCell />
          </TableRow>
        </TableHead>
        <TableBody>
          {(clients === undefined || clients.length === 0) && emptyRow}
          {clients?.map((client) => {
            return (
              <TableRow key={client.id}>
                <TableCell>
                  <Link underline="hover" component={RouterLink} to={String(client.id)}>
                    {client.attributes.phone}
                  </Link>
                </TableCell>
                <TableCell className={s.Cars}>
                  {client.attributes?.vehicles?.data?.map(
                    vehicle => <Plate key={vehicle.id} car={vehicle} navigable />
                  )}
                </TableCell>
                <TableCell align="right">
                  <Tooltip color="primary" title="Zobacz klienta">
                    <IconButton
                      size="large"
                      color="primary"
                      component={RouterLink}
                      to={String(client.id)}
                      edge="end"
                    >
                      <ChevronRightOutlined />
                    </IconButton>
                  </Tooltip>
                </TableCell>
              </TableRow>
            )
          })}
        </TableBody>
      </Table>
    </TableContainer>
  )
}

const TEST_HOOKS = {
  CLIENTSTABLE: 'clientsTable'
}

interface Props {
  clients?: ClientData[]
  size?: 'small' | 'medium'
  isLoading?: boolean
}

const withObserver = observer(ClientsTable)

export default withObserver

export {
  withObserver as ClientsTable,
  TEST_HOOKS,
  type Props
}
