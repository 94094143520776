import axios, { type AxiosInstance, type AxiosRequestConfig, type AxiosRequestHeaders, type AxiosResponse } from 'axios'
import merge from 'lodash/merge'
import {
  MeiliSearch,
  type Config,
  Index,
  type SearchParams,
  type SearchResponse,
  SearchableAttributes, DisplayedAttributes
} from 'meilisearch'
import { stringify } from 'qs'
import { type StrapiResponse } from 'strapi-sdk-js'

import { getApiBaseUrl } from 'shared/utils'

import { AvailableIndices, MeiliResource, StrapiResource } from './types'

/**
 * Simple backend for calling Strapi-based API backend.
 */
class StrapiBackend {
  readonly HOST: string = getApiBaseUrl()
  readonly BASE_URL: string = '/'
  readonly MEILI_INDEX?: AvailableIndices

  public token?: string

  private readonly axios: AxiosInstance = axios.create()

  constructor (token?: string) {
    this.token = token
  }

  public get url (): string {
    return this.HOST + this.BASE_URL
  }

  private get headers (): AxiosRequestHeaders {
    const headers: AxiosRequestHeaders = {
      'Content-Type': 'application/json'
    }
    if (this.token !== undefined) {
      headers.Authorization = `Bearer ${this.token}`
    }
    return headers
  }

  /**
   * Enhances the incoming request config with authorization headers,
   * as well as stringifies the params (Strapi sometimes doesn't work well with nested objects).
   */
  private prepareConfig (config?: AxiosRequestConfig): AxiosRequestConfig {
    return {
      headers: this.headers,
      paramsSerializer: params => stringify(params, { encodeValuesOnly: true }),
      ...config
    }
  }

  public resourceUrl (id: string | number): string {
    return `${this.url}/${id}`
  }

  public async get (url: string, config?: AxiosRequestConfig): Promise<AxiosResponse> {
    return await this.axios.get(url, this.prepareConfig(config))
  }

  public async post (url: string, data?: any, config?: AxiosRequestConfig): Promise<AxiosResponse> {
    return await this.axios.post(url, data, this.prepareConfig(config))
  }

  public async put (url: string, data?: any, config?: AxiosRequestConfig): Promise<AxiosResponse> {
    return await this.axios.put(url, data, this.prepareConfig(config))
  }

  public async delete (url: string, config?: AxiosRequestConfig): Promise<AxiosResponse> {
    return await this.axios.delete(url, this.prepareConfig(config))
  }

  /**
   * A facade for searching in Meilisearch for IDs of matching entries, and then querying for them in Strapi.
   */
  public async search (query: string, limit: number = 50, config?: AxiosRequestConfig): Promise<AxiosResponse<StrapiResponse<any[]>>> {
    if (this.MEILI_INDEX == null) throw Error('Please provide a MEILI_INDEX to allow searching')

    const meiliBackend = new MeiliBackend()
    const meiliResponse = await meiliBackend.search<MeiliResource<StrapiResource>>(this.MEILI_INDEX, query, {
      attributesToRetrieve: ['_strapiId'],
      limit
    })
    const ids: number[] = meiliResponse.hits.map(entry => parseInt(String(entry._strapiId)))
    if (ids.length === 0) {
      return {
        status: 200,
        statusText: 'OK',
        config: {},
        headers: {},
        data: {
          data: [],
          meta: {
            pagination: {
              page: 1,
              pageSize: limit,
              totalPages: 1,
              total: 0
            }
          }
        }
      }
    }
    const filtersConfig = {
      params: {
        filters: {
          id: {
            $in: ids
          }
        }
      }
    }
    return await this.get(this.url, merge({}, config ?? {}, filtersConfig))
  }
}

class MeiliBackend extends MeiliSearch {
  constructor () {
    const config: Config = {
      host: process.env.REACT_APP_MEILI_HOST ?? 'https://meili.motocar.pro',
      apiKey: process.env.REACT_APP_MEILI_KEY ?? ''
    }
    super(config)
  }

  index<T = any>(indexUid: AvailableIndices): Index<T> {
    return super.index(indexUid)
  }

  async getIndex<T = any>(indexUid: AvailableIndices): Promise<Index<T>> {
    return await super.getIndex(indexUid)
  }

  /**
   * Wrapper method to search on a given index. Doesn't require you to initialize index, jut provide its name.
   * Otherwise, it has the same interface as `Index.search`.
   */
  async search<T = Record<string, any>> (
    indexUid: AvailableIndices,
    query?: string | null,
    options?: SearchParams,
    config?: Partial<Request>
  ): Promise<SearchResponse<T>> {
    const index: Index = this.index(indexUid)
    return await index.search(query, options, config)
  }

  async getDisplayedAttributes (indexUid: AvailableIndices): Promise<DisplayedAttributes> {
    return await this.index(indexUid).getDisplayedAttributes()
  }

  /**
   * Get a list of attributes that are searchable for given index.
   */
  async getSearchableAttributes (indexUid: AvailableIndices): Promise<SearchableAttributes> {
    return await this.index(indexUid).getSearchableAttributes()
  }

  /**
   * Set which attributes of an index should be searchable.
   * You can provide `null` to turn all the attributes as searchable.
   */
  async setSearchableAttributes (
    indexUid: AvailableIndices,
    attributes: SearchableAttributes
  ): Promise<void> {
    const index: Index = this.index(indexUid)
    await index.updateSearchableAttributes(attributes)
  }
}

export {
  StrapiBackend,
  MeiliBackend
}
