import { AxiosResponse } from 'axios'
import { type ProfileResponse } from 'smsapi'

import { StrapiBackend } from './backends'

class SmsApiService extends StrapiBackend {
  readonly BASE_URL: string = '/smsapi'

  public async checkAccount (): Promise<AxiosResponse<ProfileResponse>> {
    return await this.get(this.url)
  }
}

export default SmsApiService
