import { FC, useEffect, useState } from 'react'

import { Box, Toolbar } from '@mui/material'
import { observer } from 'mobx-react-lite'
import { useNavigate } from 'react-router'
import { Outlet } from 'react-router-dom'

import { useStores } from 'stores/hooks'

import { AppNavbar, Footer, Sidebar } from 'components'

import s from './AppWrapper.module.scss'

const AppWrapper: FC = () => {
  const { auth } = useStores()
  const navigate = useNavigate()
  const [isAuthorized, setIsAuthorized] = useState<boolean>(false)
  const [sidebarOpen, setSidebarOpen] = useState<boolean>(false)

  const exitApp = (): void => {
    navigate('/', { replace: true })
  }

  useEffect(() => {
    auth.setTokenFromCookie()
    auth.isAuthorized()
      .then((authorized) => {
        setIsAuthorized(authorized)
        if (authorized) void auth.fetchUser()
        if (!authorized) {
          setIsAuthorized(false)
          exitApp()
        }
      })
      .catch(() => {
        setIsAuthorized(false)
        exitApp()
      })
  }, [])

  if (!isAuthorized) return null

  return (
    <div className={s.AppWrapper} data-testid={TEST_HOOKS.APPWRAPPER}>
      <AppNavbar isMenuOpen={sidebarOpen} onMenuClick={() => setSidebarOpen(!sidebarOpen)} />
      <Sidebar open={sidebarOpen} onClose={() => setSidebarOpen(false)} />
      <Box className={s.Wrapper}>
        <Toolbar />
        <Box component="main" className={s.Main}>
          <Outlet />
        </Box>
        <Footer />
      </Box>
    </div>
  )
}

const TEST_HOOKS = {
  APPWRAPPER: 'appWrapper'
}

// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface Props {

}

const withObserver = observer(AppWrapper)

export default withObserver

export {
  withObserver as AppWrapper,
  TEST_HOOKS,
  type Props
}
