import { FC } from 'react'

import { ChevronRightOutlined } from '@mui/icons-material'
import {
  IconButton,
  Link, Skeleton, Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tooltip
} from '@mui/material'
import times from 'lodash/times'
import { useTranslation } from 'react-i18next'
import { Link as RouterLink } from 'react-router-dom'

import { ServiceData } from 'services/types'

import { Plate, ServiceStatusBadge } from 'components'

import s from './ServicesTable.module.scss'

/**
 * Displays a single row in a list of services.
 */
const Service: FC<ServiceProps> = ({ service }) => {
  const { t } = useTranslation()
  const vehicle = service.attributes.vehicle.data
  const client = vehicle?.attributes.client.data

  return (
    <TableRow>
      <TableCell>
        {vehicle != null && (
          <Link underline="hover" component={RouterLink} to={`/app/cars/${vehicle.id}`}>
            {vehicle.attributes.manufacturer} {vehicle.attributes.model}
          </Link>
        )}
      </TableCell>
      <TableCell>
        {vehicle != null && <Plate car={vehicle} navigable />}
      </TableCell>
      <TableCell>
        {client != null && (
          <Link underline="hover" component={RouterLink} to={`/app/clients/${client.id}`}>
            {client.attributes.phone}
          </Link>
        )}
      </TableCell>
      <TableCell>
        <ServiceStatusBadge status={service.attributes.status} />
      </TableCell>
      <TableCell align="right">
        <Tooltip title={t('services.table.view')}>
          <IconButton
            component={RouterLink}
            to={`/app/services/${service.id}`}
            color="primary"
            size="large"
            edge="end"
          >
            <ChevronRightOutlined />
          </IconButton>
        </Tooltip>
      </TableCell>
    </TableRow>
  )
}

interface ServiceProps {
  service: ServiceData
}

/**
 * Displays a table with list of services.
 */
const ServicesTable: FC<Props> = ({ services, size = 'medium', isLoading = false }) => {
  const { t } = useTranslation()

  const emptyRow = (
    <TableRow>
      <TableCell colSpan={5} align="center" className={s.Empty}>
        {t('services.table.empty')}
      </TableCell>
    </TableRow>
  )

  if (isLoading) {
    return (
      <Stack spacing={2}>
        {times(services.length).map(idx => (
          <Skeleton key={idx} variant="rectangular" height={60} />
        ))}
      </Stack>
    )
  }

  return (
    <TableContainer className={s.ServicesTable} data-testid={TEST_HOOKS.SERVICESTABLE}>
      <Table size={size}>
        <TableHead>
          <TableRow>
            <TableCell>
              {t('services.table.car')}
            </TableCell>
            <TableCell>
              {t('services.table.plate')}
            </TableCell>
            <TableCell>
              {t('services.table.client')}
            </TableCell>
            <TableCell>
              {t('services.table.status')}
            </TableCell>
            <TableCell />
          </TableRow>
        </TableHead>
        <TableBody>
          {(services.length === 0) && emptyRow}
          {services.map(service => {
            return (
              <Service
                key={service.id}
                service={service}
              />
            )
          })}
        </TableBody>
      </Table>
    </TableContainer>
  )
}

const TEST_HOOKS = {
  SERVICESTABLE: 'servicesTable'
}

interface Props {
  services: ServiceData[]
  size?: 'small' | 'medium'
  isLoading?: boolean
}

export default ServicesTable

export {
  ServicesTable,
  TEST_HOOKS,
  type Props
}
