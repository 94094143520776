import { FC, SyntheticEvent, useEffect, useRef, useState } from 'react'

import { yupResolver } from '@hookform/resolvers/yup'
import {
  Autocomplete, AutocompleteInputChangeReason, Checkbox,
  Divider,
  FormControl, FormControlLabel, FormGroup, FormHelperText,
  InputAdornment,
  InputLabel,
  MenuItem,
  Select,
  TextField, Typography
} from '@mui/material'
import Grid from '@mui/material/Unstable_Grid2'
import { observer } from 'mobx-react-lite'
import { useSnackbar } from 'notistack'
import { Controller, SubmitHandler, useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import * as yup from 'yup'
import { InferType } from 'yup'

import { adaptMeiliResource } from 'shared/adapters'
import { emptyStringToNull, useRemoteErrors } from 'shared/utils'

import { MeiliBackend } from 'services/backends'
import { MeiliResource, ServiceUpdateData, VehicleData } from 'services/types'

import { useStores } from 'stores/hooks'

import { DialogForm } from 'components'

import s from './EditServiceForm.module.scss'

const schema = yup.object({
  vehicle: yup.mixed().required(),
  status: yup.string().oneOf(['new', 'progress', 'finished', 'received']).required(),
  date: yup.string().required(),
  errand: yup.string().nullable(),
  vehicleState: yup.string().nullable(),
  summary: yup.string().nullable(),
  fuelLevel: yup.string().oneOf(['empty', 'third', 'half', 'quarter', 'full']).nullable(),
  mileage: yup.number().nullable().transform(emptyStringToNull),
  maxAmount: yup.number().nullable().transform(emptyStringToNull),
  paymentType: yup.string().oneOf(['cash', 'card', 'transfer', 'other', 'none']),
  acceptNewParts: yup.boolean().nullable(),
  acceptUsedParts: yup.boolean().nullable(),
  acceptUnoriginalParts: yup.boolean().nullable(),
  acceptPlateFrame: yup.boolean().nullable(),
  acceptTestDrive: yup.boolean().nullable()
})

type EditServiceFormSchema = InferType<typeof schema>

const EditServiceForm: FC<Props> = ({ onPostSave }) => {
  const { t } = useTranslation()
  const ref = useRef(null)
  const { enqueueSnackbar } = useSnackbar()
  const { editService } = useStores()
  const { control, formState, handleSubmit, reset, setError } = useForm<EditServiceFormSchema>({
    mode: 'all',
    resolver: yupResolver(schema),
    defaultValues: editService.defaultValues
  })
  const [options, setOptions] = useState<Array<{ id: number, label: string }>>([])

  useEffect(() => {
    reset(editService.defaultValues)
  }, [editService.defaultValues])

  useRemoteErrors(setError, editService.apiError?.details.errors)

  const onSubmit: SubmitHandler<EditServiceFormSchema> = async (data) => {
    if (editService.service != null) delete data.vehicle
    else data = { ...data, vehicle: data.vehicle.id }
    const response = await editService.save(data as ServiceUpdateData)
    if (response != null) {
      enqueueSnackbar('Zapisano dane serwisu', { variant: 'success' })
      if (onPostSave != null) onPostSave()
      editService.reset()
      return
    }
    enqueueSnackbar('Błąd podczas zapisywania danych serwisu', { variant: 'error' })
  }

  const handleVehicleChange = async (e: SyntheticEvent, value: string, reason: AutocompleteInputChangeReason): Promise<void> => {
    if (value == null || value === '') {
      setOptions([])
      return
    }
    const meiliBackend = new MeiliBackend()
    const response = await meiliBackend.search<MeiliResource<VehicleData>>(
      'vehicle', value, { attributesToRetrieve: ['_strapiId', 'plate', 'manufacturer', 'model'] }
    )
    const vehicles = response.hits.map(adaptMeiliResource) as VehicleData[]
    setOptions(vehicles.map(vehicle => ({
      id: vehicle.id,
      label: `${vehicle.attributes.manufacturer} ${vehicle.attributes.model} (${vehicle.attributes.plate})`
    })))
  }

  return (
    <DialogForm
      open={editService.isOpen}
      formRef={ref}
      title={editService.service == null ? 'Dodaj serwis' : 'Edytuj dane serwisu'}
      isValid={formState.isValid && formState.isDirty}
      onCancel={() => editService.reset()}
      className={s.EditServiceForm}
      data-testid={TEST_HOOKS.EDITSERVICEFORM}
    >
      {/* eslint-disable-next-line @typescript-eslint/no-misused-promises */}
      <Grid container spacing={3} component="form" onSubmit={handleSubmit(onSubmit)} ref={ref}>
        <Grid xs={12}>
          <Controller
            control={control}
            name="vehicle"
            render={({ field }) => (
              <Autocomplete
                {...field}
                /* eslint-disable-next-line @typescript-eslint/no-misused-promises */
                onInputChange={handleVehicleChange}
                filterOptions={options => options}
                disabled={editService.service != null}
                onChange={(e, data) => field.onChange(data)}
                getOptionLabel={option => typeof option === 'string' ? option : option.label}
                isOptionEqualToValue={(option, value) => {
                  if (typeof value === 'string') return option.id === value
                  return option.id === value.id
                }}
                renderInput={params => (
                  <TextField
                    {...params}
                    required
                    label="Samochód"
                    error={Boolean(formState.errors.vehicle)}
                    // @ts-expect-error
                    helperText={t(formState.errors?.vehicle?.message ?? '')}
                  />
                )}
                options={options}
              />
            )}
          />
        </Grid>
        <Grid xs={12}>
          <Divider />
        </Grid>
        <Grid xs={12}>
          <FormControl fullWidth>
            <InputLabel>Status serwisu</InputLabel>
            <Controller
              name="status"
              control={control}
              render={({ field }) => (
                <Select
                  fullWidth
                  label="Status serwisu"
                  defaultValue={editService.defaultValues.status}
                  error={Boolean(formState.errors.status)}
                  {...field}
                >
                  <MenuItem value="new">
                    Przyjęty
                  </MenuItem>
                  <MenuItem value="progress">
                    W serwisie
                  </MenuItem>
                  <MenuItem value="finished">
                    Zakończony
                  </MenuItem>
                  <MenuItem value="received">
                    Odebrany
                  </MenuItem>
                </Select>
              )}
            />
            {formState.errors?.status != null && (
              <FormHelperText color="error">
                {t(formState.errors?.status?.message ?? '')}
              </FormHelperText>
            )}
          </FormControl>
        </Grid>
        <Grid xs={12}>
          <Controller
            control={control}
            name="errand"
            render={({ field }) => (
              <TextField
                fullWidth
                multiline
                label="Zlecenie"
                InputProps={field}
                error={Boolean(formState.errors.errand)}
                helperText={t(formState.errors?.errand?.message ?? '')}
              />
            )}
          />
        </Grid>
        <Grid xs={12}>
          <Controller
            control={control}
            name="vehicleState"
            render={({ field }) => (
              <TextField
                fullWidth
                multiline
                label="Stan pojazdu (do dalszej naprawy)"
                InputProps={field}
                error={Boolean(formState.errors.vehicleState)}
                helperText={t(formState.errors?.vehicleState?.message ?? '')}
              />
            )}
          />
        </Grid>
        <Grid xs={12} sm={6}>
          <Controller
            control={control}
            name="date"
            render={({ field }) => (
              <TextField
                fullWidth
                type="date"
                label="Data"
                InputProps={field}
                InputLabelProps={{ shrink: true }}
                error={Boolean(formState.errors.mileage)}
                helperText={t(formState.errors?.mileage?.message ?? '')}
              />
            )}
          />
        </Grid>
        <Grid xs={12} sm={6}>
          <Controller
            control={control}
            name="mileage"
            render={({ field }) => (
              <TextField
                fullWidth
                type="number"
                label="Przebieg"
                InputProps={{
                  ...field,
                  endAdornment: (
                    <InputAdornment position="end">
                      km
                    </InputAdornment>
                  )
                }}
                error={Boolean(formState.errors.mileage)}
                helperText={t(formState.errors?.mileage?.message ?? '')}
              />
            )}
          />
        </Grid>
        <Grid xs={12} sm={6}>
          <FormControl fullWidth>
            <InputLabel>Poziom baku</InputLabel>
            <Controller
              name="fuelLevel"
              control={control}
              render={({ field }) => (
                <Select
                  fullWidth
                  label="Poziom baku"
                  defaultValue={editService.defaultValues.fuelLevel}
                  error={Boolean(formState.errors.fuelLevel)}
                  {...field}
                >
                  <MenuItem value="empty">Pusty bak</MenuItem>
                  <MenuItem value="quarter">1/4</MenuItem>
                  <MenuItem value="half">1/2</MenuItem>
                  <MenuItem value="third">2/3</MenuItem>
                  <MenuItem value="full">Pełny bak</MenuItem>
                </Select>
              )}
            />
            {formState.errors?.fuelLevel != null && (
              <FormHelperText color="error">
                {t(formState.errors?.fuelLevel?.message ?? '')}
              </FormHelperText>
            )}
          </FormControl>
        </Grid>
        <Grid xs={12} sm={6}>
          <FormControl fullWidth>
            <InputLabel>Płatność</InputLabel>
            <Controller
              name="paymentType"
              control={control}
              render={({ field }) => (
                <Select
                  fullWidth
                  label="Płatność"
                  defaultValue={editService.defaultValues.paymentType}
                  error={Boolean(formState.errors.paymentType)}
                  {...field}
                >
                  <MenuItem value="cash">Gotówka</MenuItem>
                  <MenuItem value="transfer">Przelew</MenuItem>
                  <MenuItem value="card">Karta płatnicza</MenuItem>
                </Select>
              )}
            />
            {formState.errors?.paymentType != null && (
              <FormHelperText color="error">
                {t(formState.errors?.paymentType?.message ?? '')}
              </FormHelperText>
            )}
          </FormControl>
        </Grid>
        <Grid xs={12}>
          <Typography variant="subtitle2" gutterBottom>
            Zgody klienta
          </Typography>
          <FormGroup>
            <FormControlLabel
              control={
                <Controller
                  control={control}
                  name="acceptPlateFrame"
                  render={({ field }) => (
                    <Checkbox
                      {...field}
                      checked={Boolean(field.value)}
                      onChange={(e) => field.onChange(e.target.checked)}
                    />
                  )}
                />
              }
              label="Zgoda na naklejenie loga na tablicę rejestracyjną"
            />
            <FormControlLabel
              control={
                <Controller
                  control={control}
                  name="acceptNewParts"
                  render={({ field }) => (
                    <Checkbox
                      {...field}
                      checked={Boolean(field.value)}
                      onChange={(e) => field.onChange(e.target.checked)}
                    />
                  )}
                />
              }
              label="Zgoda na zakup nowych części"
            />
            <FormControlLabel
              control={
                <Controller
                  control={control}
                  name="acceptUsedParts"
                  render={({ field }) => (
                    <Checkbox
                      {...field}
                      checked={Boolean(field.value)}
                      onChange={(e) => field.onChange(e.target.checked)}
                    />
                  )}
                />
              }
              label="Zgoda na zakup używanych części"
            />
            <FormControlLabel
              control={
                <Controller
                  control={control}
                  name="acceptUnoriginalParts"
                  render={({ field }) => (
                    <Checkbox
                      {...field}
                      checked={Boolean(field.value)}
                      onChange={(e) => field.onChange(e.target.checked)}
                    />
                  )}
                />
              }
              label="Zgoda na zakup nieoryginalnych części"
            />
            <FormControlLabel
              control={
                <Controller
                  control={control}
                  name="acceptTestDrive"
                  render={({ field }) => (
                    <Checkbox
                      {...field}
                      checked={Boolean(field.value)}
                      onChange={(e) => field.onChange(e.target.checked)}
                    />
                  )}
                />
              }
              label="Zgoda na jazdę testową"
            />
          </FormGroup>
        </Grid>
        {editService.service != null && (
          <Grid xs={12}>
            <Typography variant="subtitle2" gutterBottom>
              Podsumowanie zlecenia
            </Typography>
            <Controller
              control={control}
              name="summary"
              render={({ field }) => (
                <TextField
                  fullWidth
                  multiline
                  InputProps={field}
                  error={Boolean(formState.errors.summary)}
                  helperText={t(formState.errors?.summary?.message ?? '')}
                />
              )}
            />
          </Grid>
        )}
      </Grid>
    </DialogForm>
  )
}

const TEST_HOOKS = {
  EDITSERVICEFORM: 'editServiceForm'
}

interface Props {
  onPostSave?: () => any
}

const withObserver = observer(EditServiceForm)

export default withObserver

export {
  withObserver as EditServiceForm,
  TEST_HOOKS,
  type Props,
  type EditServiceFormSchema
}
