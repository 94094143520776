import { type MeiliResource, type StrapiResource } from 'services/types'

/**
 * Adapts MeiliResource (which is flat) to a format of StrapiResource, where attributes are nested in a subfield.
 */
const adaptMeiliResource = (resource: MeiliResource<any>): StrapiResource => {
  if (resource != null && Object.hasOwn(resource, '_strapiId')) {
    const adaptedResource: StrapiResource = {
      id: 0,
      attributes: {}
    }
    adaptedResource.id = resource._strapiId
    Object.entries(resource).forEach(([key, value]) => {
      if (key === 'id' || key === '_strapiId') return
      if (Array.isArray(value)) adaptedResource.attributes[key] = value.map(adaptMeiliResource)
      else adaptedResource.attributes[key] = adaptMeiliResource(value)
    })
    return adaptedResource
  } else {
    return resource
  }
}

export {
  adaptMeiliResource
}
