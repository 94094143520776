import { AxiosResponse } from 'axios'
import { makeAutoObservable, runInAction } from 'mobx'

import { parseStrapiError } from 'shared/utils'

import { ClientsService } from 'services'

import { ClientData, ClientsFindOneResponse, ClientUpdateData } from 'services/types'

import ApiErrorStore from '../ApiErrorStore'
import LoadingStore from '../LoadingStore'
import type RootStore from '../RootStore'

/**
 * This stores handles both CREATING and EDITING clients.
 */
class EditClientStore {
  rootStore: RootStore

  isOpen: boolean = false

  client?: ClientData
  loading = new LoadingStore()
  apiError?: ApiErrorStore

  constructor (rootStore: RootStore) {
    makeAutoObservable(this)
    this.rootStore = rootStore
  }

  get defaultValues (): ClientUpdateData {
    return {
      phone: this.client?.attributes.phone ?? '',
      tin: this.client?.attributes.tin ?? ''
    }
  }

  public reset (): void {
    this.isOpen = false
    this.loading.reset()
    this.apiError = undefined
    this.client = undefined
  }

  public startEdit (client?: ClientData): void {
    if (client != null) this.client = client
    this.isOpen = true
  }

  public async save (data: ClientUpdateData): Promise<ClientData | undefined> {
    this.loading.startUpdating()
    this.apiError = undefined
    const service = new ClientsService(this.rootStore.token)

    try {
      let response: AxiosResponse<ClientsFindOneResponse>
      if (this.client != null) response = await service.update(this.client.id, data)
      else response = await service.create(data)
      this.client = response.data.data
      return response.data.data
    } catch (e) {
      runInAction(() => { this.apiError = new ApiErrorStore(parseStrapiError(e)) })
    } finally {
      this.loading.finishUpdating()
    }
  }
}

export default EditClientStore
