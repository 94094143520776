import { FC, ReactNode } from 'react'

import { Box, Divider, Stack, Typography } from '@mui/material'
import clsx from 'clsx'
import { observer } from 'mobx-react-lite'

import s from './Page.module.scss'

const Page: FC<Props> = ({ title, description = null, action = null, narrow = false, children = null, className = null, ...rest }) => {
  return (
    <div className={clsx(s.Page, narrow && s.narrow, className)} data-testid={TEST_HOOKS.PAGE} {...rest}>
      <Stack direction="row" alignItems="center" justifyContent="space-between" flexWrap="wrap" className={s.PageHeader}>
        <div>
          <Typography variant="h2" className={s.PageTitle}>{title}</Typography>
          {description !== null && <Typography variant="subtitle1" className={s.Description}>{description}</Typography>}
        </div>
        <Box className={s.Action}>
          {action}
        </Box>
      </Stack>
      <Divider className={s.Divider} />
      {children}
    </div>
  )
}

const TEST_HOOKS = {
  PAGE: 'page'
}

interface Props {
  title: string
  description?: string | ReactNode
  action?: ReactNode
  narrow?: boolean
  children?: ReactNode
  className?: string
}

const withObserver = observer(Page)

export default withObserver

export {
  withObserver as Page,
  TEST_HOOKS,
  type Props
}
