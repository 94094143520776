import { FC, ReactNode, RefObject } from 'react'

import { LoadingButton } from '@mui/lab'
import { Button, Dialog, DialogActions, DialogContent, DialogTitle } from '@mui/material'
import { Breakpoint } from '@mui/system'
import clsx from 'clsx'
import { observer } from 'mobx-react-lite'

import { submitForm } from 'shared/utils'

import s from './DialogForm.module.scss'

const DialogForm: FC<Props> = ({
  open = false,
  title,
  formRef,
  isValid = false,
  isUpdating = false,
  onCancel = () => null,
  submitLabel,
  cancelLabel,
  maxWidth = 'sm',
  className,
  children
}) => {
  return (
    <Dialog
      fullWidth
      maxWidth={maxWidth}
      open={open}
      className={clsx(s.DialogForm, className)}
      data-testid={TEST_HOOKS.DIALOGFORM}
    >
      <DialogTitle>{title}</DialogTitle>
      <DialogContent className={s.Content}>
        {children}
      </DialogContent>
      <DialogActions>
        <Button variant="outlined" onClick={onCancel}>
          {cancelLabel ?? 'Anuluj'}
        </Button>
        <LoadingButton
          disabled={!isValid}
          loading={isUpdating}
          onClick={() => submitForm(formRef)}
          variant="contained"
          color="primary"
        >
          {submitLabel ?? 'Zapisz'}
        </LoadingButton>
      </DialogActions>
    </Dialog>
  )
}

const TEST_HOOKS = {
  DIALOGFORM: 'dialogForm'
}

interface Props {
  open?: boolean
  title: string
  formRef: RefObject<HTMLFormElement>
  isValid?: boolean
  isUpdating?: boolean
  onCancel?: () => void
  submitLabel?: string
  cancelLabel?: string
  maxWidth?: Breakpoint | false
  className?: string
  children: ReactNode
}

const withObserver = observer(DialogForm)

export default withObserver

export {
  withObserver as DialogForm,
  TEST_HOOKS,
  type Props
}
